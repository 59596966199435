import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { ActionButton, TextInput } from "../../../../components";
import { createEventFields } from "../../../../utils/constants";
import { InputChangeEvent } from "../../../../utils/types";

import "./styles.createEventModal.scss";

interface ModalProps {
  onClose: () => void;
  onAddEvent: (details: { title: string; caseName: string; date: string; startTime: string; endTime: string }) => void;
}

const CreateEventModal: React.FC<ModalProps> = ({ onClose, onAddEvent }) => {
  const [details, setDetails] = useState({
    title: "",
    caseName: "",
    date: "",
    startTime: "",
    endTime: ""
  });

  const inputFields = createEventFields;

  const handleInputChange = (e: InputChangeEvent) => {
    const { name, value } = e.target;
    setDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    onAddEvent(details);
  };

  return (
    <div className='custom-modal-overlay'>
      <div className='custom-modal-content'>
        <h6 className='custom-modal-title'>Add New Task</h6>

        {inputFields.map((field) => (
          <TextInput
            key={field.name}
            labelClassName='custom-color-primary-light fw-bold'
            label={field.label}
            name={field.name}
            value={details[field.name as keyof typeof details]}
            onChange={handleInputChange}
            placeholder={field.placeholder}
            type={field.type || "text"}
          />
        ))}
        <div className='custom-time-inputs d-flex flex-row align-items-center'>
          {["startTime", "endTime"].map((timeField, index) => (
            <React.Fragment key={timeField}>
              <TextInput
                labelClassName='custom-color-primary-light fw-bold'
                label={index === 0 ? "Time" : "Time"}
                name={timeField}
                value={details[timeField as keyof typeof details]}
                onChange={handleInputChange}
                placeholder={`Enter ${index === 0 ? "Start" : "End"} Time`}
                type='time'
              />
              {index === 0 && <FontAwesomeIcon icon={faArrowRight} className='custom-yellow-arrow-icon' />}
            </React.Fragment>
          ))}
        </div>

        <div className='custom-modal-buttons w-100 d-flex flex-row bg-white justify-content-between'>
          <ActionButton className='bg-green w-48 font-size-15' title='Apply' onClick={handleSubmit} icon={undefined} />
          <ActionButton className='bg-red w-48 font-size-15' title='Cancel' onClick={onClose} icon={undefined} />
        </div>
      </div>
    </div>
  );
};

export default CreateEventModal;
