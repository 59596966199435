import { useCallback, useState } from "react";
import AddNewField from "./components/AddNewField";
import DraggableInputField from "./components/DraggableInputField";
import { STRINGS } from "../../utils/constants";
import { FormConfiguratorProps, InputField } from "../../utils/types";
import { AddFieldValueModal } from "../AddFieldValueModal";
import SearchInput from "../AdminPortalLayout/SearchInput";
import "./formConfigurator.scss";

const FormConfigurator = ({ inputFields, moveField, setInputFields }: FormConfiguratorProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
  };

  // Filter input fields based on the search query
  const filteredFields = inputFields.filter((field: InputField) => {
    // Search based on field label, name, or placeholder
    return (
      field.label.toLowerCase().includes(searchQuery) ||
      field.name.toLowerCase().includes(searchQuery) ||
      (field.placeholder && field.placeholder.toLowerCase().includes(searchQuery))
    );
  });

  const handleChange = (fieldName: string, value: unknown) => {
    // Handle the change event
    console.log(`Field ${fieldName} changed to:`, value);
    // You can add your logic here to update the form state
  };

  const toggleModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, []);

  const handleSubmit = useCallback(
    (label: string) => {
      console.log("New field added:", label);
      const newField: InputField = {
        label,
        name: label.toLowerCase().replace(/\s+/g, "_"),
        field: label.toLowerCase().replace(/\s+/g, "_"),
        placeholder: `Enter ${label}`,
        type: "text", // Default type can be updated as needed
        value: "",
        isMandatory: false,
        isCustom: true
      };

      setInputFields([...inputFields, newField]);
      toggleModal();
    },
    [inputFields, setInputFields, toggleModal]
  );

  return (
    <div className='form-configurator'>
      <div className='d-flex justify-content-between align-items-center'>
        <h6 className='font-size-18 color-primary-light fw-bold align-item-center'>Configurator</h6>
        <SearchInput
          inputClassName='bg-white'
          handleSearch={handleSearch}
          searchPlaceholder='Search fields'
          shouldRenderSuggestions={false}
        />
      </div>
      <h6 className='font-size-14 color-primary-dark mb-3'>Add fields by dragging them from list</h6>

      {filteredFields.length > 0 ? (
        filteredFields.map(
          (field: InputField, index: number) =>
            field.type !== "select" && (
              <DraggableInputField
                moveField={moveField}
                currentIndex={index}
                isConfigureMode
                allDisabled
                key={field.name}
                field={field}
                onChange={(value) => handleChange(field.name, value)}
              />
            )
        )
      ) : (
        <p>{STRINGS.NO_FIELDS_FOUND}</p>
      )}
      <AddNewField onAddNewField={toggleModal} />

      <AddFieldValueModal
        fieldLabel='Add Field'
        fieldPlaceholder='Field Name'
        isOpen={isModalOpen}
        toggle={toggleModal}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default FormConfigurator;
