import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSelector } from "react-redux";
import CustomizableEditCompanyUser from "./CustomizableEditCompanyUser";
import { BreadCrumbs } from "../../../components";
import NavigationWrapper from "../../../components/NavigationWrapper";
import { RootState } from "../../../redux/store";
import { editTitleMap } from "../../../utils/constants";
import routes from "../../../utils/constants/routes";
import "./styles.companyUserDetail.scss";

const CompanyUserDetails: React.FC = () => {
  const { selectedCompanyUser } = useSelector((state: RootState) => state.companyUsers);
  const pathSegment = location.pathname.split("/").pop();
  const title = editTitleMap[pathSegment as keyof typeof editTitleMap] || "Edit Company User:";

  return (
    <DndProvider backend={HTML5Backend}>
      <div className='d-flex flex-column'>
        <BreadCrumbs
          breadcrumbItems={[
            {
              text: "Company Details",
              url: routes.COMPANY_DETAILS
            },
            { text: "Company User Details", url: location.pathname }
          ]}
        />
        <NavigationWrapper
          title={`${title} ${selectedCompanyUser?.userId?.firstName ?? ""} ${selectedCompanyUser?.userId.lastName ?? ""}`}
        >
          <div className='scrollable-container'>
            <CustomizableEditCompanyUser data={{ personData: selectedCompanyUser }} />
          </div>
        </NavigationWrapper>
      </div>
    </DndProvider>
  );
};

export default CompanyUserDetails;
