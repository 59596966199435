import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BUTTON_TITLE, TITLES } from "../../utils/constants";
import { ConfirmationModalProps } from "../../utils/types";
import ActionButton from "../ActionButton";

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  message,
  onConfirm,
  onCancel,
  title = TITLES.CONFIRMATION
}) => {
  return (
    <Modal isOpen={true} toggle={onCancel} centered>
      <ModalHeader toggle={onCancel}>{title}</ModalHeader>
      <ModalBody>
        <p>{message}</p>
      </ModalBody>
      <ModalFooter>
        <ActionButton title={BUTTON_TITLE.NO} className='save-button' onClick={onCancel} />
        <ActionButton title={BUTTON_TITLE.YES} className='cancel-button' onClick={onConfirm} />
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
