import * as Yup from "yup";
import { toCamelCase } from "../helpers";
import { ConfigureTabProps, DraggableFieldValueProps, InputField } from "../types";

export const createNewTab = (tabs: ConfigureTabProps[], label: string): ConfigureTabProps => {
  const fieldName = toCamelCase(label);
  const newTabId = tabs.length + 1;
  return {
    id: newTabId,
    label: label,
    value: fieldName,
    content: []
  };
};

export const updateTabsWithNewTab = (tabs: ConfigureTabProps[], newTab: ConfigureTabProps): ConfigureTabProps[] => {
  return [...tabs, newTab];
};

export const updateTabContent = (tabs: ConfigureTabProps[], updatedTab: ConfigureTabProps): ConfigureTabProps[] => {
  return tabs.map((item: ConfigureTabProps) => {
    if (item.id === updatedTab.id) {
      return updatedTab;
    }
    return item;
  });
};

export const filterInputFieldsData = (inputFieldsData: InputField[], updatedTab: ConfigureTabProps): InputField[] => {
  return inputFieldsData.filter((inputField: InputField) => {
    if (Array.isArray(updatedTab.content)) {
      return !updatedTab.content.some((tabField: InputField) => tabField.name === inputField.name);
    }
    return true;
  });
};

export const moveFieldInTab = (
  tabs: ConfigureTabProps[],
  activeTabValue: string,
  direction: string,
  fieldName?: string
): ConfigureTabProps[] => {
  return tabs.map((tab) => {
    if (tab.value === activeTabValue && Array.isArray(tab.content)) {
      const index = tab.content.findIndex((item) => item.name === fieldName);
      if (
        (direction === "down" && index !== -1 && index < tab.content.length - 1) ||
        (direction === "up" && index !== -1 && index > 0)
      ) {
        const newContent = [...tab.content];
        const swapIndex = direction === "down" ? index + 1 : index - 1;
        [newContent[index], newContent[swapIndex]] = [newContent[swapIndex], newContent[index]];
        return { ...tab, content: newContent };
      }
    }
    return tab;
  });
};

export const removeFieldFromTab = (
  tabs: ConfigureTabProps[],
  activeTabValue: string,
  fieldName?: string
): ConfigureTabProps[] => {
  return tabs.map((tab) => {
    if (tab.value === activeTabValue) {
      return {
        ...tab,
        content: tab.content?.filter((item) => item.name !== fieldName) ?? []
      };
    }
    return tab;
  });
};

export const addFieldToInputFieldsData = (inputFieldsData: InputField[], field: InputField): InputField[] => {
  const isExist = inputFieldsData.find((item: InputField) => item.name === field.name);
  if (!isExist) return [...inputFieldsData, field];
  return inputFieldsData;
};

export const dragFieldInTab = (
  tabs: ConfigureTabProps[],
  activeTabValue: string,
  dragIndex: number,
  hoverIndex: number,
  field: InputField
): ConfigureTabProps[] => {
  return tabs.map((tab) => {
    if (tab.value === activeTabValue && Array.isArray(tab.content)) {
      const newContent = [...tab.content];

      const dragIndexField = newContent.find((item) => item.name == field.name);
      if (!dragIndexField) {
        return tab;
      }
      // Check if indices are valid
      if (dragIndex < 0 || dragIndex >= newContent.length || hoverIndex < 0 || hoverIndex >= newContent.length) {
        return tab;
      }
      // Check if either the dragged field or the target field is fixed
      if (newContent[dragIndex]?.isFixed || newContent[hoverIndex]?.isFixed) {
        return tab;
      }
      // Find the actual insertion index, skipping over fixed fields
      let actualHoverIndex = hoverIndex;
      const direction = hoverIndex > dragIndex ? 1 : -1;

      while (actualHoverIndex !== dragIndex && newContent[actualHoverIndex]?.isFixed) {
        actualHoverIndex -= direction;
      }

      // If we couldn't find a non-fixed position, return the original tab
      if (actualHoverIndex === dragIndex) {
        return tab;
      }

      // Perform the move
      const [removedField] = newContent.splice(dragIndex, 1);
      newContent.splice(actualHoverIndex, 0, removedField);

      return { ...tab, content: newContent };
    }
    return tab;
  });
};

export const getFormikValidationSchema = (fields: InputField[]) => {
  const schemaFields: { [key: string]: unknown } = {};

  fields.forEach((field: InputField) => {
    let fieldSchema;

    switch (field.type) {
      case "text":
      case "textarea":
        fieldSchema = Yup.string();
        break;
      case "number":
        fieldSchema = Yup.number()
          .min(field.min || -Infinity)
          .max(field.max || Infinity);
        break;
      case "date":
        fieldSchema = Yup.date();
        break;
      case "boolean":
        fieldSchema = Yup.boolean();
        break;
      case "array":
        fieldSchema = Yup.array().of(Yup.date());
        break;
      default:
        fieldSchema = Yup.mixed();
    }

    if (field.isMandatory) {
      fieldSchema = fieldSchema.required(`${field.label} is required`);
    } else {
      fieldSchema = fieldSchema.optional();
    }

    schemaFields[field.name] = fieldSchema;
  });

  return Yup.object().shape(schemaFields as Yup.ObjectShape);
};

export const getFormikInitialValues = (fields: InputField[]) => {
  const initialValues: { [key: string]: unknown } = {};

  fields.forEach((field: InputField) => {
    switch (field.type) {
      case "text":
      case "textarea":
        initialValues[field.name] = field.value || "";
        break;
      case "number":
        initialValues[field.name] = field.value || 0;
        break;
      case "date":
        initialValues[field.name] = field.value || null;
        break;
      case "boolean":
        initialValues[field.name] = field.value || false;
        break;
      case "array":
        initialValues[field.name] = field.value || [null];
        break;
      default:
        initialValues[field.name] = field.value || null;
    }
  });

  return initialValues;
};

export const getFieldValue = (field: InputField, values?: object | DraggableFieldValueProps) => {
  if (values && typeof values === "object" && field.name in values) {
    return (values as Record<string, unknown>)[field.name];
  }
  return field.value;
};
