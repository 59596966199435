import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { jurorGridData } from "../../utils/constants";
import { AddNewFormValues, Juror } from "../../utils/types";
import { SelectedParticipantProps } from "../../utils/types/index";
import { JurorState } from "../types";

const initialState: JurorState = {
  juror: jurorGridData
};

const jurorSlice = createSlice({
  name: "juror",
  initialState,
  reducers: {
    addJuror: (state, action: PayloadAction<AddNewFormValues>) => {
      const newJuror: Juror = {
        ...action.payload,
        jurorRate: action.payload.jurorRate || "",
        actions: action.payload.actions || ""
      };
      state.juror.unshift(newJuror);
    },
    deleteJuror: (state, action: PayloadAction<string>) => {
      state.juror = state.juror.filter((doc) => doc.email !== action.payload);
    },
    setJuror: (state, action: PayloadAction<Juror[]>) => {
      state.juror = action.payload;
    },
    updateJuror: (state, action: PayloadAction<Juror | SelectedParticipantProps>) => {
      const index = state.juror.findIndex(
        (j) =>
          j.email === action.payload.email ||
          (j.firstName === action.payload.firstName && j.lastName && action.payload.lastName)
      );
      if (index !== -1) {
        state.juror[index] = action.payload;
      }
    }
  }
});

export const { addJuror, deleteJuror, setJuror, updateJuror } = jurorSlice.actions;

export default jurorSlice.reducer;
