import { LicenseManager } from "ag-grid-enterprise";
import React, { useMemo } from "react";
import GridTable, { GridProps } from "./GridTable";

import "./AgGrid.scss";
import "rc-dock/dist/rc-dock.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-balham.css";

LicenseManager.setLicenseKey(
  "CompanyName=Brians Com LLC,LicensedGroup=Protovate-1,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-033265,SupportServicesEnd=26_November_2023_[v2]_MTcwMDk1NjgwMDAwMA==e06890fd3dc491144a33daab1bbf3843"
);

const AgGrid: React.FC<GridProps> = ({ pagination = true, paginationPageSize = 50, ...props }) => {
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 90,
      resizable: true
    };
  }, []);

  return (
    <div className='grid-table'>
      <GridTable
        defaultColDef={defaultColDef}
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        cacheBlockSize={paginationPageSize}
        animateRows
        {...props}
      />
    </div>
  );
};
export default AgGrid;
