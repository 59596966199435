import React from "react";
import { AddMoreProps } from "../../utils/types";

import "./styles.addMore.scss";

const AddMore: React.FC<AddMoreProps> = ({ onClick, title, disabled = false, btnClassName }) => {
  return (
    <button className={`add-more-button ${btnClassName}`} disabled={disabled} onClick={onClick}>
      <span className='icon'>+</span>
      <span className='text'>{title}</span>
    </button>
  );
};

export default AddMore;
