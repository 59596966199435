import * as Yup from "yup";
import { AddNewFormValues } from "../../../utils/types";

export const initialValues: AddNewFormValues = {
  firstName: "",
  lastName: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  email: "",
  phoneNumber: "",
  seatNumber: ""
};

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email format"), //.optional("Email is required"),
  phoneNumber: Yup.string().optional(),
  city: Yup.string().optional(),
  state: Yup.string().optional(),
  country: Yup.string().optional(),
  zipCode: Yup.string().optional(),
  seatNumber: Yup.string().optional()
});
