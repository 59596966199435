import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { BUTTON_TITLE } from "../../utils/constants";
import { SOCKET_EVENTS } from "../../utils/constants/socketEvents";
import { NotepadProps } from "../../utils/types";
import ActionButton from "../ActionButton";
import NotesCard from "../NotesCard/NotesCard";

import "./notepad.scss";

const Notepad = ({
  title,
  placeholderText = "Type new notes here...",
  disabled = false,
  handleSaveNote,
  notesHistory
}: NotepadProps) => {
  const [noteValue, setNoteValue] = useState<string>("");
  const user = useSelector((state: RootState) => state.users.user);

  const onSaveNote = useCallback(() => {
    if (handleSaveNote) {
      handleSaveNote({
        note: {
          note: noteValue,
          creator: {
            firstName: "",
            lastName: "",
            id: user?.id || ""
          }
        },
        type: SOCKET_EVENTS.ADD_NOTE,
        eventType: SOCKET_EVENTS.MANAGE_NOTE
      });
      setNoteValue("");
    }
  }, [handleSaveNote, noteValue, user?.id]);

  return (
    <div className='np-container'>
      <div className='np-header'>
        <span>{title}</span>
      </div>
      <div className='np-body'>
        {!disabled && (
          <div
            className='np-textarea-wrap'
            style={{
              ...(notesHistory.length > 0 || noteValue.length > 0 ? {} : { flex: 1 })
            }}
          >
            <textarea
              className='np-textarea'
              placeholder={placeholderText}
              value={noteValue}
              onChange={(e) => setNoteValue(e.target.value)}
              style={{
                ...(notesHistory.length > 0 || noteValue.length > 0 ? { height: "93px" } : { display: "flex", flex: 1 })
              }}
            />
          </div>
        )}
        {noteValue.length > 0 && !disabled ? (
          <div className='pl-2 pt-3'>
            <ActionButton className='save-button' title={BUTTON_TITLE.SAVE} onClick={onSaveNote} />
          </div>
        ) : null}

        {notesHistory.length > 0 ? (
          <div className='np-history'>
            {notesHistory.map((note, index) => (
              <NotesCard note={note} key={index} />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Notepad;
