import { useFormik } from "formik";
import React, { useCallback } from "react";
import { BUTTON_TITLE, FORM_LABELS, FORM_NAMES, jurorEvaluationTabsData, TITLES } from "../../../../../utils/constants";
import { JurorEvaluationProps, RatingValue } from "../../../../../utils/types";
import ActionButton from "../../../../ActionButton";
import RatingField from "../../../../RatingField";
import WrapperComponent from "../../../../WrapperComponent";
import { bodyLangInitialValues, bodyLangValidationSchema } from "../helper";

import "../jurorEvaluation.scss";

const BodyLanguage: React.FC<JurorEvaluationProps> = ({ handleTabChange }) => {
  const formik = useFormik({
    initialValues: bodyLangInitialValues,
    validationSchema: bodyLangValidationSchema,
    onSubmit: (values) => {
      console.log("Form data", values);
    }
  });

  const { values, setFieldValue, resetForm, handleSubmit } = formik;

  const handleRatingChange = useCallback(
    (field: string, value: RatingValue) => {
      setFieldValue(field, value);
    },
    [setFieldValue]
  );

  const handleCommentChange = useCallback(
    (field: string, comment: string) => {
      setFieldValue(field, comment);
    },
    [setFieldValue]
  );

  return (
    <div className='juror-evaluation-container'>
      <WrapperComponent title={TITLES.BODY_LANGUAGE}>
        <form onSubmit={handleSubmit}>
          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.POSTURE}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.POSTURE, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.POSTURE_COMMENT, comment)}
              initialRating={values[FORM_NAMES.POSTURE] as RatingValue}
              value={values[FORM_NAMES.POSTURE_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.EYE_CONTACT}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.EYE_CONTACT, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.EYE_CONTACT_COMMENT, comment)}
              initialRating={values[FORM_NAMES.EYE_CONTACT] as RatingValue}
              value={values[FORM_NAMES.EYE_CONTACT_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.ACTIVE_LISTENING}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.ACTIVE_LISTENING, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.ACTIVE_LISTENING_COMMENT, comment)}
              initialRating={values[FORM_NAMES.ACTIVE_LISTENING] as RatingValue}
              value={values[FORM_NAMES.ACTIVE_LISTENING_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.EMOTIONAL_DISPLAYS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.EMOTIONAL_DISPLAYS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.EMOTIONAL_DISPLAYS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.EMOTIONAL_DISPLAYS] as RatingValue}
              value={values[FORM_NAMES.EMOTIONAL_DISPLAYS_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.COLLABORATIVE_BEHAVIOR}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.COLLABORATIVE_BEHAVIOR, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.COLLABORATIVE_BEHAVIOR_COMMENT, comment)}
              initialRating={values[FORM_NAMES.COLLABORATIVE_BEHAVIOR] as RatingValue}
              value={values[FORM_NAMES.COLLABORATIVE_BEHAVIOR_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.CONFIDENT_POSTURE}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.CONFIDENT_POSTURE, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.CONFIDENT_POSTURE_COMMENT, comment)}
              initialRating={values[FORM_NAMES.CONFIDENT_POSTURE] as RatingValue}
              value={values[FORM_NAMES.CONFIDENT_POSTURE_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.ADAPTATION}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.ADAPTATION, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.ADAPTATION_COMMENT, comment)}
              initialRating={values[FORM_NAMES.ADAPTATION] as RatingValue}
              value={values[FORM_NAMES.ADAPTATION_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.GESTURES}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.GESTURES, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.GESTURES_COMMENT, comment)}
              initialRating={values[FORM_NAMES.GESTURES] as RatingValue}
              value={values[FORM_NAMES.GESTURES_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.FACIAL_EXPRESSIONS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.FACIAL_EXPRESSIONS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.FACIAL_EXPRESSIONS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.FACIAL_EXPRESSIONS] as RatingValue}
              value={values[FORM_NAMES.FACIAL_EXPRESSIONS_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.DISTRACTED_BEHAVIOR}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.DISTRACTED_BEHAVIOR, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.DISTRACTED_BEHAVIOR_COMMENT, comment)}
              initialRating={values[FORM_NAMES.DISTRACTED_BEHAVIOR] as RatingValue}
              value={values[FORM_NAMES.DISTRACTED_BEHAVIOR_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.BODY_TENSION}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.BODY_TENSION, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.BODY_TENSION_COMMENT, comment)}
              initialRating={values[FORM_NAMES.BODY_TENSION] as RatingValue}
              value={values[FORM_NAMES.BODY_TENSION_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.DOMINANT_PASSIVE_BEHAVIOR}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.DOMINANT_PASSIVE_BEHAVIOR, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.DOMINANT_PASSIVE_BEHAVIOR_COMMENT, comment)}
              initialRating={values[FORM_NAMES.DOMINANT_PASSIVE_BEHAVIOR] as RatingValue}
              value={values[FORM_NAMES.DOMINANT_PASSIVE_BEHAVIOR_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.ASSERTIVE_BEHAVIOR}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.ASSERTIVE_BEHAVIOR, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.ASSERTIVE_BEHAVIOR_COMMENT, comment)}
              initialRating={values[FORM_NAMES.ASSERTIVE_BEHAVIOR] as RatingValue}
              value={values[FORM_NAMES.ASSERTIVE_BEHAVIOR_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.FLEXIBILITY}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.FLEXIBILITY, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.FLEXIBILITY_COMMENT, comment)}
              initialRating={values[FORM_NAMES.FLEXIBILITY] as RatingValue}
              value={values[FORM_NAMES.FLEXIBILITY_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
            <ActionButton
              title={BUTTON_TITLE.SAVE}
              className='save-button'
              onClick={() => {
                handleTabChange?.(jurorEvaluationTabsData[2]);
                resetForm();
              }}
            />
            <ActionButton
              title={BUTTON_TITLE.CANCEL}
              onClick={() => {
                handleTabChange?.(jurorEvaluationTabsData[0]);
                resetForm();
              }}
              className='cancel-button'
            />
          </div>
        </form>
      </WrapperComponent>
    </div>
  );
};

export default BodyLanguage;
