import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { SOCKET_EVENTS, NOTE_TYPES, STRINGS } from "../../../../utils/constants";
import { CompanyUser, FieldData, InputField, SelectedParticipantProps } from "../../../../utils/types";
import { AnervaServerResponseProps, EventData, Note } from "../../../../utils/types";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().optional(),
  lastName: Yup.string().optional(),
  pronouns: Yup.string().optional(),
  preferredTitle: Yup.string().optional(),
  age: Yup.number().optional().positive().integer(),
  sex: Yup.string().optional(),
  gender: Yup.string().optional(),
  occupation: Yup.string().optional(),
  educationLevel: Yup.string().optional(),
  address: Yup.string().optional(),
  city: Yup.string().optional(),
  state: Yup.string().optional(),
  country: Yup.string().optional(),
  zipCode: Yup.string().optional(),
  relationshipStatus: Yup.string().optional()
});

export const getFormikInitialValues = (fields: InputField[]) => {
  const initialValues: { [key: string]: unknown } = {};

  fields.forEach((field: InputField) => {
    // Ensure `field.field` is defined before using it as a key
    if (field.field) {
      switch (field.type) {
        case "text":
        case "string":
        case "textarea":
          initialValues[field.field] = field.value || "";
          break;
        case "number":
          initialValues[field.field] = field.value || 0;
          break;
        case "date":
          initialValues[field.field] = field.value || null;
          break;
        case "boolean":
          initialValues[field.field] = field.value || false;
          break;
        case "array":
          initialValues[field.field] = field.value || [null];
          break;
        default:
          initialValues[field.field] = field.value || null;
      }
    }
  });

  return initialValues;
};

export const createParticipantDetailsPayload = (
  selectedParticipant: SelectedParticipantProps,
  userId: string,
  caseId?: string,
  participantId?: string
) => {
  return {
    ...selectedParticipant,
    caseId: caseId || "",
    participantId: participantId || "",
    userId: userId || "",
    eventType: SOCKET_EVENTS.MANAGE_PARTICIPANT_DETAILS,
    type: SOCKET_EVENTS.PARTICIPANT_AND_DETAILS_UPDATED,
    requestId: uuidv4()
  };
};

export const transformCompanyUserData = (user: CompanyUser): FieldData[] => {
  if (!user) return [];

  return [
    { name: "firstName", label: "First Name", type: "string", value: user.firstName ?? user.userId?.firstName ?? "" },
    { name: "lastName", label: "Last Name", type: "string", value: user.lastName ?? user.userId?.lastName ?? "" },
    { name: "email", label: "Email", type: "string", value: user.email ?? user.userId?.email ?? "" },
    { name: "role", label: "Role Name", type: "string", value: user.role?.name ?? user.userId?.role?.name ?? "" }
  ];
};

export const handleSaveNote = (
  socketEmit: <T extends EventData = EventData>(
    eventName: string,
    data: T,
    onSuccess?: (data: AnervaServerResponseProps) => void,
    onFailure?: (data: AnervaServerResponseProps) => void
  ) => void,
  values: {
    note: Note;
    type: string;
    eventType: string;
  },
  selectedCompanyUser: { id?: string; companyId?: string },
  userId: string
) => {
  const isCompanyUserNote = selectedCompanyUser?.id && selectedCompanyUser?.companyId;

  if (socketEmit) {
    const notePayload = {
      type: values.type,
      createdBy: userId || "",
      eventType: values.eventType,
      requestId: uuidv4(),
      note: values?.note.note,
      noteType: isCompanyUserNote ? NOTE_TYPES.COMPANY_USER : NOTE_TYPES.PARTICIPANT,
      companyUserId: selectedCompanyUser?.id,
      companyId: selectedCompanyUser?.companyId
    };

    socketEmit(
      SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
      notePayload,
      (data) => {
        const resData = data as AnervaServerResponseProps;
        if (resData.success) toast.success(resData.message);
        else if (!resData.success) toast.error(resData.message);
      },
      (data) => {
        const resData = data as AnervaServerResponseProps;
        if (resData.success) toast.success(resData.message);
        else if (!resData.success) toast.error(resData.message);
      }
    );
  } else {
    toast.error(STRINGS.SOCKET_NOT_AVAILABLE);
    return;
  }
};

export const handleUpdateCompanyUser = (
  socketEmit: <T extends EventData = EventData>(
    eventName: string,
    data: T,
    onSuccess?: (data: AnervaServerResponseProps) => void,
    onFailure?: (data: AnervaServerResponseProps) => void
  ) => void,
  data: Partial<CompanyUser>,
  userId: string,
  companyId: string,
  id: string
) => {
  console.log("data and userid: ", data, userId);
  if (socketEmit) {
    const updatePayload = {
      ...data,
      requestId: uuidv4(),
      eventType: SOCKET_EVENTS.MANAGE_COMPANY_USERS,
      type: SOCKET_EVENTS.UPDATE_COMPANY_USER, // Ensure this matches the backend expected type
      createdBy: userId,
      companyId,
      id
    };

    socketEmit(
      SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
      updatePayload,
      (response) => {
        const resData = response as AnervaServerResponseProps;
        if (resData.success) toast.success(resData.message);
        else toast.error(resData.message);
      },
      (response) => {
        const resData = response as AnervaServerResponseProps;
        if (resData.success) toast.success(resData.message);
        else toast.error(resData.message);
      }
    );
  } else {
    toast.error(STRINGS.SOCKET_NOT_AVAILABLE);
  }
};
