import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import UserRoleCard from "./userRoleCard";
import NavigationWrapper from "../../../components/NavigationWrapper";
import SelectComponent from "../../../components/SelectComponent";
import WrapperComponent from "../../../components/WrapperComponent";
import { RootState } from "../../../redux/store";
import { TITLES } from "../../../utils/constants";
import useSocket from "../../../utils/hooks/sockets";
import { Company } from "../../../utils/types";
import { fetchAllCompaniesFromServer } from "../companies/helpers";
import { fetchAllCompanyUsersFromServer } from "../companyDetails/helpers";

import "./styles.switchUserRoles.scss";

type SwitchUserRolesProps = object;

const SwitchUserRoles: React.FC<SwitchUserRolesProps> = () => {
  const { companiesList, selectedCompany } = useSelector((state: RootState) => state.companies);
  const user = useSelector((state: RootState) => state.users.user);
  const { companyUsersList } = useSelector((state: RootState) => state.companyUsers);
  const [selectedUserCompany, setSelectedCompany] = useState<string>("");
  const { socketEmit } = useSocket();
  const fetchedRef = useRef(false);
  const fetchedRefCompanies = useRef(false);

  const companyOptions = companiesList.length
    ? companiesList.map((company: Company) => ({
        value: company.id as string,
        label: company.companyName
      }))
    : [];

  useEffect(() => {
    fetchAllCompaniesFromServer(socketEmit, fetchedRef, user?.id || "");
  }, [socketEmit, user?.id]);

  useEffect(() => {
    if (selectedCompany && !selectedUserCompany) {
      setSelectedCompany(selectedCompany?.id || "");
      fetchAllCompanyUsersFromServer(socketEmit, selectedCompany?.id || "", user?.id || "", fetchedRefCompanies);
    }
  }, [selectedCompany, selectedUserCompany, socketEmit, user?.id]);

  const handleCompanySelect = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedCompany(event.target.value);
      fetchAllCompanyUsersFromServer(socketEmit, event.target.value || "", user?.id || "");
    },
    [socketEmit, user?.id]
  );
  return (
    <NavigationWrapper
      title={TITLES.SWITCH_USER_ROLES}
      goBack={false}
      headerChild={
        <div className='companies-dropdown'>
          <SelectComponent
            label='Company'
            hideLabel
            items={companyOptions}
            value={selectedUserCompany}
            onChange={handleCompanySelect}
            errorMessage={selectedUserCompany ? "" : "Please select a company"}
            layout='row'
            placeholder='Select a company'
          />
        </div>
      }
    >
      <WrapperComponent>
        {selectedUserCompany && selectedUserCompany.length > 0 ? (
          <UserRoleCard companyUsersList={companyUsersList} />
        ) : (
          <div className='noRecordsMessage'>Please select company from dropdown.</div>
        )}
      </WrapperComponent>
    </NavigationWrapper>
  );
};

export default SwitchUserRoles;
