import { AxiosInstance } from "axios";
import getInstance from "../utils/axiosInstance";
import { API_END_POINTS } from "../utils/constants/apiEndPoints";

interface VerifyTokenResponse {
  code: number;
  message: string;
  token?: string;
}

const MAX_RETRIES = 3; // Max number of retries
const RETRY_DELAY = 1000; // Delay in ms between retries (increase exponentially)

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
/**
 * Service to verify if the current token is valid.
 *
 * @returns {Promise<boolean>} - Returns true if the token is valid, false otherwise.
 */
export const verifyToken = async (token: string): Promise<VerifyTokenResponse | null> => {
  let retries = 0;

  while (retries < MAX_RETRIES) {
    try {
      const axiosInstance: AxiosInstance | null = await getInstance(token);

      if (!axiosInstance) {
        throw new Error("Failed to create Axios instance");
      }

      const response = await axiosInstance.get<VerifyTokenResponse>(API_END_POINTS.VERIFY_TOKEN);

      console.log("The response of verification of token is", response.data);
      return response?.data ?? null;
    } catch (error) {
      console.error(`Token verification attempt ${retries + 1} failed:`, error);

      if (retries >= MAX_RETRIES - 1) {
        console.error("Max retries reached. Token verification failed.");
        return null;
      }

      retries += 1;
      await delay(RETRY_DELAY * retries); // Exponential backoff
    }
  }

  return null;
};

/**
 * Service to verify if the current token is valid.
 *
 * @returns {Promise<boolean>} - Returns true if the token is valid, false otherwise.
 */
export const refreshToken = async (userId: string, token: string): Promise<VerifyTokenResponse | null> => {
  try {
    const axiosInstance = await getInstance(token);
    const data = {
      id: userId,
      token: token
    };
    const response = await axiosInstance.post<VerifyTokenResponse>(API_END_POINTS.REFRESH_TOKEN, data);
    console.log("The response of refresh Token is", response.data);
    return response?.data ?? null;
  } catch (error) {
    console.error("Token Refresh failed:", error);
    return null;
  }
};
