import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { capitalizeFirstLetter } from "../../../../utils/helpers";
import { BasicInfoField, ExperienceData, ParticipantBasicInfoProps } from "../../../../utils/types";

import "./style.Experience.scss";

const Field: React.FC<{ label: string; value: string | number | string[] | undefined }> = ({ label, value }) => (
  <div className='experience-field'>
    <strong>{label}:</strong>
    <p>{value ? capitalizeFirstLetter(String(value)) : "N/A"}</p>
  </div>
);

const SocialLinks: React.FC<{ links: BasicInfoField[] | undefined }> = ({ links }) => (
  <div className='social-links'>
    {links?.map((link, index) => (
      <a key={index} href={`https://${String(link.value)}`} target='_blank' rel='noopener noreferrer'>
        {link.label}
      </a>
    ))}
  </div>
);

const Experience: React.FC<ParticipantBasicInfoProps> = () => {
  const { selectedParticipant } = useSelector((state: RootState) => state.participants);
  const experienceData: ExperienceData[] = selectedParticipant?.participantDetails?.[0]?.experience || [];

  return (
    <section className='experience-section'>
      {experienceData.map((experience, index) => {
        return experience.isCustom ? (
          <div className='experience-grid'>
            <Field label={experience?.label as string} value={experience?.value as string} />
          </div>
        ) : (
          <div key={index} className='experience-card'>
            <div className='experience-grid'>
              {/* Company Details */}
              {experience?.companyDetails && (
                <>
                  <Field label='Company Name' value={experience.companyDetails?.name?.value as string} />
                  <Field label='Company Address' value={experience.companyDetails?.address?.value as string} />
                  <Field label='Industry' value={experience.companyDetails?.industry?.value as string} />
                  <Field label='Company Size' value={experience.companyDetails?.size?.value as string} />
                  <Field label='Founded Year' value={experience.companyDetails?.founded?.value as string} />
                </>
              )}
              {/* Role Details */}
              {experience.roleDetails && (
                <>
                  <Field label='Job Title' value={experience.roleDetails?.title?.value as string} />
                  <Field label='Job Class' value={experience.roleDetails?.class?.value as string} />
                  <Field label='Role' value={experience.roleDetails?.role?.value as string} />
                  <Field label='Sub Role' value={experience.roleDetails?.subRole?.value as string} />
                  <Field label='Levels' value={experience.roleDetails?.levels?.value as string} />
                </>
              )}
              {/* Dates */}
              <Field label='Start Date' value={experience.startDate?.value as string} />
              <Field label='End Date' value={experience.endDate?.value as string} />
              {/* Location */}
              {experience.location && <Field label='Location' value={experience.location?.value as string} />}
              {/* Summary */}
              {experience.summary && <Field label='Job Summary' value={experience.summary?.value as string} />}{" "}
            </div>

            {/* Social Links */}
            <SocialLinks links={experience.companyDetails?.socialLinks} />
          </div>
        );
      })}
    </section>
  );
};

export default Experience;
