import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { initializeTabs } from "./helper";
import { RootState } from "../../../../redux/store";
import { ConfigureTabProps, EditCandidateProps, Tab, TabDataField } from "../../../../utils/types";
import TabButtons from "../../../TabButtons";
import {
  Demographic,
  EducationInfo,
  ExperienceInfo,
  ParticipantBasicInfo,
  SkillsInfo,
  SocialProfiles
} from "../../ParticipantInfoTabs";

const ViewParticipantDetails: React.FC<EditCandidateProps> = () => {
  const [tabs, setTabs] = useState<ConfigureTabProps[]>([]);
  const [activeTab, setActiveTab] = useState<ConfigureTabProps | undefined>(undefined);
  const { selectedParticipant } = useSelector((state: RootState) => state?.participants);

  const excludeTabs = ["alternativeNames"];

  useEffect(() => {
    if (selectedParticipant?.participantDetails) {
      initializeTabs(selectedParticipant, setTabs, setActiveTab);
    }
  }, [selectedParticipant]);

  const handleTabChange = useCallback((tab: ConfigureTabProps) => {
    setActiveTab(tab);
  }, []);

  const renderTabContent = () => {
    return tabs
      .filter((tab) => !excludeTabs.includes(tab.value)) // Exclude specified tabs
      .map((tab) => {
        if (activeTab?.value === tab.value) {
          const tabData: TabDataField[] = selectedParticipant?.participantDetails?.[0]?.[tab.value] || [];

          return (
            <div key={tab.value}>
              {tab.value === "basic" && <ParticipantBasicInfo basicData={tabData} />}
              {tab.value === "demographic" && <Demographic data={tabData} />}
              {tab.value === "education" && <EducationInfo />}
              {tab.value === "experience" && <ExperienceInfo />}
              {tab.value === "skills" && <SkillsInfo />}
              {tab.value === "socialProfiles" && <SocialProfiles />}

              {!["basic", "demographic", "education", "skills", "socialProfiles", "experience"].includes(tab.value) && (
                <Demographic data={tabData} />
              )}
            </div>
          );
        }
        return null;
      });
  };

  return (
    <div>
      <div className='d-flex flex-row align-items-center justify-content-between'>
        <TabButtons
          tabs={tabs.filter((tab) => !excludeTabs.includes(tab.value)) as Tab[]}
          activeTab={activeTab as Tab}
          onTabChange={handleTabChange}
        />
      </div>

      <div>{renderTabContent()}</div>
    </div>
  );
};

export default ViewParticipantDetails;
