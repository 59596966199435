export const NOTE_TYPES = {
  PARTICIPANT: "participant",
  CASE: "case",
  COMPANY_USER: "companyUser"
};

/**
 * An enumeration of possible field types for different categories of data.
 * These field types represent the various sections or types of information in the system.
 */
export const REACTION_FIELD_TYPES = {
  /**
   * "User Details" indicates fields related to user-specific information.
   * - Examples: First name, last name, email, role, etc.
   */
  USER_DETAILS: "User Details",

  /**
   * "Participant Details" indicates fields related to participant-specific information.
   * - Examples: Participant name, address, status, background info, etc.
   */
  PARTICIPANT_DETAILS: "Participant Details",

  /**
   * "Case Details" indicates fields related to case-specific information.
   * - Examples: Case number, case name, court documents, evidence, etc.
   */
  CASE_DETAILS: "Case Details"
};
