/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { setSelectedCase } from "../../redux/slices/casesSlices";
import { setSelectedCompany } from "../../redux/slices/companiesSlices";
import { setCompanyUser, setSelectedCompanyUser } from "../../redux/slices/companyUsersSlice";
import { setActiveMenu } from "../../redux/slices/menuActionsSlice";
import { setSelectedParticipant, setTrialTeamParticipants } from "../../redux/slices/participantsSlice";
import { logout } from "../../redux/slices/userSlice";
import { verifyToken } from "../../services/login";
import routes from "../constants/routes";

/**
 * Verifies the token and handles logout if the token is invalid.
 *
 * @param {Dispatch} dispatch - Redux dispatch function.
 * @param {NavigateFunction} navigate - Function to navigate between routes.
 * @returns {Promise<boolean>} - Returns true if the token is valid, false otherwise.
 */
export const isVerifyToken = async (
  token: string,
  dispatch: Dispatch,
  navigate: NavigateFunction
): Promise<boolean> => {
  try {
    const tokenValid = await verifyToken(token);

    // Check if the token is valid
    if (!tokenValid?.code || tokenValid?.code !== 200) {
      logOutUser(dispatch);
      window.location.href = routes.LOGIN;
      navigate(routes.LOGIN);
      localStorage.setItem("logout-event", Date.now().toString());
      toast.error("Your session has expired. Please login again.");
      return false;
    }

    console.log("isVerifyToken: ", tokenValid);
    return true;
  } catch (error) {
    console.error("Token verification failed:", error);
    // dispatch(logout());
    // navigate(routes.LOGIN);
    return true;
  }
};

export const logOutUser = (dispatch: Dispatch) => {
  dispatch(logout());
  dispatch(setSelectedCase(null));
  dispatch(setSelectedCompany(null));
  dispatch(setCompanyUser(null));
  dispatch(setSelectedCompanyUser(null));
  dispatch(setSelectedParticipant(null));
  dispatch(setActiveMenu(""));
  dispatch(setTrialTeamParticipants([]));
};
