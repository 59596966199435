import React from "react";
import { StatisticsCardContainerProps } from "../../../../utils/types";

import "./styles.statisticsCardContainer.scss";

const StatisticsCardContainer: React.FC<StatisticsCardContainerProps> = ({
  headerText,
  numberWithPercentage,
  children,
  className
}) => {
  const renderPercentagewithColor = (percentage: string) => {
    const percentages = percentage.split(" ");
    return percentages.map((percentage, index) => {
      return (
        <span key={index} className={`number-value color-${index}`}>
          {percentage}
        </span>
      );
    });
  };

  return (
    <div className={`statistics-card ${className}`}>
      <div className='header'>
        <div className='text'>{headerText}</div>
        {numberWithPercentage && <div className='number'>{renderPercentagewithColor(numberWithPercentage)}</div>}
      </div>
      <div className='body'>{children}</div>
    </div>
  );
};

export default StatisticsCardContainer;
