import { Formik } from "formik";
import { DemographicInformationForm } from "./DemographicInformationForm";
import { demographicsInitialValues, demographicsValidationSchema } from "./helpers";
import { DemographicsFormValueProps, PersonalDataProps } from "../../../../../utils/types";
import WrapperComponent from "../../../../WrapperComponent";

const DemographicInformation: React.FC<PersonalDataProps> = ({ handleTabChange }) => {
  return (
    <WrapperComponent title='Demographics'>
      <Formik<DemographicsFormValueProps>
        initialValues={demographicsInitialValues}
        validationSchema={demographicsValidationSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        <DemographicInformationForm handleTabChange={handleTabChange} />
      </Formik>
    </WrapperComponent>
  );
};

export default DemographicInformation;
