import React from "react";
import { WrapperComponentPropTypes } from "../../utils/types";

import "./styles.wrappercomponent.scss";

/**
 * A wrapper component that applies styling to its children.
 * It accepts all standard HTML div attributes and passes them to the wrapper `div` element.
 *
 * @component
 * @example
 * <WrapperComponent className="custom-class" style={{ padding: '10px' }}>
 *   <p>Content goes here</p>
 * </WrapperComponent>
 */
export const WrapperComponent: React.FC<WrapperComponentPropTypes> = ({
  title,
  children,
  onClick,
  customClass,
  ref,
  buttonText,
  ...props
}) => {
  return (
    <div ref={ref} className={`wrapper-component ${customClass || ""}`} {...props}>
      {title && (
        <div className='header-section'>
          <span className='heading'>{title}</span>
          {buttonText && (
            <button className='btn text-btn' onClick={onClick}>
              {buttonText}
            </button>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default WrapperComponent;
