import { Formik } from "formik";
import CommunicationPreferencesForm from "./CommunicationPreferencesForm";
import { initialValues, validationSchema } from "./helper";
import { CommunicationPreferencesFormValueProps, PersonalDataProps } from "../../../../../utils/types";
import WrapperComponent from "../../../../WrapperComponent";

const CommunicationPreferences: React.FC<PersonalDataProps> = ({ handleTabChange, setActiveCard }) => {
  return (
    <WrapperComponent title='Communication Preferences'>
      <Formik<CommunicationPreferencesFormValueProps>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        <CommunicationPreferencesForm setActiveCard={setActiveCard} handleTabChange={handleTabChange} />
      </Formik>
    </WrapperComponent>
  );
};
export default CommunicationPreferences;
