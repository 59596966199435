import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { MESSAGES, SOCKET_EVENTS } from "../../../utils/constants";
import { AnervaServerResponseProps, EventData } from "../../../utils/types";

export const handleNewCompanySave = (
  label: string,
  socketEmit: <T extends EventData = EventData>(
    eventName: string,
    data: T,
    onSuccess?: (data: AnervaServerResponseProps) => void,
    onFailure?: (data: AnervaServerResponseProps) => void
  ) => void,
  toggleModal: () => void,
  userId: string
) => {
  if (!label.trim()) {
    toast.error(MESSAGES.COMPANY_NAME_EMPTY);
    return;
  }

  const newCompany = {
    companyName: label.trim(),
    createdBy: userId,
    type: SOCKET_EVENTS.ADD_COMPANY,
    eventType: SOCKET_EVENTS.MANAGE_COMPANIES,
    requestId: uuidv4()
  };

  if (socketEmit) {
    socketEmit(
      SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
      newCompany,
      (data) => {
        const resData = data as AnervaServerResponseProps;
        if (resData.success) {
          toast.success(resData.message || MESSAGES.NEW_COMPANY_SUCCESS);
        } else {
          toast.error(resData.message || MESSAGES.NEW_COMPANY_FAILURE);
        }
      },
      (data) => {
        const resData = data as AnervaServerResponseProps;
        toast.error(resData.message || MESSAGES.NEW_COMPANY_FAILURE);
      }
    );
  }

  toggleModal(); // Close the modal after submission
};

// Function to fetch all companies from the server
export const fetchAllCompaniesFromServer = (
  socketEmit: <T extends EventData = EventData>(
    eventName: string,
    data: T,
    onSuccess?: (data: AnervaServerResponseProps) => void
  ) => void,
  fetchedRef: React.MutableRefObject<boolean>,
  userId: string
) => {
  if (fetchedRef.current) return; // Exit if already fetched
  if (socketEmit) {
    socketEmit(
      SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
      {
        type: SOCKET_EVENTS.FETCH_COMPANIES,
        eventType: SOCKET_EVENTS.MANAGE_COMPANIES,
        userId: userId,
        requestId: uuidv4()
      },
      () => {
        fetchedRef.current = true;
      }
    );
  }
};

// Function to handle the deletion of a company
export const handleDeleteCompany = (
  socketEmit: <T extends EventData = EventData>(
    eventName: string,
    data: T,
    onSuccess?: (data: AnervaServerResponseProps) => void,
    onFailure?: (data: AnervaServerResponseProps) => void
  ) => void,
  data: { id: string },
  userId: string
) => {
  if (socketEmit) {
    socketEmit(
      SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
      {
        type: SOCKET_EVENTS.DELETE_COMPANY,
        companyId: data?.id,
        userId: userId,
        eventType: SOCKET_EVENTS.MANAGE_COMPANIES,
        requestId: uuidv4()
      },
      ({ success, message }) => {
        if (success) toast.success(message || MESSAGES.DELETE_COMPANY_SUCCESS);
        else toast.error(message || MESSAGES.DELETE_COMPANY_FAILURE);
      }
    );
  }
};

export const handleUpdateCompany = (
  updatedLabel: string, // The updated company name
  companyId: string, // The ID of the company to update
  socketEmit: <T extends EventData = EventData>(
    eventName: string,
    data: T,
    onSuccess?: (data: AnervaServerResponseProps) => void,
    onFailure?: (data: AnervaServerResponseProps) => void
  ) => void,
  toggleModal: () => void, // Function to close the modal after submission
  userId: string
) => {
  // Validate the company name input
  if (!updatedLabel.trim()) {
    toast.error(MESSAGES.COMPANY_NAME_EMPTY);
    return;
  }

  // Prepare the data for updating the company
  const updatedCompanyData = {
    companyName: updatedLabel.trim(), // The updated company name
    id: companyId,
    createdBy: userId, // Pass the user ID
    type: SOCKET_EVENTS.UPDATE_COMPANY,
    eventType: SOCKET_EVENTS.MANAGE_COMPANIES,
    requestId: uuidv4()
  };

  // Check if socketEmit is available and send the update event
  if (socketEmit) {
    socketEmit(
      SOCKET_EVENTS.ANERVA_SERVER_REQUEST, // The event name for server requests
      updatedCompanyData, // The data to send
      (data) => {
        const resData = data as AnervaServerResponseProps;
        if (resData.success) {
          toast.success(resData.message || MESSAGES.UPDATE_COMPANY_SUCCESS);
        } else {
          toast.error(resData.message || MESSAGES.UPDATE_COMPANY_FAILURE);
        }
      },
      (data) => {
        const resData = data as AnervaServerResponseProps;
        toast.error(resData.message || MESSAGES.UPDATE_COMPANY_FAILURE);
      }
    );
  }

  // Close the modal after the submission
  toggleModal();
};
