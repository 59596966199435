import React from "react";
import { DashboardCardPropTypes } from "../../utils/types";

import "./styles.dashboardcard.scss";

export default function DashboardCard(props: DashboardCardPropTypes) {
  return (
    <div className='dashboard-card-container'>
      <div className='left-col'>
        {props?.icon}
        <span className={"title"}>{props?.title}</span>
      </div>
      <span className={`count ${props?.className}`}>{props?.count || 0}</span>
    </div>
  );
}
