import { faThumbsDown, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Rating } from "react-simple-star-rating";
import { Tooltip } from "react-tooltip";
import { Modal, ModalBody } from "reactstrap";
import { getDefaultValues, getSummary, handleCheckboxChange, handleSubmit } from "./helper";
import { RootState } from "../../redux/store";
import { BUTTON_TITLE, FORM_PLACEHOLDERS, STRIKING_REASONS, STRINGS } from "../../utils/constants";
import useSocket from "../../utils/hooks/sockets";
import {
  InputChangeEvent,
  ReactionResult,
  SocketEmitFunction,
  StrikeJurorModalProps,
  StrikeReason,
  StrikingReason
} from "../../utils/types";
import ActionButton from "../ActionButton";
import TextInput from "../TextInput";

import "./strikeJuror.scss";

const StrikeJurorModal: React.FC<StrikeJurorModalProps> = ({
  isOpen = false,
  onClose = () => {},
  currentParticipant = null
}) => {
  const { socketEmit } = useSocket() as { socketEmit: SocketEmitFunction };
  const user = useSelector((state: RootState) => state.users.user);
  const [selectedReasons, setSelectedReasons] = useState(STRIKING_REASONS);
  const [rating, setRating] = useState<number>(1);
  const [isRatingDisabled, setIsRatingDisabled] = useState<boolean>(false);
  const [otherReason, setOtherReason] = useState<string>("");
  const [fieldsReaction, setFieldsReaction] = useState<ReactionResult>({
    likedCount: 0,
    dislikedCount: 0,
    likedLabels: [],
    dislikedLabels: []
  });

  useEffect(() => {
    const { selectedReasons, rating, otherReason, isRatingDisabled } = getDefaultValues(
      currentParticipant,
      setFieldsReaction
    );
    setSelectedReasons(selectedReasons);
    setRating(rating);
    setOtherReason(otherReason);
    setIsRatingDisabled(isRatingDisabled);
  }, [currentParticipant]);

  const handleOtherReasonChange = useCallback((event: InputChangeEvent) => {
    setOtherReason(event.target.value);
  }, []);

  const renderOtherReasonInput = (
    selectedReasons: StrikingReason[],
    otherReason: string,
    handleOtherReasonChange: (event: InputChangeEvent) => void
  ) => {
    return (
      selectedReasons[5]?.value && (
        <TextInput placeholder={FORM_PLACEHOLDERS.REASON_HERE} value={otherReason} onChange={handleOtherReasonChange} />
      )
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        onClose();
        setOtherReason("");
      }}
      className='strike-juror-modal'
    >
      <ModalBody>
        <div className='strike-juror-modal-content'>
          <p className='strike-juror-modal-title'>{STRINGS.STRIKE}</p>
          <p className='juror-subtitle'>Reason for striking?</p>

          <div className='strike-juror-checkbox-group'>
            {selectedReasons.map(({ type, value, text }: StrikeReason) => (
              <div
                key={type}
                className={`custom-checkbox ${value ? "checked" : ""}`}
                onClick={() =>
                  handleCheckboxChange(
                    type,
                    !value,
                    selectedReasons,
                    setSelectedReasons,
                    setIsRatingDisabled,
                    setRating
                  )
                }
              >
                <span className='color-red'>{text}</span>
                <div className={`checkbox-indicator ${value ? "checked" : ""}`} />
              </div>
            ))}

            {renderOtherReasonInput(selectedReasons, otherReason, handleOtherReasonChange)}

            <div className='mb-2 mt-2'>
              <p className='strike-juror-modal-title color-primary-light'>{STRINGS.RATING}</p>
              <Rating
                onClick={(newRating) => setRating(newRating)}
                initialValue={rating}
                size={30}
                fillColor={isRatingDisabled ? "#b61111" : "#137c4e"}
                emptyColor='#dce0d9'
                allowHover={true}
                readonly={isRatingDisabled}
                iconsCount={5}
                transition
              />
            </div>
          </div>

          <div className='d-flex flex-row justify-content-between w-60 mb-4 toggle-icons align-items-center'>
            <div className={`cursor-pointer`} data-tooltip-id='tooltip-like'>
              <FontAwesomeIcon size='2xl' icon={faThumbsUp} style={{ color: "#137c4e" }} />
              <span className='like-dislike-text color-primary-light'>{fieldsReaction?.likedCount || 0}</span>

              <Tooltip
                id='tooltip-like'
                place='left'
                content={getSummary("Liked Fields", fieldsReaction.likedLabels)}
              />
            </div>

            <div className={`cursor-pointer`} data-tooltip-id='tooltip-dislike'>
              <FontAwesomeIcon size='2xl' icon={faThumbsDown} style={{ color: "#b61111" }} />
              <span className='like-dislike-text color-red'>{fieldsReaction?.dislikedCount || 0}</span>

              <Tooltip
                id='tooltip-dislike'
                place='right'
                content={getSummary("Disliked Fields", fieldsReaction.dislikedLabels)}
              />
            </div>
          </div>

          <div className='strike-juror-modal-buttons'>
            <ActionButton
              className='save-button padding-x'
              onClick={() =>
                handleSubmit(
                  socketEmit,
                  currentParticipant,
                  rating,
                  selectedReasons,
                  otherReason,
                  user?.id || "",
                  onClose
                )
              }
              title={BUTTON_TITLE.APPLY}
            />
            <ActionButton className='cancel-button padding-x' onClick={onClose} title={BUTTON_TITLE.CANCEL} />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default StrikeJurorModal;
