import { ColDef, ICellRendererParams } from "ag-grid-community";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { ParticipantBasicInfoProps, SocialProfileData } from "../../../../utils/types";
import AgGrid from "../../../AgGrid";
import "./style.SocialProfiles.scss";

const SocialProfiles: React.FC<ParticipantBasicInfoProps> = () => {
  const { selectedParticipant } = useSelector((state: RootState) => state.participants);
  const socialProfilesData: SocialProfileData[] = selectedParticipant?.participantDetails?.[0]?.socialProfiles || [];

  // Column definitions for Ag-Grid
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Platform",
        field: "details.network.value",
        valueGetter: (params) => params.data?.details?.network?.value || "N/A"
      },
      {
        headerName: "Username",
        field: "details.username.value",
        valueGetter: (params) => params.data?.details?.username?.value || "N/A"
      },
      {
        headerName: "URL",
        field: "details.url.value",
        cellRenderer: (params: ICellRendererParams) => {
          const url = params.data?.details?.url?.value;
          return url ? (
            <a href={`https://${url}`} target='_blank' rel='noopener noreferrer'>
              {url}
            </a>
          ) : (
            "N/A"
          );
        }
      }
    ],
    []
  );

  return (
    <div className='d-flex h-100 flex-column'>
      <div className='social-profiles-section' style={{ height: "60vh" }}>
        <AgGrid columnDefs={columnDefs} rowData={socialProfilesData} pagination={true} paginationPageSize={10} />
      </div>
    </div>
  );
};

export default SocialProfiles;
