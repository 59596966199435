import { FormikValues } from "formik";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { inputFieldsArray } from "../../../../utils/constants/formFieldsJson";
import { SOCKET_EVENTS } from "../../../../utils/constants/socketEvents";
import { formatPhoneNumber } from "../../../../utils/helpers";
import {
  ClientInformationFormValues,
  ConfigureTabProps,
  InputField,
  LegalAndOtherRecordsValues,
  Note,
  ParticipantDetail,
  SelectedCaseProps,
  SelectedParticipantProps,
  SocketEmitFunction
} from "../../../../utils/types";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().optional(),
  lastName: Yup.string().optional(),
  pronouns: Yup.string().optional(),
  preferredTitle: Yup.string().optional(),
  age: Yup.number().optional().positive().integer(),
  sex: Yup.string().optional(),
  gender: Yup.string().optional(),
  occupation: Yup.string().optional(),
  educationLevel: Yup.string().optional(),
  address: Yup.string().optional(),
  city: Yup.string().optional(),
  state: Yup.string().optional(),
  country: Yup.string().optional(),
  zipCode: Yup.string().optional(),
  relationshipStatus: Yup.string().optional()
});

export const initialValues: ClientInformationFormValues = {
  firstName: "",
  lastName: "",
  nickname: "",
  age: "",
  sex: "",
  gender: "",
  pronouns: "",
  preferredTitle: "",
  occupation: "",
  educationLevel: "",
  address: "",
  addressAdditional: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  children: false,
  childrenText: "",
  childSupport: false,
  childSupportText: "",
  relationshipStatusOption: "",
  relationshipStatus: "",
  additionalInformation: "",
  timeZone: ""
};

export const demographicsValidationSchema = Yup.object().shape({
  ethnicity: Yup.string().optional(),
  economicStatus: Yup.string().optional(),
  occupation: Yup.string().optional(),
  workplace: Yup.string().optional(),
  anyBankruptcy: Yup.boolean(),
  anyBankruptcyText: Yup.string(),
  estimatedHouseholdIncome: Yup.string().optional(),
  estimatedNetWorth: Yup.string().optional(),
  lengthOfResidence: Yup.string().optional(),
  homeOwnershipStatus: Yup.string().optional()
});

export const initialDemographicsValues = {
  ethnicity: "",
  economicStatus: "",
  occupation: "",
  workplace: "",
  anyBankruptcy: false,
  anyBankruptcyText: "",
  estimatedHouseholdIncome: "",
  estimatedNetWorth: "",
  lengthOfResidence: "",
  homeOwnershipStatus: ""
};

export const legalAndOtherRecordsValidationSchema = Yup.object().shape({
  legal_other_records: Yup.string().optional(),
  licenses_or_credentials: Yup.string().optional(),
  academic_records: Yup.string().optional(),
  criminal_records: Yup.string().optional(),
  testimony_transcripts: Yup.string().optional(),
  prior_verdicts_or_settlements: Yup.string().optional(),
  witness_protection_program: Yup.string().optional(),
  no_contact_or_restraining_order: Yup.string().optional(),
  additional_information: Yup.string().notRequired(),
  prior_statements_or_reports: Yup.string().optional(),
  // Switch checks are not required, but we still include them for completeness
  prior_verdicts_or_settlements_check: Yup.boolean(),
  witness_protection_program_check: Yup.boolean(),
  no_contact_or_restraining_order_check: Yup.boolean(),
  prior_statements_or_reports_check: Yup.boolean()
});

export const initialLegalAndOtherRecordsValues: LegalAndOtherRecordsValues = {
  legal_other_records: "",
  licenses_or_credentials: "",
  academic_records: "",
  criminal_records: "",
  testimony_transcripts: "",
  prior_verdicts_or_settlements: "",
  witness_protection_program: "",
  no_contact_or_restraining_order: "",
  additionalInformation: "",
  prior_statements_or_reports: "",
  // Switch checks
  prior_verdicts_or_settlements_check: false,
  witness_protection_program_check: false,
  no_contact_or_restraining_order_check: false,
  prior_statements_or_reports_check: false
};

export const socialMediaValidationSchema = Yup.object().shape({
  twitter: Yup.string().url("Invalid URL"),
  facebook: Yup.string().url("Invalid URL"),
  reddit: Yup.string().url("Invalid URL"),
  tiktok: Yup.string().url("Invalid URL"),
  website_blog_urls: Yup.string().url("Invalid URL").optional(),
  linkedin: Yup.string().url("Invalid URL"),
  instagram: Yup.string().url("Invalid URL"),
  youtube: Yup.string().url("Invalid URL")
});

export const initialSocialMediaValues = {
  twitter: "",
  facebook: "",
  reddit: "",
  tiktok: "",
  website_blog_urls: "",
  linkedin: "",
  instagram: "",
  youtube: ""
};

export const getFormikInitialValues = (fields: InputField[]) => {
  const initialValues: { [key: string]: unknown } = {};
  fields.forEach((field: InputField) => {
    switch (field.type) {
      case "text":
        initialValues[field.name] = field.value || "";
        break;
      case "string":
        initialValues[field.name] = field.value || "";
        break;
      case "textarea":
        initialValues[field.name] = field.value || "";
        break;
      case "number":
        initialValues[field.name] = field.value || 0;
        break;
      case "date":
        initialValues[field.name] = field.value || null;
        break;
      case "boolean":
        initialValues[field.name] = field.value || false;
        break;
      case "array":
        initialValues[field.name] = field.value || [null];
        break;
      default:
        initialValues[field.name] = field.value || null;
    }
  });
  return initialValues;
};

export const initializeTabs = (
  data: SelectedParticipantProps,
  setTabs: React.Dispatch<React.SetStateAction<ConfigureTabProps[]>>,
  setExcludedFields: React.Dispatch<React.SetStateAction<{ field: InputField; section: string }[]>>,
  setActiveTab: React.Dispatch<React.SetStateAction<ConfigureTabProps | undefined>>
): void => {
  const details = data.participantDetails;
  const list: ConfigureTabProps[] = [];
  const excludedFields = ["_id", "participantId", "__v", "createdAt", "updatedAt", "profileImage"];
  const excludedContent: { field: InputField; section: string }[] = [];

  details.forEach((section: ParticipantDetail) => {
    const sectionKeys = Object.keys(section).filter((key) => !excludedFields.includes(key));

    sectionKeys.forEach((sectionKey) => {
      if (Array.isArray(section[sectionKey]) && section[sectionKey].length > 0) {
        const content: InputField[] = (section[sectionKey] as InputField[])
          .map((field) => {
            // Check if the field is in the excluded list
            if (excludedFields.includes(field?.field || "")) {
              excludedContent.push({ field, section: sectionKey }); // Store excluded field with section
              return null; // Exclude it from the main content
            }

            const commonFields: InputField = {
              ...field,
              id: field.id || "",
              label: field.label,
              isMandatory: false,
              name: field.field || field.name || "",
              placeholder: "",
              isFixed: true,
              field: field?.field || field.name,
              originalValue: field.value
            };
            const isStringArray = (value: unknown): value is string[] =>
              Array.isArray(value) && value.every((item) => typeof item === "string");

            const isNameObjectArray = (
              value: unknown
            ): value is {
              firstName?: string;
              middleName?: string;
              lastName?: string;
            }[] =>
              Array.isArray(value) &&
              value.every(
                (item) =>
                  typeof item === "object" &&
                  item !== null &&
                  ("firstName" in item || "middleName" in item || "lastName" in item)
              );

            // Format the value based on the field type
            const value =
              field.field === "alternativeNames" && Array.isArray(field.value)
                ? isNameObjectArray(field.value)
                  ? field.value.map((item: { firstName?: string; middleName?: string; lastName?: string }) =>
                      `${item.firstName || ""} ${item.middleName || ""} ${item.lastName || ""}`.trim()
                    )
                  : isStringArray(field.value)
                    ? field.value
                    : ""
                : field.field === "skills" && Array.isArray(field.value)
                  ? isStringArray(field.value)
                    ? field.value
                    : Array.isArray(field.value[0]) // Check if nested arrays
                      ? (field.value as string[][]).map((subArray) => subArray.join(", "))
                      : ""
                  : field.value;
            // Set specific field attributes based on type
            return {
              ...commonFields,
              type: field.field === "alternativeNames" || field.field === "skills" ? "textArea" : field.type,
              value: field.field === "alternativeNames" || field.field === "skills" ? value : field.value,
              rows: field.field === "alternativeNames" || field.field === "skills" ? 10 : undefined,
              disabled: field.field === "alternativeNames" || field.field === "skills"
            };
          })
          .filter(Boolean) as InputField[]; // Filter out nulls from excluded fields

        list.push({
          id: Math.floor(Math.random() * 1000000) + (typeof section._id === "number" ? section._id : 0),
          label: sectionKey.charAt(0).toUpperCase() + sectionKey.slice(1),
          value: sectionKey,
          content
        });
      }
    });
  });

  setTabs(list);
  setExcludedFields(excludedContent); // Set excluded fields with section information
  setActiveTab(list[0]);
};

/**
 * Initializes input fields by populating values from participant details
 * and ensuring phone fields are formatted correctly.
 *
 * @param data - The data containing participant details.
 * @param tabs - The array of tabs containing field configurations.
 * @param setInputFieldsData - The function to set the input fields state.
 */
export const initializeInputFields = (
  data: SelectedParticipantProps,
  tabs: ConfigureTabProps[],
  setInputFieldsData: React.Dispatch<React.SetStateAction<InputField[]>>
): void => {
  const details: ParticipantDetail[] = data.participantDetails;

  // Gather all used field names from the tabs
  const usedFieldNames: string[] = tabs.flatMap((tab) => tab.content.map((field) => field.name));

  // Filter inputFieldsArray to exclude fields already used in the tabs
  const filteredFields: InputField[] = inputFieldsArray.filter((field) => !usedFieldNames.includes(field.name));

  // Map through the filtered fields to populate values and format phone numbers if applicable
  const populatedFields: InputField[] = filteredFields.map((field: InputField) => {
    const matchedField: InputField | undefined = details
      .flatMap((detail: ParticipantDetail) =>
        Object.values(detail)
          .filter((item): item is InputField[] => Array.isArray(item))
          .flat()
      )
      .find((inputField) => inputField.field === field.name);

    // If the field is related to phone, format its value using formatPhoneNumber
    const value = matchedField?.value ?? "";
    const formattedValue = field.name.toLowerCase().includes("phone") ? formatPhoneNumber(String(value)) : value;

    return { ...field, value: formattedValue };
  });

  // Set the input fields state with populated and formatted fields
  setInputFieldsData(populatedFields);
};

export const createParticipantDetailsPayload = (
  selectedParticipant: SelectedParticipantProps,
  userId: string,
  caseId?: string,
  participantId?: string
) => {
  return {
    ...selectedParticipant,
    caseId: caseId || "",
    participantId: participantId || "",
    userId: userId || "",
    eventType: SOCKET_EVENTS.MANAGE_PARTICIPANT_DETAILS,
    type: SOCKET_EVENTS.PARTICIPANT_AND_DETAILS_UPDATED,
    requestId: uuidv4()
  };
};

export const emitUpdateParticipantDetails = (socketEmit: SocketEmitFunction, payload: SelectedParticipantProps) => {
  try {
    if (socketEmit) {
      socketEmit(
        SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
        payload,
        ({ success, message }: { success: boolean; message: string }) => {
          if (success) toast.success(message);
        },
        ({ success, message }: { success: boolean; message: string }) => {
          if (!success) toast.error(message);
        }
      );
    }
  } catch (error) {
    if (error instanceof Error) console.log("emitSaveParticipantDetails error: ", error?.message);
  }
};

export const mergeParticipantValues = (values: FormikValues, participant: SelectedParticipantProps) => {
  return Object.keys(values).reduce(
    (updatedParticipant, key) => {
      if (key in updatedParticipant) {
        updatedParticipant[key] = values[key];
      }
      return updatedParticipant;
    },
    { ...participant }
  );
};

// Utility function to build values from tab content nodes
export const buildValues = (tabs: ConfigureTabProps[]) =>
  tabs
    .flatMap((item) => item.content)
    .reduce(
      (acc, node) => {
        acc[node.name] =
          (node.name === "skills" || node.name === "alternativeNames") && Array.isArray(node.originalValue)
            ? node.originalValue
            : node.value;
        return acc;
      },
      {} as Record<string, unknown>
    );

// Utility function to construct participant details structure
export const buildParticipantDetails = (tabs: ConfigureTabProps[]) =>
  tabs.reduce((acc: Record<string, InputField[]>, item) => {
    acc[item.value] = item.content.map((node) => ({
      ...node,
      value:
        (node.name === "skills" || node.name === "alternativeNames") && Array.isArray(node.originalValue)
          ? node.originalValue
          : node.value
    }));
    return acc;
  }, {});

/**
 * Filters and sorts notes based on participant type and case ID.
 *
 * @param selectedParticipant - The selected participant containing notes.
 * @param selectedCase - The selected case with an ID.
 * @returns Filtered and sorted notes array.
 */
export const getFilteredNotes = (
  selectedParticipant: SelectedParticipantProps,
  selectedCase: SelectedCaseProps
): Note[] => {
  const caseParticipant = selectedParticipant?.caseParticipants?.[0];
  return (
    selectedParticipant?.notes
      ?.filter((note) => {
        if (caseParticipant?.participantType === "candidate") {
          return note.createdAt != null && note.caseId === selectedCase?.id;
        }

        if (caseParticipant?.participantType === "witness" || caseParticipant?.participantType === "expert") {
          return note.createdAt != null;
        }

        return note.createdAt != null; // Default case to handle any other types
      })
      .sort((a, b) => {
        const dateA = new Date(a.createdAt!).getTime();
        const dateB = new Date(b.createdAt!).getTime();
        return dateB - dateA;
      }) || []
  );
};
