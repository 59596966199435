import React from "react";
import { ActionButtonPropTypes } from "../../utils/types";

import "./styles.button.scss";

export const ActionButton = ({
  className = "",
  icon,
  title,
  onClick,
  disabled = false,
  type = "button" // Default to "button" but allow overriding
}: ActionButtonPropTypes) => {
  return (
    <button
      type={type} // Use the passed type prop instead of hardcoding
      className={`button ${className} ${disabled ? "disabled" : ""}`}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      {icon && <span className='button-icon'>{icon}</span>}
      {title && <span className='button-title'>{title}</span>}
    </button>
  );
};

export default ActionButton;
