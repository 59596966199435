import React from "react";
import { SortIconsProps } from "../../utils/types";

import "./sortIcons.style.scss";

function SortIcons({ isFirstIndex, isLastIndex, onMoveUp, onMoveDown }: SortIconsProps) {
  return (
    <div className='d-flex flex-row pl-1 border-left pt-1 pb-1 gap-1'>
      <span
        onClick={() => {
          if (onMoveUp && !isFirstIndex) onMoveUp();
        }}
        className='cursor-pointer'
      >
        <i className={`fa-solid fa-up h-px-17 w-px-17 color-primary-light ${isFirstIndex ? "opacity-50" : ""}`}></i>
      </span>
      <span
        onClick={() => {
          if (onMoveDown && !isLastIndex) onMoveDown();
        }}
        className='cursor-pointer'
      >
        <i className={`fa-solid fa-down h-px-17 w-px-17 color-primary-light ${isLastIndex ? "opacity-50" : ""}`}></i>
      </span>
    </div>
  );
}

export default SortIcons;
