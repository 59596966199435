import { useFormik } from "formik";
import React, { FC, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { validationSchema } from "./helpers";
import { BUTTON_TITLE, DEFAULT_ROLES } from "../../utils/constants";
import { AddUserModalProps } from "../../utils/types";
import ActionButton from "../ActionButton";
import SelectComponent from "../SelectComponent";
import TextInput from "../TextInput";

export const AddUserModal: FC<AddUserModalProps> = ({ isOpen, toggle, onSubmit, fieldLabel }) => {
  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      role: ""
    },
    validationSchema, // Yup validation schema
    onSubmit: (values) => {
      onSubmit(values); // Submit form values
      formik.resetForm(); // Reset the form after submission
    }
  });

  useEffect(() => {
    if (!isOpen) {
      formik.resetForm();
    }
  }, [isOpen, formik]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue("role", e.target.value);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{fieldLabel ? fieldLabel : "Add User"}</ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit}>
          <div className='d-flex flex-row gap-3'>
            <div className='d-flex flex-column w-50'>
              <TextInput
                id='firstName'
                name='firstName'
                value={formik.values.firstName}
                placeholder='First Name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className='text-danger small'>{formik.errors.firstName}</div>
              ) : null}
            </div>

            <div className='d-flex flex-column w-50'>
              <TextInput
                id='lastName'
                name='lastName'
                value={formik.values.lastName}
                placeholder='Last Name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className='text-danger small'>{formik.errors.lastName}</div>
              ) : null}
            </div>
          </div>
          <div className='d-flex flex-row gap-3 my-2'>
            <div className='d-flex flex-column w-50'>
              <TextInput
                id='email'
                name='email'
                value={formik.values.email}
                placeholder='Email'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className='text-danger small'>{formik.errors.email}</div>
              ) : null}
            </div>

            <div className='d-flex flex-column w-50'>
              <SelectComponent
                hideLabel
                label='User Role'
                items={DEFAULT_ROLES}
                value={formik.values.role}
                onChange={handleChange}
                errorMessage={formik.touched.role && formik.errors.role ? formik.errors.role : ""}
                layout='row'
                placeholder='Select User Role'
              />
            </div>
          </div>
          <ModalFooter>
            <ActionButton
              title={BUTTON_TITLE.ADD}
              className='save-button'
              disabled={!formik.isValid || !formik.dirty || !formik.values.role}
              onClick={formik.submitForm}
            />

            {/* Cancel Button */}
            <ActionButton title={BUTTON_TITLE.CANCEL} onClick={toggle} className='cancel-button' />
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
};
