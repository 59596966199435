import { inputFieldsArray } from "../../../../utils/constants/formFieldsJson";
import { formatPhoneNumber } from "../../../../utils/helpers";
import { ConfigureTabProps, InputField, ParticipantDetail, SelectedParticipantProps } from "../../../../utils/types";

export const initializeTabs = (
  data: SelectedParticipantProps,
  setTabs: React.Dispatch<React.SetStateAction<ConfigureTabProps[]>>,
  setActiveTab: React.Dispatch<React.SetStateAction<ConfigureTabProps | undefined>>
): void => {
  const details: ParticipantDetail[] = data.participantDetails;

  const list: ConfigureTabProps[] = [];

  details.forEach((section: ParticipantDetail) => {
    const sectionKeys: string[] = Object.keys(section).filter(
      (key) => !["_id", "participantId", "__v", "createdAt", "updatedAt"].includes(key)
    );

    sectionKeys.forEach((sectionKey: string) => {
      if (Array.isArray(section[sectionKey]) && section[sectionKey].length > 0) {
        const content: InputField[] = (section[sectionKey] as InputField[]).map((field: InputField) => ({
          ...field,
          id: field.id || "", // Handle undefined by defaulting to an empty string
          label: field.label,
          isMandatory: false,
          name: field.field || field.name || "", // Default to empty string if field name is undefined
          placeholder: "",
          type: field.type,
          value: field.value,
          isFixed: true,
          field: field?.field || field.name
        }));

        list.push({
          id: `${Math.random()}_${parseInt(section._id ?? "0", 10) || 0}`, // Convert section._id to number or fallback to 0
          label: sectionKey.charAt(0).toUpperCase() + sectionKey.slice(1),
          value: sectionKey,
          content
        } as unknown as ConfigureTabProps);
      }
    });
  });

  setTabs(list);
  setActiveTab(list[0]); // Set the first tab as active by default
};

/**
 * Initializes input fields by populating values from participant details
 * and ensuring phone fields are formatted correctly.
 *
 * @param data - The data containing participant details.
 * @param tabs - The array of tabs containing field configurations.
 * @param setInputFieldsData - The function to set the input fields state.
 */
export const initializeInputFields = (
  data: SelectedParticipantProps,
  tabs: ConfigureTabProps[],
  setInputFieldsData?: React.Dispatch<React.SetStateAction<InputField[]>>
): void => {
  console.log("initializeInputFields -> data", data, tabs);
  const details: ParticipantDetail[] = data.participantDetails;

  // Gather all used field names from the tabs
  const usedFieldNames: string[] = tabs.flatMap((tab) => tab.content.map((field) => field.name));

  // Filter inputFieldsArray to exclude fields already used in the tabs
  const filteredFields: InputField[] = inputFieldsArray.filter((field) => !usedFieldNames.includes(field.name));

  // Map through the filtered fields to populate values and format phone numbers if applicable
  const populatedFields: InputField[] = filteredFields.map((field: InputField) => {
    const matchedField: InputField | undefined = details
      .flatMap((detail: ParticipantDetail) =>
        Object.values(detail)
          .filter((item): item is InputField[] => Array.isArray(item))
          .flat()
      )
      .find((inputField) => inputField.field === field.name);

    // If the field is related to phone, format its value using formatPhoneNumber
    const value = matchedField?.value ?? "";
    const formattedValue = field.name.toLowerCase().includes("phone") ? formatPhoneNumber(String(value)) : value;

    return { ...field, value: formattedValue };
  });

  // Set the input fields state with populated and formatted fields
  if (setInputFieldsData) {
    setInputFieldsData(populatedFields);
  }
};
