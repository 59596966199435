import React from "react";
import { IToggleButton } from "../../utils/types";

import "./toggleButton.scss";

const ToggleButton = ({ isChecked, handleChange, label }: IToggleButton) => {
  return (
    <div className='switch-container'>
      <span className='label'>{label}</span>
      <label className='switch'>
        <input type='checkbox' defaultChecked={isChecked} onChange={(e) => handleChange(e.target.checked)} />
        <span className='slider round'></span>
      </label>
    </div>
  );
};

export default ToggleButton;
