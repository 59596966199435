import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";
import { BarChartProps } from "../../../../utils/types";

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const BarChart: React.FC<BarChartProps> = ({ data, horizontal = false }) => {
  const chartData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => item.value),
        backgroundColor: data.map((item) => item.color),
        borderWidth: 1,
        borderRadius: 5,
        barThickness: horizontal ? 20 : 25,
        categoryPercentage: 0.6,
        barPercentage: 0.8
      }
    ]
  };

  const chartOptions: ChartOptions<"bar"> = {
    indexAxis: horizontal ? "y" : "x", // 'y' for horizontal bars, 'x' for vertical bars
    plugins: {
      legend: {
        display: false // Hide legend if not needed
      },
      tooltip: {
        callbacks: {
          //   label: function (context: { dataset: { label: unknown }; raw: unknown }) {
          //     return `${context.dataset.label || ""}: ${context.raw}`;
          //   }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove background grid lines for x-axis
          drawTicks: false
        },
        border: {
          display: false
        },

        ticks: horizontal ? { display: false } : { display: true, callback: (value: unknown) => `${value}` } // Show labels on top of bars for vertical
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false
        },
        border: {
          display: false
        },
        ticks: horizontal
          ? { display: true, callback: (value: unknown) => `${value}` } // Show labels on the left for horizontal
          : { display: false }
      }
    },
    responsive: true,
    maintainAspectRatio: false
  };

  return (
    <div className='bar-Con'>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default BarChart;
