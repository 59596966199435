import { PSLInstanceClass } from "../pslInstance";

const PSL = PSLInstanceClass.getPSLInstance();
const env = PSL.GetEnvironment() || "localhost";

const url = env.includes("staging") ? "https://stg-server.anerva.net" : "http://localhost:5000";
export const URLS = {
  BASE_URL: `${url}`,
  IMAGE_URL: `${url}/api/images/`
};
