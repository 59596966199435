import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";

import "./styles.scss";

interface ThumbsToggleProps {
  onLikeClick?: () => void;
  onDisLikeClick?: () => void;
  fieldReactionValue: string | null;
}

const ThumbsToggle: React.FC<ThumbsToggleProps> = ({
  onLikeClick = () => {},
  onDisLikeClick = () => {},
  fieldReactionValue = null
}) => {
  // State to track the current reaction: 'like', 'dislike', or null
  const [reaction, setReaction] = useState<"like" | "dislike" | null>(null);

  // Set initial reaction state based on the `fieldReactionValue` prop
  useEffect(() => {
    if (fieldReactionValue === "like") {
      setReaction("like");
    } else if (fieldReactionValue === "dislike") {
      setReaction("dislike");
    } else {
      setReaction(null);
    }
  }, [fieldReactionValue]);

  // Unified function to handle reactions
  const handleReactionClick = useCallback(
    (type: "like" | "dislike") => {
      if (reaction === type) {
        return; // If already the same reaction, do nothing
      }
      setReaction(type);

      // Call the appropriate callback
      if (type === "like") {
        onLikeClick();
      } else {
        onDisLikeClick();
      }
    },
    [reaction, onLikeClick, onDisLikeClick]
  );

  return (
    <div className='thumbs-toggle-container'>
      <div
        className={`like-button ${reaction === "like" ? "like-active" : ""}`}
        onClick={() => handleReactionClick("like")}
        data-tooltip-id='tooltip-likes'
      >
        <FontAwesomeIcon icon={faThumbsUp} className={`like-icon ${reaction === "like" ? "liked" : "unliked"}`} />
      </div>

      <div
        className={`dislike-button ${reaction === "dislike" ? "dislike-active" : ""}`}
        onClick={() => handleReactionClick("dislike")}
        data-tooltip-id='tooltip-dislikes'
      >
        <FontAwesomeIcon
          icon={faThumbsDown}
          className={`dislike-icon ${reaction === "dislike" ? "disliked" : "undisliked"}`}
        />
      </div>
    </div>
  );
};

export default ThumbsToggle;
