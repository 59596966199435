import React from "react";
import "./styles.AddNewField.scss";

type AddNewFieldProps = {
  // Add props here
  onAddNewField: () => void;
};

const AddNewField: React.FC<AddNewFieldProps> = ({ onAddNewField }) => {
  return (
    <div className='custom-add-new-field' onClick={onAddNewField}>
      <i className='fa-regular fa-plus field-icon-style'></i>
      <p className='field-label'>Add New Field</p>
    </div>
  );
};

export default AddNewField;
