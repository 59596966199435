import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { BasicInfoField, EducationData, FieldProps, ParticipantBasicInfoProps } from "../../../../utils/types";

import "./style.Education.scss";

const Field: React.FC<FieldProps> = ({ label, value }) => (
  <div className='education-field'>
    <strong>{label}:</strong>
    <p>
      {Array.isArray(value) ? (value && value.length > 0 ? value.join(", ") : "N/A") : value ? String(value) : "N/A"}
    </p>
  </div>
);

const SocialLinks: React.FC<{ links: BasicInfoField[] | undefined }> = ({ links }) => (
  <div className='social-links'>
    {links?.map((link, index) => (
      <a key={index} href={`https://${String(link.value)}`} target='_blank' rel='noopener noreferrer'>
        {link.label}
      </a>
    ))}
  </div>
);

const Education: React.FC<ParticipantBasicInfoProps> = () => {
  const { selectedParticipant } = useSelector((state: RootState) => state.participants);
  const educationData: EducationData[] = selectedParticipant?.participantDetails?.[0]?.education || [];

  return (
    <section className='education-section'>
      {educationData.map((education, index) => {
        return education?.isCustom ? (
          <Field label={education?.label as string} value={education?.value as string} />
        ) : (
          <div key={index} className='education-card'>
            <div className='education-grid'>
              <Field label={education.degree?.label || "Degree"} value={education.degree?.value} />
              <Field label='School Name' value={education.school?.name?.value} />
              <Field label='Location' value={education.location?.value} />
              <Field label='School Type' value={education.type?.value} />
              <Field label='Start Date' value={education.startDate?.value} />
              <Field label='End Date' value={education.endDate?.value} />
              {education.gpa?.value && <Field label='GPA' value={education.gpa?.value} />}
            </div>
            <SocialLinks links={education.school?.socialLinks} />
          </div>
        );
      })}
    </section>
  );
};

export default Education;
