import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleNext, handleSubmit, validationSchema } from "./helpers";
import { AnervaLogoWithName } from "../../../assets/images";
import { ActionButton, LabeledInput } from "../../../components";
import { STRINGS } from "../../../utils/constants";

import "./styles.login.scss";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<"email" | "password">("email");
  const dispatch = useDispatch();

  return (
    <div className='login-page'>
      <div className='login-content-container'>
        <div className='company-logo'>
          <img src={AnervaLogoWithName} alt='Anerva Logo' className='company-logo-img' />
        </div>

        <div className='login-form'>
          <p className='login-form-title'>{STRINGS.LOGIN_TITLE}</p>
          <p className='login-form-subtitle'>{STRINGS.LOGIN_SUBTITLE}</p>

          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={step === "email" ? validationSchema.email : validationSchema.password}
            onSubmit={
              step === "email"
                ? (values) => handleNext(values, setStep)
                : (values) => handleSubmit(values, navigate, dispatch)
            }
          >
            {({ handleChange, values, errors, touched }) => (
              <Form>
                {step === "email" ? (
                  <LabeledInput
                    label={STRINGS.EMAIL_LABEL}
                    placeholder={STRINGS.EMAIL_PLACEHOLDER}
                    value={values.email}
                    onChange={handleChange("email")}
                    error={touched.email && errors.email ? errors.email : ""}
                  />
                ) : (
                  <LabeledInput
                    label={STRINGS.PASSWORD_LABEL}
                    placeholder={STRINGS.PASSWORD_PLACEHOLDER}
                    type='password'
                    value={values.password}
                    onChange={handleChange("password")}
                    error={touched.password && errors.password ? errors.password : ""}
                  />
                )}

                <div className='login-form__btn-container'>
                  <ActionButton
                    className='cancel-btn'
                    title={STRINGS.CANCEL_BUTTON_TITLE}
                    onClick={() => setStep("email")}
                  />
                  <ActionButton
                    className='next-btn'
                    title={step === "email" ? STRINGS.NEXT_BUTTON_TITLE : STRINGS.SUBMIT_BUTTON_TITLE}
                    type='submit'
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
