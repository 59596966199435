// Demographic.tsx
import React from "react";
import { DemographicProps } from "../../../../utils/types";

import "./style.Demographic.scss";

const Demographic: React.FC<DemographicProps> = ({ data }) => {
  return (
    <section className='demographic-section'>
      <div className='demographic-grid'>
        {data.map((field) => (
          <div key={field._id} className='demographic-item'>
            <strong>{field.label}:</strong> <span>{String(field.value) || "N/A"}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Demographic;
