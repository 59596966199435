import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../../redux/store";
import { SUPER_ADMIN } from "../../utils/constants";
import routes from "../../utils/constants/routes";

interface ProtectedRouteProps {
  element: React.ReactElement;
  isAdminRoute?: boolean;
}

/**
 * Component to protect routes based on user roles.
 * Redirects non-admin users to the dashboard if they try to access admin routes.
 */
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, isAdminRoute = false }) => {
  const { role } = useSelector((state: RootState) => state.users);
  const isAdmin = role === SUPER_ADMIN;

  // If the user is not logged in, redirect to the login page
  if (!role) {
    return <Navigate to={routes.LOGIN} replace />;
  }

  // If this is an admin route and the user is not an admin, redirect to the dashboard
  if (isAdminRoute && !isAdmin) {
    return <Navigate to={routes.DASHBOARD} replace />;
  }

  // If all conditions pass, render the protected element
  return element;
};

export default ProtectedRoute;
