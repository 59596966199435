// src/components/CustomizableTabs.tsx

import { FormikValues } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { BUTTON_TITLE } from "../../utils/constants";
import { CustomizableTabsProps, Tab } from "../../utils/types";
import ActionButton from "../ActionButton";
import { AddFieldValueModal } from "../AddFieldValueModal";
import CustomizableTabSection from "../CustomizableTabSection";
import FormConfigurator from "../FormConfigurator";
import Notepad from "../Notepad";
import TabButtons from "../TabButtons";
import "./styles.CustomizableTabs.scss";

const CustomizableTabs: React.FC<CustomizableTabsProps> = ({
  tabs,
  activeTab,
  isConfigureMode,
  inputFieldsData,
  onTabChange,
  addNewTab,
  handleFieldChange,
  onMoveDown,
  onMoveUp,
  handleRemoveField,
  moveField,
  initialValues,
  validationSchema,
  onFormSubmit,
  handleSaveNote,
  enableNotepad,
  notesHistory,
  onFormValuesChange,
  setInputFieldsData,
  profileImage,
  handleReactions
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formikValues, setFormikValues] = useState<FormikValues>({});
  const toggleModal = useCallback(() => {
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  }, []);

  const handleAddNewTab = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleNewTabLabel = useCallback(
    (label: string) => {
      addNewTab(label);
      toggleModal();
    },
    [addNewTab, toggleModal]
  );

  useEffect(() => {
    if (Object.keys(formikValues).length === 0) {
      let initialFormValues = {};
      inputFieldsData.map((item) => {
        initialFormValues = Object.assign(initialFormValues, { [item.name]: item.value });
      });
      setFormikValues(initialFormValues);
    }

    return () => {};
  }, [formikValues, inputFieldsData]);

  return (
    <div>
      <div className='customizeTab-d-flex flex-row align-items-center justify-content-between'>
        <TabButtons tabs={tabs as Tab[]} activeTab={activeTab as Tab} onTabChange={onTabChange} />
        {isConfigureMode && <ActionButton onClick={handleAddNewTab} title='+ Add Section' />}
      </div>
      <div className='customizeTab-d-flex flex-row py-3'>
        <div className={enableNotepad ? "col-8" : "col-12"}>
          {tabs.map(
            (tab) =>
              activeTab?.value === tab.value && (
                <CustomizableTabSection
                  key={tab.label}
                  isConfigureMode={isConfigureMode}
                  handleFieldChange={handleFieldChange}
                  tab={tab}
                  onMoveDown={onMoveDown}
                  onMoveUp={onMoveUp}
                  moveField={moveField}
                  handleRemoveField={handleRemoveField}
                  initialValues={initialValues}
                  onFormValuesChange={onFormValuesChange}
                  validationSchema={validationSchema}
                  formConfigurator={
                    isConfigureMode && (
                      <FormConfigurator
                        moveField={moveField}
                        inputFields={inputFieldsData}
                        setInputFields={setInputFieldsData} // Pass setInputFieldsData
                      />
                    )
                  }
                  profileImage={profileImage}
                  handleReactions={handleReactions}
                />
              )
          )}
          <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
            <ActionButton
              title={BUTTON_TITLE.SAVE_PAGE_CONFIGURATION}
              className='bg-primary-light'
              onClick={() => {
                onFormSubmit(tabs, formikValues);
              }}
            />
            <ActionButton title={BUTTON_TITLE.CANCEL} className='bg-red' onClick={() => {}} />
          </div>
        </div>

        {!isConfigureMode && enableNotepad && (
          <div className='col-4 ms-2'>
            <Notepad id='1234' title='Notepad' handleSaveNote={handleSaveNote} notesHistory={notesHistory || []} />
          </div>
        )}
      </div>

      <AddFieldValueModal
        fieldLabel='Add Section'
        fieldPlaceholder='Section Name'
        isOpen={isModalOpen}
        toggle={toggleModal}
        onSubmit={handleNewTabLabel}
      />
    </div>
  );
};

export default CustomizableTabs;
