import React, { useCallback, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ActionButton, BreadCrumbs } from "../../components";
import RangeSlider from "../../components/AgGrid/GridRangeSlider";
import JuryProfileGenerationModal from "../../components/JuryProfileGenerationModal";
import NavigationWrapper from "../../components/NavigationWrapper";
import ViewParticipantDetails from "../../components/Pages/ManageCase/ViewParticipantDetails";
import StrikeJurorModal from "../../components/StrikeJurorModal";
import { updateJuror } from "../../redux/slices/jurorSlice";
import { setActiveTab } from "../../redux/slices/manageCaseSlice";
import { RootState } from "../../redux/store";
import { BUTTON_TITLE, CASE_TABS, editTitleMap } from "../../utils/constants";
import routes from "../../utils/constants/routes";
import { SOCKET_EVENTS } from "../../utils/constants/socketEvents";
import { calculateNewRate } from "../../utils/helpers";
import useSocket from "../../utils/hooks/sockets";
import { SelectedReasons } from "../../utils/types";

import "./styles.scss";

const ParticipantDetails: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [openStrikeModal, setOpenStrikeModal] = useState(false);
  const { selectedParticipant } = useSelector((state: RootState) => state.participants);
  const pathSegment = location.pathname.split("/").pop();
  const title = editTitleMap[pathSegment as keyof typeof editTitleMap] || "View Participant:";
  const { socket, socketEmit } = useSocket();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addMemberAsTeam = useCallback(() => {
    const payload = {
      userId:
        typeof selectedParticipant?.userId === "object"
          ? (selectedParticipant?.userId?.id ?? "")
          : (selectedParticipant?.userId ?? ""),
      type: SOCKET_EVENTS.ADD_CASE_TRIAL_PARTICIPANT,
      eventType: SOCKET_EVENTS.MANAGE_CASE_PARTICIPANT,
      caseParticipantId: selectedParticipant?.caseParticipants[0]?.id,
      requestId: uuidv4()
    };
    if (socket) {
      socketEmit(SOCKET_EVENTS.ANERVA_SERVER_REQUEST, payload, (data) => {
        if (data.requestId === payload.requestId) {
          navigate(routes.MANAGE_CASE);
          dispatch(setActiveTab(CASE_TABS.TRIAL_TEAM));
        }
      });
    }
  }, [selectedParticipant, socket, socketEmit, navigate, dispatch]);

  const handleOpenStrikeModal = useCallback(() => {
    setOpenStrikeModal(true);
  }, []);

  const handleStrikeSubmit = useCallback(
    (data: { selectedReasons: SelectedReasons }) => {
      if (selectedParticipant) {
        const currentRate = selectedParticipant?.jurorRate || "0";
        const newRate = calculateNewRate(currentRate, data.selectedReasons);
        const updatedJurors = { ...selectedParticipant, jurorRate: `${newRate}%` };
        dispatch(updateJuror(updatedJurors));
        setOpenStrikeModal(false);
      }
    },
    [dispatch, selectedParticipant]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className='d-flex flex-column'>
        <BreadCrumbs
          breadcrumbItems={[
            {
              text: "Manage Case",
              url: routes.MANAGE_CASE
            },
            { text: "Case Participants", url: location.pathname }
          ]}
        />
        <NavigationWrapper
          title={`${title} ${selectedParticipant?.firstName || ""} ${selectedParticipant?.lastName || ""}`}
          headerChild={
            <div className='header-child-container'>
              <div className='button-rating bg-white'>
                <RangeSlider
                  value={(selectedParticipant?.caseParticipants[0]?.participantRatings[0]?.rating ?? 0) / 0.05}
                  min={0}
                  max={100}
                  onClick={() => handleOpenStrikeModal()}
                />
              </div>
              <ActionButton
                className={"bg-primary-light"}
                onClick={addMemberAsTeam}
                title={BUTTON_TITLE.ADD_AS_A_TEAM}
                icon={<i className='fa-regular fa-user-plus'></i>}
              />
              {/* <ActionButton
                className={"bg-blue-light"}
                onClick={() => {
                  setShowModal(true);
                }}
                title={BUTTON_TITLE.AI_PROFILE_GENERATE}
                icon={<AiSearch className='fill-white h-px-20 w-px-20' />}
              /> */}
            </div>
          }
        >
          <div className='scrollable-container'>
            <ViewParticipantDetails data={{ personData: selectedParticipant }} />
          </div>
          <JuryProfileGenerationModal showModal={showModal} onClose={() => setShowModal(false)} size='md' />
        </NavigationWrapper>
        <StrikeJurorModal
          isOpen={openStrikeModal}
          onClose={() => {
            setOpenStrikeModal(false);
          }}
          onSubmit={handleStrikeSubmit}
          currentParticipant={selectedParticipant}
        />
      </div>
    </DndProvider>
  );
};

export default ParticipantDetails;
