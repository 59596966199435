import { ColDef, ICellRendererParams } from "ag-grid-community";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
// import { deleteJuror } from "../../../../redux/slices/jurorSlice";
import { setSelectedParticipant } from "../../../../redux/slices/participantsSlice";
import { RootState } from "../../../../redux/types";
import { ACTION_COLUMN_STYLE, baseJurorColumns } from "../../../../utils/constants";
import routes from "../../../../utils/constants/routes";
import { SOCKET_EVENTS } from "../../../../utils/constants/socketEvents";
import useSocket from "../../../../utils/hooks/sockets";
import { Juror } from "../../../../utils/types";
import { KickOutParticipantData, PersonInfo } from "../../../../utils/types";
import AgGrid from "../../../AgGrid";
import ActionsRenderer from "../../../AgGrid/ActionGridRenderer";
import ConfirmationModal from "../../../ConfirmationModal";
import PersonInfoModal from "../../../PersonInfoModal";
import WrapperComponent from "../../../WrapperComponent";

const TrialTeamTab: React.FC = () => {
  const { socket, socketEmit, socketOff } = useSocket();
  const fetchedRef = useRef(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<PersonInfo | null>(null);
  const { selectedCase } = useSelector((state: RootState) => state.cases);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedJuror, setSelectedJuror] = useState<Juror | null>(null);
  const { trialTeam } = useSelector((state: RootState) => state.participants);
  const user = useSelector((state: RootState) => state.users.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewPersonInfo = (person: PersonInfo) => {
    setSelectedPerson(person);
    setIsModalOpen(true);
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  // const handleDelete = useCallback(
  //   (data: Juror) => {
  //     dispatch(deleteJuror(data?.email || ""));
  //   },
  //   [dispatch]
  // );

  const fetchTrialTeam = () => {
    if (selectedCase && "id" in selectedCase && selectedCase.id) {
      if (fetchedRef.current) return;
      const payload = {
        type: SOCKET_EVENTS.FETCH_PARTICIPANT_TRIAL,
        eventType: SOCKET_EVENTS.MANAGE_PARTICIPANT,
        userId: user?.id,
        caseId: selectedCase?.id,
        requestId: uuidv4()
      };

      if (socket) {
        socketEmit(SOCKET_EVENTS.ANERVA_SERVER_REQUEST, payload, () => {
          fetchedRef.current = true;
        });
      }
    }
  };

  useEffect(() => {
    fetchTrialTeam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, selectedCase, socketOff]);

  const kickOutParticipant = useCallback(
    (data: KickOutParticipantData) => {
      const caseParticipantId =
        Array.isArray(data.caseParticipants) && data.caseParticipants.length > 0
          ? data.caseParticipants[0].id
          : undefined;
      const payload = {
        caseParticipantId: caseParticipantId,
        userId: user?.id,
        type: SOCKET_EVENTS.KICK_OUT_CASE_TRIAL_PARTICIPANT,
        eventType: SOCKET_EVENTS.MANAGE_CASE_PARTICIPANT,
        requestId: uuidv4()
      };
      console.log("payload", payload);
      if (socketEmit) {
        socketEmit(
          SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
          payload,
          ({ success, message }) => {
            if (success) {
              toast.success(message);
            }
          },
          ({ message }) => {
            toast.error(message);
          }
        );
      }
    },
    [socketEmit, user?.id]
  );

  const JurorColumns: ColDef[] = [
    ...baseJurorColumns,

    {
      ...ACTION_COLUMN_STYLE,
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          editable={true}
          kickOut={true}
          deletable={false}
          data={params.data}
          onKickOut={() => {
            setSelectedJuror(params.data);
            setIsConfirmationModalOpen(true);
          }}
          onEdit={() => {
            dispatch(setSelectedParticipant(params.data));
            navigate(routes.CUSTOMIZABLE_EDIT_PARTICIPANTS, { state: { participantData: params.data } });
          }}
          onView={() => {
            dispatch(setSelectedParticipant(params.data));
            navigate(routes.PARTICIPANT_DETAILS, { state: { participantData: params.data } });
            handleViewPersonInfo(params.data);
          }}
        />
      )
    }
  ];

  const onConfirmKickOut = () => {
    if (selectedJuror) {
      kickOutParticipant(selectedJuror);
      setIsConfirmationModalOpen(false);
    }
  };

  return (
    <WrapperComponent title='Trial Team' customClass='mb-2'>
      <div style={{ height: "80vh" }}>
        <AgGrid
          columnDefs={JurorColumns}
          rowData={trialTeam}
          suppressContextMenu={true}
          getContextMenuItems={() => {
            return [];
          }}
          tooltipShowDelay={0}
          enableBrowserTooltips={true}
          pagination={false}
        />
      </div>

      {isModalOpen && <PersonInfoModal isOpen={isModalOpen} toggle={toggleModal} data={selectedPerson} />}

      {isConfirmationModalOpen && (
        <ConfirmationModal
          onCancel={() => setIsConfirmationModalOpen(false)}
          message='Are you sure you want to kick out this juror?'
          onConfirm={onConfirmKickOut}
        />
      )}
    </WrapperComponent>
  );
};

export default TrialTeamTab;
