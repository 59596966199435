import { Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  demographicsValidationSchema,
  initialDemographicsValues,
  initialLegalAndOtherRecordsValues,
  initialSocialMediaValues,
  initialValues,
  legalAndOtherRecordsValidationSchema,
  socialMediaValidationSchema,
  validationSchema
} from "./helper";
import { CASE_TABS, NEW_CLIENT_TABS, TITLES } from "../../../../utils/constants";
import { ConfigureTabProps, EditCandidateProps, Tab } from "../../../../utils/types";
import TabButtons from "../../../TabButtons";
import { WrapperComponent } from "../../../WrapperComponent";
import NewClientDemographics from "../../CreateCase/Participants/CreateNewClient/NewClientDemographics";
import NewClientFormBasic from "../../CreateCase/Participants/CreateNewClient/NewClientFormBasic";
import NewClientFormLegal from "../../CreateCase/Participants/CreateNewClient/NewClientFormLegal";
import NewClientFormSocial from "../../CreateCase/Participants/CreateNewClient/NewClientFormSocial";
import CommunicationPreferences from "../../JurorDetails/PersonalData/CommunicationPreferences";
import FinancialInformation from "../../JurorDetails/PersonalData/FinancialInformation";
import PersonalPreferences from "../../JurorDetails/PersonalData/PersonalPreferences";
import PoliticalAndSocialDetails from "../../JurorDetails/PersonalData/PoliticalAndSocial";

const EditCandidate: React.FC<EditCandidateProps> = ({ photoLabel = "", subheader = "", data }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<Tab>({ id: 1, label: "Basic", value: "basic" });
  const allInitialValues = useMemo(() => {
    switch (activeTab?.value) {
      case CASE_TABS.BASIC:
        return initialValues;
      case CASE_TABS.DEMOGRAPHICS:
        return initialDemographicsValues;
      case CASE_TABS.LEGAL:
        return initialLegalAndOtherRecordsValues;
      case CASE_TABS.SOCIAL_MEDIA:
        return initialSocialMediaValues;
      default:
        return initialValues;
    }
  }, [activeTab]);

  const validationSchemas = useMemo(() => {
    switch (activeTab?.value) {
      case CASE_TABS.BASIC:
        return validationSchema;
      case CASE_TABS.DEMOGRAPHICS:
        return demographicsValidationSchema;
      case CASE_TABS.LEGAL:
        return legalAndOtherRecordsValidationSchema;
      case CASE_TABS.SOCIAL_MEDIA:
        return socialMediaValidationSchema;
      default:
        return validationSchema;
    }
  }, [activeTab]);

  const handleTabChange = () => {
    const index = NEW_CLIENT_TABS.findIndex((t) => t.id === activeTab.id);
    if (index < NEW_CLIENT_TABS.length - 1) {
      setActiveTab(NEW_CLIENT_TABS[index + 1]);
    } else if (index == NEW_CLIENT_TABS.length - 1) {
      navigate(-1);
    }
  };

  const onTabChange = useCallback((tab: ConfigureTabProps) => {
    setActiveTab(tab as Tab);
  }, []);

  const RenderActiveTabContent = () => {
    switch (activeTab?.value) {
      case CASE_TABS.BASIC:
        return (
          <WrapperComponent title={CASE_TABS.BASIC === activeTab?.value ? subheader : TITLES[activeTab.value]}>
            <Formik
              initialValues={allInitialValues}
              validationSchema={validationSchemas}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              <NewClientFormBasic photoLabel={photoLabel} handleTabChange={handleTabChange} data={data.personData} />
            </Formik>
          </WrapperComponent>
        );
      case CASE_TABS.DEMOGRAPHICS:
        return (
          <WrapperComponent title={CASE_TABS.BASIC === activeTab?.value ? subheader : TITLES[activeTab.value]}>
            <Formik
              initialValues={allInitialValues}
              validationSchema={validationSchemas}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              <NewClientDemographics handleTabChange={handleTabChange} />
            </Formik>
          </WrapperComponent>
        );
      case CASE_TABS.LEGAL:
        return (
          <WrapperComponent title={CASE_TABS.BASIC === activeTab?.value ? subheader : TITLES[activeTab.value]}>
            <Formik
              initialValues={allInitialValues}
              validationSchema={validationSchemas}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              <NewClientFormLegal handleTabChange={handleTabChange} />
            </Formik>
          </WrapperComponent>
        );
      case CASE_TABS.SOCIAL_MEDIA:
        return (
          <WrapperComponent title={CASE_TABS.BASIC === activeTab?.value ? subheader : TITLES[activeTab.value]}>
            <Formik
              initialValues={allInitialValues}
              validationSchema={validationSchemas}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              <NewClientFormSocial handleTabChange={handleTabChange} />
            </Formik>
          </WrapperComponent>
        );
      case CASE_TABS.PERSONAL_PREFERENCES:
        return (
          <WrapperComponent title={TITLES[activeTab.value]}>
            <PersonalPreferences handleTabChange={handleTabChange} />
          </WrapperComponent>
        );
      case CASE_TABS.POLITICAL:
        return (
          <WrapperComponent title={TITLES[activeTab.value]}>
            <PoliticalAndSocialDetails handleTabChange={handleTabChange} />
          </WrapperComponent>
        );
      case CASE_TABS.FINANCIAL:
        return (
          <WrapperComponent title={TITLES[activeTab.value]}>
            <FinancialInformation handleTabChange={handleTabChange} />
          </WrapperComponent>
        );
      case CASE_TABS.COMMUNICAITON_PREFERENCES:
        return (
          <WrapperComponent title={TITLES[activeTab.value]}>
            <CommunicationPreferences handleTabChange={handleTabChange} />
          </WrapperComponent>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <TabButtons tabs={NEW_CLIENT_TABS} activeTab={activeTab || NEW_CLIENT_TABS[0]} onTabChange={onTabChange} />

      {RenderActiveTabContent()}
    </div>
  );
};

export default EditCandidate;
