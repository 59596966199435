import { Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { STRIKING_REASONS } from "../../utils/constants";
import { SOCKET_EVENTS } from "../../utils/constants/socketEvents";
import {
  FieldReaction,
  Participant,
  ReactionResult,
  SelectedParticipantProps,
  SocketEmitFunction,
  StrikingReason
} from "../../utils/types";

// Helper function to set default values
export const getDefaultValues = (
  currentParticipant: Participant | null,
  setReactions: React.Dispatch<React.SetStateAction<ReactionResult>>
) => {
  if (!currentParticipant) {
    return {
      selectedReasons: STRIKING_REASONS,
      rating: 0,
      otherReason: "",
      isRatingDisabled: false
    };
  }

  const caseParticipant = currentParticipant.caseParticipants?.[0];
  const participantRating = caseParticipant?.participantRatings?.[0];
  const reactions = caseParticipant?.fieldReactions ?? [];
  if (reactions && reactions.length > 0) {
    const result: ReactionResult = {
      likedCount: 0,
      dislikedCount: 0,
      likedLabels: [],
      dislikedLabels: []
    };

    reactions.forEach((reaction: FieldReaction) => {
      const label = reaction.fieldLabel || reaction.fieldName;

      if (reaction.isLiked === true) {
        result.likedCount++;
        result.likedLabels.push(label as string);
      } else if (reaction.isLiked === false) {
        result.dislikedCount++;
        result.dislikedLabels.push(label as string);
      }
    });
    setReactions(result);
  }
  if (participantRating) {
    const strikeRating = Array.isArray(participantRating.strikeRating) ? participantRating.strikeRating : [];
    const rating = participantRating.rating ?? 0;
    const reasons = participantRating.reasons ?? "";
    const anySelected = participantRating.strikeRating?.some((item: StrikingReason) => item.value);
    return {
      selectedReasons: strikeRating,
      rating: anySelected ? 1 : rating,
      otherReason: reasons,
      isRatingDisabled: anySelected
    };
  }

  return {
    selectedReasons: STRIKING_REASONS,
    rating: 0,
    otherReason: "",
    isRatingDisabled: false
  };
};

// Handle checkbox change
export const handleCheckboxChange = (
  type: string,
  value: boolean,
  selectedReasons: StrikingReason[],
  setSelectedReasons: Dispatch<SetStateAction<StrikingReason[]>>,
  setIsRatingDisabled: Dispatch<SetStateAction<boolean>>,
  setRating: Dispatch<SetStateAction<number>>
) => {
  const updatedReasons = selectedReasons.map((item) => (item.type === type ? { ...item, value } : item));

  const anySelected = updatedReasons.some((item) => item.value);
  setIsRatingDisabled(anySelected);

  if (anySelected) setRating(1);

  setSelectedReasons(updatedReasons);
};

// Submit handler
export const handleSubmit = (
  socketEmit: SocketEmitFunction | null,
  currentParticipant: Participant | SelectedParticipantProps | null,
  rating: number,
  selectedReasons: StrikingReason[],
  otherReason: string,
  userId: string,
  onClose: () => void
) => {
  const payload = {
    type: SOCKET_EVENTS.ADD_PARTICIPANT_RATING,
    eventType: SOCKET_EVENTS.MANAGE_PARTICIPANT_RATING,
    requestId: uuidv4(),
    caseParticipantId: currentParticipant?.caseParticipants?.[0]?.id ?? "",
    userId: userId, // Replace with actual user ID
    rating: rating,
    strikeRating: selectedReasons,
    reasons: otherReason
  };

  if (socketEmit) {
    socketEmit(
      SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
      payload,
      (response: { success: boolean; message: string }) => {
        if (response.success) {
          toast.success(response.message);
        }
      },
      (error: { success: boolean; message: string }) => {
        toast.error(error.message);
      }
    );
  }

  onClose();
};

export const getSummary = (label: string = "Fields", labels: string[] = [], maxDisplay: number = 50): string => {
  if (!labels?.length) return `${label}`;

  const displayedLabels = labels.slice(0, maxDisplay);
  const remainingCount = labels.length - displayedLabels.length;

  return remainingCount > 0
    ? `${label}: ${displayedLabels.join(", ")}, and ${remainingCount} more...`
    : `${label}: ${displayedLabels.join(", ")}`;
};
