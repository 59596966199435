import { ArcElement, Chart as ChartJS, ChartOptions, Legend, Tooltip } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { getDonughtLabelStyles } from "../../../../utils/constants";
import { DonutChartProps } from "../../../../utils/types";

import "./styles.DonughtChart.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart: React.FC<DonutChartProps> = ({ data, conClassName }) => {
  const rootStyle = getComputedStyle(document.documentElement);

  const defaultLabelStyles = getDonughtLabelStyles(conClassName);

  const chartData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => item.value),
        backgroundColor: data.map((item) => rootStyle.getPropertyValue(`--${item.color}`).trim()),
        borderWidth: 0
      }
    ]
  };

  const chartOptions: ChartOptions<"doughnut"> = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: defaultLabelStyles
      }
    },
    maintainAspectRatio: false
  };

  return (
    <div className={`"donught-con" ${conClassName}`}>
      <Doughnut data={chartData} options={chartOptions} />
    </div>
  );
};

export default DonutChart;
