import { ConfigureTabProps, FieldReaction, InputField, SelectedCaseProps } from "../../../utils/types";

/**
 * Determines the reaction status (like, dislike, or null) based on participant reactions.
 *
 * @param {Array} participantFieldReaction - The array of reactions.
 * @param {Object} tab - The current tab object with a `value` property.
 * @param {Object} field - The field object with `name` or `field` properties.
 * @returns {string|null} - Returns "like", "dislike", or null.
 */
export const getReactionStatus = (
  participantFieldReaction: FieldReaction[],
  tab: ConfigureTabProps,
  field: InputField,
  selectedCase: SelectedCaseProps
): string | null => {
  const reaction = participantFieldReaction?.find(
    (reaction) =>
      reaction?.caseId === selectedCase?.id &&
      reaction?.tabSection === tab.value &&
      (reaction?.fieldName === field.name || reaction?.fieldName === field.field)
  );

  return reaction?.isLiked === true ? "like" : reaction?.isLiked === false ? "dislike" : null;
};
