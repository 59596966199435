import { SetIsAdmin } from "../types";

export const checkUserRole = (setIsAdmin: SetIsAdmin): void => {
  const userRole: string | null = localStorage.getItem("userRole");
  if (userRole === "admin") {
    setIsAdmin(true);
  } else {
    setIsAdmin(false);
  }
};

export const getToken = (): string | null => {
  return localStorage.getItem("authToken");
};
