import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "./helper";
import { DummyPersonImg } from "../../../../assets/images";
import { RootState } from "../../../../redux/store";
import { URLS } from "../../../../utils/constants/urls";
import { BasicInfoField, ParticipantBasicInfoProps } from "../../../../utils/types";
import "./styles.Basics.scss";

const ParticipantBasicInfo: React.FC<ParticipantBasicInfoProps> = () => {
  const { selectedParticipant } = useSelector((state: RootState) => state?.participants);
  const basicInfo = selectedParticipant?.participantDetails?.[0]?.basic || [];

  const getFieldByType = (fieldType: string) =>
    Array.isArray(basicInfo) ? basicInfo.filter((field: BasicInfoField) => field?.field === fieldType) : [];

  const fullName = getFieldByType("fullName");
  const alternativeNames = getFieldByType("alternativeNames");
  const profileImage =
    typeof getFieldByType("profileImage")?.[0]?.value === "string" ? getFieldByType("profileImage")[0].value : "";

  const getAlternativePhones = () => {
    return Array.isArray(basicInfo)
      ? basicInfo.filter(
          (field: BasicInfoField) => typeof field?.field === "string" && field?.field?.startsWith("alternative_phone")
        )
      : [];
  };

  const getAlternativeEmails = () => {
    return Array.isArray(basicInfo)
      ? basicInfo.filter(
          (field: BasicInfoField) => typeof field?.field === "string" && field?.field?.startsWith("alternative_email")
        )
      : [];
  };

  const getAddressFields = () => {
    return Array.isArray(basicInfo)
      ? basicInfo.filter(
          (field: BasicInfoField) =>
            field?.field === "address" ||
            (typeof field?.field === "string" && field?.field?.startsWith("additional_address"))
        )
      : [];
  };
  const addressFields = getAddressFields();
  const alternativePhones = getAlternativePhones();
  const alternativeEmails = getAlternativeEmails();
  return (
    <section className='participant-basic-info'>
      <div className='profile-section'>
        <img
          src={profileImage && typeof profileImage === "string" ? `${URLS.IMAGE_URL}${profileImage}` : DummyPersonImg}
          alt='Profile'
          className='profile-image'
        />
        <div className='name-info'>
          {Array.isArray(fullName) && fullName.length > 0 ? (
            fullName.map((nameField: BasicInfoField) => (
              <div key={nameField?._id || Math.random()} className='name-item'>
                <strong>{(nameField?.value as string) || "N/A"}</strong>
              </div>
            ))
          ) : (
            <div className='name-item'>
              <strong>
                {`${selectedParticipant?.firstName || ""} ${selectedParticipant?.lastName || ""}` || "N/A"}
              </strong>
            </div>
          )}

          {Array.isArray(alternativeNames) && alternativeNames.length > 0 && (
            <div className='alternative-names'>
              <strong>Alternative Names:</strong>
              {alternativeNames.map((altField: BasicInfoField) =>
                Array.isArray(altField?.value) && altField.value.length > 0 ? (
                  altField.value.map((nameObj: { rawNames?: string[] }) =>
                    Array.isArray(nameObj?.rawNames) && nameObj.rawNames.length > 0 ? (
                      nameObj.rawNames.map((name) => (
                        <span key={name} className='chip'>
                          {name || "N/A"}
                        </span>
                      ))
                    ) : (
                      <span key={Math.random()} className='chip'>
                        N/A
                      </span>
                    )
                  )
                ) : (
                  <span key={Math.random()} className='chip'>
                    N/A
                  </span>
                )
              )}
            </div>
          )}
        </div>
      </div>

      <div className='contact-details'>
        <div className='basic-info-grid'>
          {Array.isArray(basicInfo) && basicInfo.length > 0 ? (
            (() => {
              const excludedFields = [
                "firstName",
                "lastName",
                "phoneNumber",
                "profileImage",
                "address",
                "fullName" // Handle separately for display first
              ];

              // Filter fields to exclude unwanted ones
              const otherFields = basicInfo.filter((field: BasicInfoField) => extractFields(field, excludedFields));
              // Extract fullName for separate rendering
              const fullNameField = basicInfo.find((field: BasicInfoField) => field?.field === "fullName");

              return (
                <>
                  {/* Display full name first */}
                  {fullNameField && (
                    <div key={fullNameField?._id || Math.random()} className='contact-item'>
                      <strong>{fullNameField?.label || "Unknown Field"}:</strong>{" "}
                      <span>{String(fullNameField?.value) || "N/A"}</span>
                    </div>
                  )}

                  {/* Display other fields */}
                  {otherFields.map((field: BasicInfoField) => (
                    <div key={field?._id || Math.random()} className='contact-item'>
                      <strong>{field?.label || "Unknown Field"}:</strong> <span>{String(field?.value) || "N/A"}</span>
                    </div>
                  ))}
                </>
              );
            })()
          ) : (
            <div className='contact-item'>
              <strong>No Information Available</strong>
            </div>
          )}
        </div>

        {/* Alternative Phones Table */}
        <div className='address-table'>
          <table>
            <thead>
              <tr>
                <th>Alternative Phone Numbers</th>
                <th>Phone Type</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(alternativePhones) && alternativePhones.length > 0 ? (
                alternativePhones.map((phoneField: BasicInfoField) => {
                  const phoneType = phoneField?.label?.toLowerCase().includes("landline")
                    ? "Landline"
                    : phoneField?.label?.toLowerCase().includes("mobile")
                      ? "Mobile"
                      : "N/A";

                  return (
                    <tr key={phoneField?._id || Math.random()}>
                      <td>
                        {typeof phoneField?.value === "string" || typeof phoneField?.value === "number"
                          ? phoneField?.value
                          : "N/A"}
                      </td>
                      <td>{phoneType}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={2}>N/A</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Alternative Emails Table */}
        <div className='address-table'>
          <table>
            <thead>
              <tr>
                <th>Alternative Emails</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(alternativeEmails) && alternativeEmails.length > 0 ? (
                alternativeEmails.map((emailField: BasicInfoField) => (
                  <tr key={emailField?._id || Math.random()}>
                    <td>
                      {typeof emailField?.value === "string" || typeof emailField?.value === "number"
                        ? emailField?.value
                        : "N/A"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>N/A</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Address Table */}
        <div className='address-table'>
          <table>
            <thead>
              <tr>
                <th>Addresses</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(addressFields) && addressFields.length > 0 ? (
                addressFields.map((addressField: BasicInfoField) => (
                  <tr key={addressField?._id || Math.random()}>
                    <td>
                      {typeof addressField?.value === "string" || typeof addressField?.value === "number"
                        ? addressField?.value
                        : "N/A"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>N/A</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default ParticipantBasicInfo;
