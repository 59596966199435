import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BUTTON_TITLE, FORM_PLACEHOLDERS } from "../../utils/constants";
import { AddFieldValueModalProps } from "../../utils/types";
import ActionButton from "../ActionButton";
import TextInput from "../TextInput";

export const AddFieldValueModal: React.FC<AddFieldValueModalProps> = ({
  isOpen,
  toggle,
  onSubmit,
  fieldLabel,
  fieldPlaceholder,
  initialValue = "",
  isUpdate = false,
  setIsUpdate
}) => {
  const [name, setName] = useState(initialValue);

  useEffect(() => {
    if (!isUpdate) {
      setName("");
    } else {
      setName(initialValue);
    }
  }, [isUpdate, initialValue]);

  const handleSubmit = useCallback(() => {
    const actionType = isUpdate ? "update" : "add";
    onSubmit(name, actionType);
    setName("");
    if (setIsUpdate) {
      setIsUpdate(false);
    }
  }, [isUpdate, name, onSubmit, setIsUpdate]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{fieldLabel ? fieldLabel : "Add Field"}</ModalHeader>
      <ModalBody>
        <TextInput
          value={name}
          placeholder={fieldPlaceholder ? fieldPlaceholder : FORM_PLACEHOLDERS.FieldPlaceholder}
          onChange={(e) => setName(e.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <ActionButton
          title={isUpdate ? BUTTON_TITLE.SAVE : BUTTON_TITLE.ADD}
          className={"save-button"}
          disabled={!name}
          onClick={handleSubmit}
        />
        <ActionButton title={BUTTON_TITLE.CANCEL} onClick={toggle} className='cancel-button' />
      </ModalFooter>
    </Modal>
  );
};
