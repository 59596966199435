import { ColDef } from "ag-grid-community";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { fetchAllAccountsFromServer } from "./helpers";
import AgGrid from "../../components/AgGrid";
import NavigationWrapper from "../../components/NavigationWrapper";
import WrapperComponent from "../../components/WrapperComponent";
import { RootState } from "../../redux/types";
import { baseAccountsColumns, TITLES } from "../../utils/constants";
import useSocket from "../../utils/hooks/sockets";

const Accounts = () => {
  const accountsList = useSelector((state: RootState) => state.accounts.accountsList);
  const user = useSelector((state: RootState) => state.users.user);

  const [pageSize] = useState<number>(1000);
  const fetchedRef = useRef(false);
  const { socketEmit } = useSocket();

  useEffect(() => {
    fetchAllAccountsFromServer(socketEmit, fetchedRef, user?.id || "", 1, pageSize);
  }, [pageSize, socketEmit, user?.id]);

  // Column definitions for the AgGrid
  const AccountsListColumns: ColDef[] = useMemo(() => [...baseAccountsColumns], []);

  return (
    <div className='d-flex flex-column w-100'>
      <NavigationWrapper title={TITLES.ACCOUNTS} goBack={false}>
        <WrapperComponent>
          <div className='d-flex' style={{ height: "65vh" }}>
            <AgGrid columnDefs={AccountsListColumns} rowData={accountsList} />
          </div>
        </WrapperComponent>
      </NavigationWrapper>
    </div>
  );
};

export default Accounts;
