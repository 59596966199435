import { Formik } from "formik";
import { useDrop } from "react-dnd";
import { CustomizableForm } from "./CustomizableForm";
import { ConfigureTabProps, CustomizableTabSectionProps, InputField } from "../../utils/types";
import WrapperComponent from "../WrapperComponent";

const CustomizableTabSection = ({
  tab,
  handleFieldChange,
  isConfigureMode,
  onMoveDown,
  onMoveUp,
  handleRemoveField,
  formConfigurator,
  moveField,
  initialValues,
  validationSchema,
  onFormValuesChange,
  profileImage,
  handleReactions
}: CustomizableTabSectionProps) => {
  const [, drop] = useDrop({
    accept: "Field",
    drop: (item: InputField) => {
      handleFieldDrop(item);
    },
    canDrop: () => isConfigureMode || true
  });

  const handleFieldDrop = (field: InputField) => {
    if (Array.isArray(tab.content) && tab.content.some((existingField) => existingField.name === field.name)) {
      console.log(
        "Array.isArray(tab.content) && tab.content.some((existingField) => existingField.name === field.name) : ",
        Array.isArray(tab.content) && tab.content.some((existingField) => existingField.name === field.name)
      );
    } else {
      const updatedTab: ConfigureTabProps = {
        ...tab,
        content: Array.isArray(tab.content) ? [...tab.content, field] : [field]
      };
      handleFieldChange(updatedTab);
    }
  };

  return (
    <div className='h-100 w-100 d-flex user-select-none'>
      <WrapperComponent key={tab.value} title={tab.label}>
        <div className='w-100 d-flex flex-row'>
          <div ref={drop} className='w-100'>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={() => {}}>
              <CustomizableForm
                isConfigureMode={isConfigureMode}
                tab={tab}
                moveField={moveField}
                handleRemoveField={handleRemoveField}
                onFormValuesChange={onFormValuesChange}
                onMoveDown={onMoveDown}
                onMoveUp={onMoveUp}
                profileImage={profileImage}
                handleReactions={handleReactions}
              />
            </Formik>
          </div>
          {formConfigurator}
        </div>
      </WrapperComponent>
    </div>
  );
};

export default CustomizableTabSection;
