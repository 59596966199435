import { FormikValues } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getFormikInitialValues, transformCompanyUserData, handleSaveNote, handleUpdateCompanyUser } from "./helpers";
import CustomizableTabs from "../../../../components/CustomizableTabs";
import ToggleButton from "../../../../components/ToggleButton/toggleButton";
import { RootState } from "../../../../redux/store";
import { FORM_LABELS, SORT_DIRECTIONS } from "../../../../utils/constants";
import {
  addFieldToInputFieldsData,
  createNewTab,
  dragFieldInTab,
  filterInputFieldsData,
  getFormikValidationSchema,
  moveFieldInTab,
  removeFieldFromTab,
  updateTabContent,
  updateTabsWithNewTab
} from "../../../../utils/helpers/customizableTabHelper";
import useSocket from "../../../../utils/hooks/sockets";
import { CompanyUser, ConfigureTabProps, EditCandidateProps, InputField, Note } from "../../../../utils/types";

const CustomizableEditCompanyUser: React.FC<EditCandidateProps> = () => {
  const { socketEmit } = useSocket();
  const [tabs, setTabs] = useState<ConfigureTabProps[]>([]);
  const [inputFieldsData, setInputFieldsData] = useState<InputField[]>([]);
  const [isConfigureMode, setIsConfigureMode] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<ConfigureTabProps | undefined>(undefined);
  const [formikValues, setFormikValues] = useState<FormikValues>({});
  const { selectedCompanyUser } = useSelector((state: RootState) => state.companyUsers);
  const user = useSelector((state: RootState) => state.users.user);

  useEffect(() => {
    if (selectedCompanyUser) {
      const transformedData: InputField[] = transformCompanyUserData(selectedCompanyUser) as InputField[];

      setTabs([{ label: "Basic", value: "basic", content: transformedData }]);
      setInputFieldsData(transformedData);
      setActiveTab({ label: "Basic", value: "basic", content: transformedData });

      const initialValues = getFormikInitialValues(transformedData);
      setFormikValues(initialValues);
    }
  }, [selectedCompanyUser]);

  const addNewTab = useCallback(
    (label: string) => {
      const newTab = createNewTab(tabs, label);
      setTabs((prevTabs) => updateTabsWithNewTab(prevTabs, newTab));
    },
    [tabs]
  );
  const handleFieldChange = useCallback((updatedTab: ConfigureTabProps) => {
    setTabs((prevTabs) => updateTabContent(prevTabs, updatedTab));
    setInputFieldsData((prevInputFieldsData) => filterInputFieldsData(prevInputFieldsData, updatedTab));
  }, []);

  const onMoveDown = useCallback(
    (field: InputField) => {
      setTabs((prevTabs) => moveFieldInTab(prevTabs, activeTab?.value ?? "", SORT_DIRECTIONS.DOWN, field.name));
    },
    [activeTab]
  );

  const onMoveUp = useCallback(
    (field: InputField) => {
      setTabs((prevTabs) => moveFieldInTab(prevTabs, activeTab?.value ?? "", SORT_DIRECTIONS.UP, field.name));
    },
    [activeTab]
  );

  const handleRemoveField = useCallback(
    (field: InputField) => {
      setInputFieldsData((prevInputFieldsData) => addFieldToInputFieldsData(prevInputFieldsData, field));
      setTabs((prevTabs) => removeFieldFromTab(prevTabs, activeTab?.value ?? "", field.name));
    },
    [activeTab]
  );

  const handleTabChange = useCallback((tab: ConfigureTabProps) => {
    setActiveTab(tab);
  }, []);

  const handleConfigureModeChange = useCallback((checked: boolean) => {
    setIsConfigureMode(checked);
  }, []);

  const moveField = useCallback(
    (dragIndex: number, hoverIndex: number, field: InputField) => {
      setTabs((prevTabs) => dragFieldInTab(prevTabs, activeTab?.value ?? "", dragIndex, hoverIndex, field));
    },
    [activeTab]
  );

  const onFormValuesChange = useCallback((newValues: FormikValues) => {
    setFormikValues(newValues); // Update the state

    console.log("Form values changed", newValues);

    setTabs((prevTabs) =>
      prevTabs.map((tab) => ({
        ...tab,
        content: tab.content.map((field) => ({
          ...field,
          value: newValues[field.name] !== undefined ? newValues[field.name] : field.value
        }))
      }))
    );
  }, []);

  const onFormSubmit = useCallback(
    async (tabs: ConfigureTabProps[]) => {
      const updatedTabs = tabs.map((tab) => ({
        ...tab,
        content: tab.content.map((field) => ({
          ...field,
          value: formikValues[field.name] !== undefined ? formikValues[field.name] : field.value
        }))
      }));

      if (selectedCompanyUser) {
        const updatedData = updatedTabs.reduce((acc: Partial<CompanyUser>, tab) => {
          tab.content.forEach((field) => {
            acc[field.name as keyof CompanyUser] = field.value;
          });
          return acc;
        }, {} as Partial<CompanyUser>);

        handleUpdateCompanyUser(
          socketEmit,
          updatedData,
          user?.id ?? "",
          selectedCompanyUser?.companyId ?? "",
          selectedCompanyUser?.id ?? ""
        );
      } else {
        toast.error("Selected company user is not available");
      }
    },
    [formikValues, selectedCompanyUser, socketEmit, user?.id]
  );

  return (
    <div>
      <ToggleButton
        label={FORM_LABELS.CONFIGURE_MODE}
        isChecked={isConfigureMode}
        handleChange={handleConfigureModeChange}
      />
      <CustomizableTabs
        tabs={tabs}
        activeTab={activeTab}
        isConfigureMode={isConfigureMode}
        inputFieldsData={inputFieldsData}
        initialValues={formikValues}
        onTabChange={handleTabChange}
        addNewTab={addNewTab}
        handleFieldChange={handleFieldChange}
        onMoveDown={onMoveDown}
        onMoveUp={onMoveUp}
        handleRemoveField={handleRemoveField}
        validationSchema={getFormikValidationSchema([...tabs.map((item) => item.content)].flat())}
        moveField={moveField}
        onFormSubmit={onFormSubmit}
        onFormValuesChange={onFormValuesChange}
        enableNotepad={true}
        handleSaveNote={(values) => {
          if (selectedCompanyUser) {
            handleSaveNote(socketEmit, values, selectedCompanyUser, user?.id ?? "");
          }
        }}
        notesHistory={
          selectedCompanyUser?.notes
            ?.filter((note): note is Note & { createdAt: Date | string } => note.createdAt != null)
            .sort((a, b) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              return dateB.getTime() - dateA.getTime();
            }) || []
        }
        setInputFieldsData={setInputFieldsData}
      />
    </div>
  );
};

export default CustomizableEditCompanyUser;
