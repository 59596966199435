import React, { ChangeEvent, DragEvent, useCallback, useEffect, useId, useMemo, useState } from "react";
import { FileUploaderProps } from "../../utils/types";

import "./styles.fileuploader.scss";

/**
 * FileUploader Component
 *
 * A component that allows users to upload files via drag-and-drop or file selection.
 *
 * @param {string} label - The label text to display above the uploader. Defaults to "Upload File".
 * @param {(files: File[] | null, file?: File) => void} onFileChange - Callback function triggered when files are selected or removed.
 * @param {boolean} multiple - If true, allows multiple file selection. Defaults to false.
 * @param {File} file - A single file to be displayed initially. Used when `multiple` is false.
 * @param {File[]} files - An array of files to be displayed initially. Used when `multiple` is true.
 * @returns {JSX.Element} The rendered FileUploader component.
 */
const FileUploader: React.FC<FileUploaderProps> = ({
  label = "Upload File",
  onFileChange,
  multiple = false,
  file,
  files = []
}) => {
  // State for tracking drag-over status and selected files
  const [dragOver, setDragOver] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  // Generate a unique ID for input element
  const inputId = useId();

  // Effect to set initial files when props change
  useEffect(() => {
    if (!multiple && file) {
      setSelectedFiles([file]);
    } else if (files && files.length > 0) {
      setSelectedFiles(files);
    }
  }, [file, files, multiple]);

  /**
   * Handles drag over event and sets drag-over state
   */
  const handleDragOver = useCallback((event: DragEvent<HTMLLabelElement>): void => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  /**
   * Handles drag leave event and resets drag-over state
   */
  const handleDragLeave = useCallback((): void => {
    setDragOver(false);
  }, []);

  /**
   * Handles drop event for drag-and-drop file uploads
   */
  const handleDrop = useCallback((event: DragEvent<HTMLLabelElement>): void => {
    event.preventDefault();
    setDragOver(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    handleFiles(droppedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles file selection via file input element
   */
  const handleFileSelect = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    if (event.target.files) {
      const selected = Array.from(event.target.files);
      handleFiles(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the files selected or dropped, updates the state and triggers onFileChange callback.
   */
  const handleFiles = useCallback(
    (files: File[]): void => {
      const newFiles = multiple ? [...selectedFiles, ...files] : [files[0]];
      setSelectedFiles(newFiles);
      onFileChange?.(multiple ? newFiles : newFiles, newFiles[0]);
    },
    [multiple, selectedFiles, onFileChange]
  );

  /**
   * Removes a file from the selected files list and triggers onFileChange callback.
   */
  const removeFile = useCallback(
    (index: number): void => {
      const updatedFiles = selectedFiles.filter((_, i) => i !== index);
      setSelectedFiles(updatedFiles);
      onFileChange?.(updatedFiles.length > 0 ? updatedFiles : null);
    },
    [selectedFiles, onFileChange]
  );

  /**
   * Memoized file preview components to avoid unnecessary re-renders.
   */
  const filePreviews = useMemo(() => {
    return selectedFiles.map((file: File, index: number) => (
      <div key={file.name + index} className='file-item'>
        <span className='file-name'>{file.name}</span>
        <div className='cursor-pointer removes-icon' onClick={() => removeFile(index)}>
          <i className='fa-solid fa-xmark'></i>
        </div>
      </div>
    ));
  }, [selectedFiles, removeFile]);

  return (
    <div className='parent-container'>
      <h6 className='label'>{label}</h6>
      <div className='file-upload'>
        <label
          htmlFor={inputId}
          className={`upload-area ${dragOver ? "drag-over" : ""}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className='upload-content'>
            <i className='fa-regular fa-cloud-arrow-up upload-icon'></i>
            <span className='drag-drop-text'>Drag & Drop File here</span>
            <span className='from-device-text'>
              or <span className='browse'>browse</span> from your device
            </span>
          </div>
        </label>
        <input
          id={inputId}
          type='file'
          className='file-input'
          onChange={handleFileSelect}
          multiple={multiple}
          style={{ display: "none" }}
        />
        <div className='file-preview'>{filePreviews}</div>
      </div>
    </div>
  );
};

export default FileUploader;
