import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Account, AccountState } from "../../utils/types";

const initialState: AccountState = {
  accountsList: [],
  selectedAccounts: null,
  total: 0 as number
};

// Create a slice for companies management.
const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    // Action to get all companies.
    allAccounts: (state, action: PayloadAction<Account[]>) => {
      state.accountsList = action.payload;
    },
    // Action to set the total number of accounts
    setTotalAccounts: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    }
  }
});

// Export the actions for use in components.
export const { allAccounts, setTotalAccounts } = accountsSlice.actions;

// Export the reducer for use in the store.
export default accountsSlice.reducer;
