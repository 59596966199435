import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { CompanyUser, CompanyUserData, CompanyUsersState } from "../../utils/types/index";

// Initial state for company users
const initialState: CompanyUsersState = {
  companyUsersList: [],
  companyUser: null,
  selectedCompanyUser: null,
  total: 0
};

// Create the company users slice
const companyUsersSlice = createSlice({
  name: "companyUsers",
  initialState,
  reducers: {
    // Action to set the list of company users
    allCompanyUsers: (state, action: PayloadAction<CompanyUserData[]>) => {
      if (Array.isArray(action.payload)) {
        state.companyUsersList = action.payload;

        const updatedCompanyUser = action.payload.find((user) => user.id === state.selectedCompanyUser?.id);

        if (updatedCompanyUser) {
          state.selectedCompanyUser = updatedCompanyUser as unknown as CompanyUser;
        }
      } else {
        console.error("allCompanyUsers action payload is not an array:", action.payload);
      }
    },
    // Action to add a new company user
    addCompanyUser: (state, action: PayloadAction<CompanyUserData>) => {
      state.companyUsersList.push(action.payload);
    },
    // Action to delete a company user
    deleteCompanyUser: (state, action: PayloadAction<string>) => {
      state.companyUsersList = state.companyUsersList.filter((user) => String(user.id) !== String(action.payload));
    },
    // Action to set a company user
    setCompanyUser: (state, action: PayloadAction<CompanyUserData | null>) => {
      state.companyUser = action.payload;
    },

    // Action to set a selected company user
    setSelectedCompanyUser: (state, action: PayloadAction<CompanyUser | null>) => {
      state.selectedCompanyUser = action.payload;
    },
    // Action to clear the selected company user
    clearSelectedCompanyUser: (state) => {
      state.selectedCompanyUser = null;
    },
    // Action to set the total count of company users
    setTotalCompanyUsers: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    }
  }
});

// Persist configuration for company users
const companyUsersPersistConfig = {
  key: "companyUsers",
  storage,
  whitelist: ["companyUser", "selectedCompanyUser", "companyUsersList"] // Specify the exact keys to persist
};

// Create a persisted reducer
const persistedCompanyUsersReducer = persistReducer(companyUsersPersistConfig, companyUsersSlice.reducer);

// Export actions
export const {
  allCompanyUsers,
  addCompanyUser,
  deleteCompanyUser,
  setCompanyUser,
  setSelectedCompanyUser,
  clearSelectedCompanyUser,
  setTotalCompanyUsers
} = companyUsersSlice.actions;

// Export the persisted reducer as default
export default persistedCompanyUsersReducer;
