import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { ParticipantBasicInfoProps } from "../../../../utils/types";

import "./style.Skills.scss";

const Skills: React.FC<ParticipantBasicInfoProps> = () => {
  const { selectedParticipant } = useSelector((state: RootState) => state.participants);
  const skillsData = selectedParticipant?.participantDetails?.[0]?.skills[0] || [];
  return (
    <section className='skills-section'>
      <div className='skills-grid'>
        {skillsData?.value?.map((skill: string, index: number) => (
          <div key={index} className='skills-item'>
            <span>{skill}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;
