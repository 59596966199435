import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenu } from "../../../../redux/slices/menuActionsSlice";
import { setUser } from "../../../../redux/slices/userSlice";
import { RootState } from "../../../../redux/store";
import { refreshToken } from "../../../../services/login";
import routes from "../../../../utils/constants/routes";
import { CompanyUserData, User, UserRoleListProps } from "../../../../utils/types";

import "./styles.userRoleCard.scss";

const UserRoleList: React.FC<UserRoleListProps> = ({ companyUsersList }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.users);

  const onClickUserRole = useCallback(
    async (item: CompanyUserData) => {
      const userData: User = {
        ...item.userId,
        roleId: item.userId.role?._id || "",
        _id: item.userId.id || "",
        role: item.userId.role || { _id: "", name: "" },
        createdAt: item.createdAt
          ? typeof item.createdAt === "string"
            ? item.createdAt
            : JSON.stringify(item.createdAt)
          : undefined,
        updatedAt: item.updatedAt
          ? typeof item.updatedAt === "string"
            ? item.updatedAt
            : JSON.stringify(item.updatedAt)
          : undefined
      };
      const newToken = await refreshToken(item?.userId?.id, token || "");
      dispatch(setUser({ user: userData, token: newToken?.token || "" }));
      dispatch(setActiveMenu(routes.DASHBOARD));
      localStorage.setItem("userChanged-event", Date.now().toString());
      window.location.href = routes.DASHBOARD;
    },
    [dispatch, token]
  );
  const RenderUserRoleCard = ({
    email,
    role,
    description,
    fullName,
    onClick
  }: {
    email: string;
    role: string;
    description: string;
    fullName: string;
    onClick: () => void;
  }) => {
    return (
      <div className='switchUserCard'>
        <table className='table switchUser'>
          <tbody>
            <tr>
              <td>
                <b>Role:</b>
              </td>
              <td className='roleSelect' onClick={onClick}>
                {role} ({email})
              </td>
            </tr>
            <tr>
              <td>Description:</td>
              <td>{description}</td>
            </tr>
            <tr>
              <td>User Name:</td>
              <td>{fullName}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  if (!companyUsersList || companyUsersList.length === 0) {
    return <div className='noRecordsMessage'>No user roles records found.</div>;
  }

  return (
    <div className='userRoleContainer'>
      {companyUsersList.map((companyUser: CompanyUserData, index: number) => {
        const { firstName = "N/A", lastName = "N/A", email = "N/A", role } = companyUser.userId || {};
        const fullName = `${firstName} ${lastName}`.trim();
        const userRoleName = role?.name || "N/A";
        const userRoleDescription = role?.description || "No description available";

        return (
          <RenderUserRoleCard
            key={index}
            email={email}
            role={userRoleName}
            description={userRoleDescription}
            fullName={fullName}
            onClick={() => onClickUserRole(companyUser)}
          />
        );
      })}
    </div>
  );
};

export default UserRoleList;
