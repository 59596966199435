import { ColDef, ICellRendererParams, ValueGetterParams } from "ag-grid-community";
import { STRINGS } from "../../../../utils/constants";

export const toggleField = (
  field: string,
  participantType: string,
  activeFields: Record<string, boolean>,
  setActiveFields: (
    fields: Record<string, boolean> | ((prevFields: Record<string, boolean>) => Record<string, boolean>)
  ) => void,
  columnDefs: ColDef[],
  setColumnDefs: (defs: ColDef[]) => void,
  dummyValues: Record<string, unknown>
) => {
  const fieldKey = field === STRINGS.Education ? STRINGS.EducationLevel : field.toLowerCase();

  setActiveFields((prevFields: Record<string, boolean>) => {
    const updatedFields = { ...prevFields, [field]: !prevFields[field] };
    return updatedFields;
  });

  if (!activeFields[field]) {
    const newColumn: ColDef = {
      field: fieldKey,
      headerName: field,
      sortable: true,
      minWidth: 200,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      valueGetter: (params: ValueGetterParams) => {
        const colId = params.column?.getColId();
        if (colId === "age") {
          return (
            params.data?.participantDetails[0]?.demographic?.find((item: ColDef) => item.field === "age")?.value ||
            dummyValues[colId]
          );
        }
        if (colId === "gender") {
          return (
            params.data?.participantDetails[0]?.demographic?.find((item: ColDef) => item.field === "gender")?.value ||
            dummyValues[colId]
          );
        }
        if (colId === "occupation") {
          return (
            params.data?.participantDetails[0]?.demographic?.find((item: ColDef) => item.field === "occupation")
              ?.value || dummyValues[colId]
          );
        }
        if (colId === "educationLevel") {
          return (
            params.data?.participantDetails[0]?.demographic?.find((item: ColDef) => item.field === "educationLevel")
              ?.value || dummyValues[colId]
          );
        }
        return colId ? params.data?.participantDetails[0]?.demographic?.[colId] || dummyValues[colId] : undefined;
      },
      cellRenderer: (params: ICellRendererParams) => <span>{params.value}</span>
    };
    setColumnDefs([...columnDefs, newColumn]);
  } else {
    setColumnDefs(columnDefs.filter((colDef) => colDef.field !== fieldKey));
  }
};
