export default {
  HOME: "/",
  DASHBOARD: "/page/dashboard",
  CALENDARS: "/page/calendars",
  CREATE_CASE: "/page/create_case",
  TRIAL_TEAM: "/page/trial-team",
  CASE_EVIDENCE: "/page/create_case/case_evidence",
  ADD_NEW_CANDIDATE: "/page/cases/add-new-candidate",
  ADD_NEW_WITNESS: "/page/cases/add-new-witness",
  ADD_NEW_EXPERTS: "/page/cases/add-new-experts",
  ADD_NEW_PARTICIPANTS: "/page/cases/add-new-participants",
  JUROR_DETAILS: "/page/juror_details",
  DOCUMENTS: "/page/documents",
  MANAGE_CASE: "/page/cases/manage_case",
  CASES_LIST: "/page/cases",
  EDIT_NEW_CANDIDATE: "/page/edit-new-candidate",
  EDIT_NEW_WITNESS: "/page/edit-new-witness",
  EDIT_NEW_EXPERTS: "/page/edit-new-experts",
  EDIT_NEW_PARTICIPANTS: "/page/edit-new-participants",
  CUSTOMIZABLE_EDIT_PARTICIPANTS: "/page/cases/edit-new-participants-v2",
  ADD_NEW_JUROR: "/page/add-new-juror",
  NOT_FOUND: "*", // Fallback for any unmatched route (404)
  COMPANIES: "/page/companies",
  COMPANY_DETAILS: "/page/company-details",
  PARTICIPANT_DETAILS: "/page/cases/participant-details",
  LOGIN: "/login",
  SWITCH_USER_ROLES: "/page/switch-user-roles",
  ACCOUNTS: "/page/accounts",
  COMPANY_USER_DETAILS: "/page/company-user-details"
};
