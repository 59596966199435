import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveMenu } from "../../redux/slices/menuActionsSlice";
import { RootState } from "../../redux/store";
import { SUPER_ADMIN } from "../constants";
import routes from "../constants/routes";
import { isVerifyToken, logOutUser } from "../helpers/verifyTokenHelper";

/**
 * Custom hook for handling authentication redirection and logout synchronization.
 * This hook verifies the user's token, manages role-based redirection, and ensures synchronized logout across tabs.
 *
 * @returns An object with a boolean indicating if the user is a super admin.
 */
const useAuthRedirect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { role, token } = useSelector((state: RootState) => state.users);
  const { activeMenu } = useSelector((state: RootState) => state.menu);

  /**
   * Handles authentication verification and role-based navigation.
   * - If the user is not authenticated, redirects to the login page.
   * - If the user's role is `SUPER_ADMIN`, redirects to the `COMPANIES` page.
   * - If the user's role is other than `SUPER_ADMIN`, redirects to the `DASHBOARD`.
   * - Restores the last active menu when navigating back to the application from the login page.
   */
  const handleAuth = useCallback(async () => {
    const roles = localStorage.getItem("userRole") || role;
    if (!roles) {
      navigate(routes.LOGIN);
      return;
    }

    const authToken = token || localStorage.getItem("authToken") || "";
    const tokenIsValid = await isVerifyToken(authToken, dispatch, navigate);

    if (!tokenIsValid) return;

    const currentPath = window.location.pathname;

    if (currentPath === routes.HOME) {
      if (roles === SUPER_ADMIN) {
        window.location.href = routes.COMPANIES;
        dispatch(setActiveMenu(routes.COMPANIES));
      } else if (["Support Staff", "Attorney", "Consultant", "Admin"].includes(roles)) {
        window.location.href = routes.DASHBOARD;
        dispatch(setActiveMenu(routes.DASHBOARD));
      }
    } else if (currentPath === routes.LOGIN && authToken) {
      window.location.href = activeMenu || routes.HOME;
    }
  }, [activeMenu, dispatch, navigate, role, token]);

  /**
   * Effect to handle authentication and redirection on component mount or dependency changes.
   * - Runs the authentication verification logic when the `role`, `token`, or other dependencies change.
   */
  useEffect(() => {
    handleAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Effect to synchronize logout across tabs and re-validate authentication when the page is focused.
   * - Listens for `storage` events to detect logout events from other tabs.
   * - Listens for `focus` events to trigger authentication verification.
   */
  useEffect(() => {
    /**
     * Handles logout and user change events from other tabs.
     * - Clears the user's state and navigates to the login page for `logout-event`.
     * - Runs authentication logic for `userChanged`.
     *
     * @param event - The `StorageEvent` fired when `localStorage` changes.
     */
    const handleStorageEvent = async (event: StorageEvent) => {
      if (event.key === "logout-event") {
        logOutUser(dispatch);
        localStorage.removeItem("logout-event");
        window.location.href = routes.LOGIN;
      } else if (event.key === "userChanged-event") {
        localStorage.removeItem("userChanged-event");
        window.location.href = routes.DASHBOARD;
      } else if (event.key === "login-event") {
        localStorage.removeItem("login-event");
        window.location.href = routes.HOME;
      }
    };

    window.addEventListener("storage", handleStorageEvent);

    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, [dispatch, navigate, activeMenu, handleAuth]);

  /**
   * @returns {Object} An object containing:
   * - `isAdmin`: A boolean indicating if the current user is a super admin.
   */
  return { isAdmin: role === SUPER_ADMIN };
};

export default useAuthRedirect;
