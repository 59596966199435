import React, { useCallback, useMemo } from "react";
import { RangeSliderProps } from "../../utils/types";
import "./RangeSlider.scss";

const RangeSlider: React.FC<RangeSliderProps> = ({ value = 0, min, max, trackColor, onClick }) => {
  const getEmoji = useCallback(() => {
    if (value <= 20)
      return (
        <span className='icon-red cursor-pointer' onClick={onClick}>
          <i className='fa-light fa-face-frown'></i>
        </span>
      );

    if (value < 50)
      return (
        <span className='icon-orange cursor-pointer ' onClick={onClick}>
          <i className='fa-light fa-face-meh'></i>
        </span>
      );
    return (
      <span className='icon-green cursor-pointer' onClick={onClick}>
        <i className='fa-light fa-face-smile'></i>
      </span>
    );
  }, [value, onClick]);

  const fetchTrackColor = useMemo(() => {
    if (value <= 20) return `var(--color-btn-danger)`; // Red for low values
    if (value < 50) return `var(--color-orange)`; // Orange for mid values
    return `var(--color-primary-light)`;
  }, [value]);

  const trackStyle = useMemo(
    () => ({
      background: `linear-gradient(90deg, ${trackColor || fetchTrackColor} ${value}%, #c7c9c9 ${value}%)`,
      borderRadius: "10px"
    }),
    [trackColor, fetchTrackColor, value]
  );

  return (
    <div className={"range-slider-container cursor-pointer"} onClick={onClick}>
      <span className='emoji-icon cursor-pointer' onClick={onClick}>
        {getEmoji()}
      </span>
      <input
        className='range-slider-input cursor-pointer'
        onClick={onClick}
        style={trackStyle}
        type='range'
        value={value}
        min={min}
        max={max}
        disabled
      />
    </div>
  );
};

export default RangeSlider;
