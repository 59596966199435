import { RouteObject } from "react-router-dom";
import routes from "./routes";
import ProtectedRoute from "../../components/ProtectedRoute";
import {
  AddNewClient,
  AddNewJuror,
  CaseEvidence,
  CaseList,
  Companies,
  CompanyDetails,
  CreateCase,
  CustomizableEditParticipant,
  Dashboard,
  Documents,
  EditNewClient,
  InteractiveCalendar,
  JurorDetails,
  ManageCase,
  NotFound,
  ParticipantDetails,
  SwitchUserRoles,
  CompanyUserDetails,
  TrialTeam
} from "../../pages";
import Accounts from "../../pages/accounts";

export const routesConfig: RouteObject[] = [
  { path: routes.DASHBOARD, element: <Dashboard /> },
  { path: routes.CALENDARS, element: <InteractiveCalendar /> },
  { path: routes.CREATE_CASE, element: <CreateCase /> },
  { path: routes.TRIAL_TEAM, element: <TrialTeam /> },
  { path: routes.CASE_EVIDENCE, element: <CaseEvidence /> },
  { path: routes.ADD_NEW_CANDIDATE, element: <AddNewClient /> },
  { path: routes.ADD_NEW_WITNESS, element: <AddNewClient /> },
  { path: routes.ADD_NEW_EXPERTS, element: <AddNewClient /> },
  { path: routes.ADD_NEW_PARTICIPANTS, element: <AddNewClient /> },
  { path: routes.JUROR_DETAILS, element: <JurorDetails /> },
  { path: routes.DOCUMENTS, element: <Documents /> },
  { path: routes.MANAGE_CASE, element: <ManageCase /> },
  { path: routes.CASES_LIST, element: <CaseList /> },
  { path: routes.EDIT_NEW_CANDIDATE, element: <EditNewClient /> },
  { path: routes.EDIT_NEW_WITNESS, element: <EditNewClient /> },
  { path: routes.EDIT_NEW_EXPERTS, element: <EditNewClient /> },
  { path: routes.EDIT_NEW_PARTICIPANTS, element: <EditNewClient /> },
  { path: routes.CUSTOMIZABLE_EDIT_PARTICIPANTS, element: <CustomizableEditParticipant /> },
  { path: routes.ADD_NEW_JUROR, element: <AddNewJuror /> },
  { path: routes.PARTICIPANT_DETAILS, element: <ParticipantDetails /> },
  { path: routes.SWITCH_USER_ROLES, element: <SwitchUserRoles /> },
  { path: routes.ACCOUNTS, element: <Accounts /> },
  { path: routes.COMPANY_USER_DETAILS, element: <CompanyUserDetails /> },

  // Admin routes protected by the ProtectedRoute component
  {
    path: routes.COMPANIES,
    element: <ProtectedRoute element={<Companies />} isAdminRoute />
  },
  {
    path: routes.COMPANY_DETAILS,
    element: <ProtectedRoute element={<CompanyDetails />} isAdminRoute />
  },
  {
    path: routes.SWITCH_USER_ROLES,
    element: <ProtectedRoute element={<SwitchUserRoles />} isAdminRoute />
  },
  {
    path: routes.ACCOUNTS,
    element: <ProtectedRoute element={<Accounts />} isAdminRoute />
  },

  // Fallback for any unmatched route (404)
  { path: routes.NOT_FOUND, element: <NotFound /> }
];
