import React from "react";
import {
  BUTTON_TITLE,
  economicStatusOptions,
  ethnicityOptions,
  FIELD_TYPES,
  NEW_CLIENT_TABS
} from "../../../utils/constants";
import { FORM_LABELS, FORM_NAMES, FORM_PLACEHOLDERS } from "../../../utils/constants";
import { DemographicsProps } from "../../../utils/types";
import { ActionButton } from "../../ActionButton";
import SelectComponent from "../../SelectComponent";
import TextInput from "../../TextInput";

const Demographics: React.FC<DemographicsProps> = (props) => {
  const { values, errors, setFieldValue, handleChange, resetForm, handleTabChange } = props;

  return (
    <>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <SelectComponent
          label={FORM_LABELS.ETHNICITY}
          name={FORM_NAMES.ETHNICITY}
          items={ethnicityOptions}
          value={values.ethnicity}
          onChange={(e) => setFieldValue(FORM_NAMES.ETHNICITY, e.target.value)}
          errorMessage={errors.ethnicity}
        />
        <SelectComponent
          label={FORM_LABELS.ECONOMIC_STATUS}
          name={FORM_NAMES.ECONOMIC_STATUS}
          items={economicStatusOptions}
          value={values.economicStatus}
          onChange={(e) => setFieldValue(FORM_NAMES.ECONOMIC_STATUS, e.target.value)}
          errorMessage={errors.economicStatus}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
        <TextInput
          label={FORM_LABELS.OCCUPATION}
          name={FORM_NAMES.OCCUPATION}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          value={values.occupation}
          onChange={handleChange}
          errorMessage={errors.occupation}
        />
        <TextInput
          label={FORM_LABELS.WORKPLACE}
          name={FORM_NAMES.WORKPLACE}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          value={values.workplace}
          onChange={handleChange}
          errorMessage={errors.workplace}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap w-50'>
        <TextInput
          label={FORM_LABELS.ANY_BANKRUPTCY}
          name={FORM_NAMES.ANY_BANKRUPTCY}
          isSwitch
          value={values.anyBankruptcyText}
          checked={values?.anyBankruptcy}
          placeholder={FORM_PLACEHOLDERS.DASHED}
          onChange={handleChange}
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.ANY_BANKRUPTCY, e.target.checked)}
        />
      </div>

      <p className='my-2 color-primary-light font-size-15'>HOUSEHOLD INFORMATION</p>

      <div className='d-flex flex-row gap-4 sm-flex-wrap w-100'>
        <div className='w-50'>
          <TextInput
            label={FORM_LABELS.ESTIMATED_HOUSEHOLD_INCOME}
            name={FORM_NAMES.ESTIMATED_HOUSEHOLD_INCOME}
            value={values.estimatedHouseholdIncome}
            errorMessage={errors.estimatedHouseholdIncome}
            onChange={handleChange}
            parentClassName='w-85'
            type={FIELD_TYPES.CURRENCY}
            currencyText={"per year"}
          />
        </div>
        <div className='w-50'>
          <TextInput
            label={FORM_LABELS.ESTIMATED_NET_WORTH}
            name={FORM_NAMES.ESTIMATED_NET_WORTH}
            value={values.estimatedNetWorth}
            onChange={handleChange}
            errorMessage={errors.estimatedNetWorth}
            parentClassName='w-90'
            type={FIELD_TYPES.CURRENCY}
            currencyText={"M"}
          />
        </div>
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap w-100'>
        <TextInput
          label={FORM_LABELS.LENGTH_OF_RESIDENCE}
          name={FORM_NAMES.LENGTH_OF_RESIDENCE}
          value={values.lengthOfResidence}
          errorMessage={errors.lengthOfResidence}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.HOME_OWNERSHIP_STATUS}
          name={FORM_NAMES.HOME_OWNERSHIP_STATUS}
          value={values.homeOwnershipStatus}
          onChange={handleChange}
          errorMessage={errors.homeOwnershipStatus}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
        <ActionButton
          title={BUTTON_TITLE.SAVE}
          className='save-button'
          onClick={() => {
            handleTabChange?.(NEW_CLIENT_TABS[2]);
            resetForm?.();
          }}
        />
        <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            handleTabChange?.(NEW_CLIENT_TABS[0]);
            resetForm?.();
          }}
          className='cancel-button'
        />
      </div>
    </>
  );
};

export default Demographics;
