import React, { useCallback, useState } from "react";
import PotentialBiases from "./PotentialBiases";
import Strengths from "./Strengths";
import Weaknesses from "./Weakness";
import { jurorProfileTabsData } from "../../../../utils/constants";
import { JurorEvaluationProps, Tab } from "../../../../utils/types";
import TabButtons from "../../../TabButtons";

import "./jurorProfile.scss";

const JurorProfile: React.FC<JurorEvaluationProps> = ({ setActiveCard }) => {
  const [activeTab, setActiveTab] = useState<Tab>(jurorProfileTabsData[0]);

  const handleTabChange = useCallback((tab: Tab) => {
    setActiveTab(tab);
  }, []);

  const renderActiveTab = () => {
    switch (activeTab.value) {
      case jurorProfileTabsData[0].value:
        return <Strengths handleTabChange={handleTabChange} setActiveCard={setActiveCard} />;
      case jurorProfileTabsData[1].value:
        return <Weaknesses handleTabChange={handleTabChange} setActiveCard={setActiveCard} />;
      case jurorProfileTabsData[2].value:
        return <PotentialBiases handleTabChange={handleTabChange} setActiveCard={setActiveCard} />;
      default:
        return null;
    }
  };

  return (
    <div className='juror-profile-container'>
      <TabButtons onTabChange={handleTabChange} tabs={jurorProfileTabsData} activeTab={activeTab} />
      {renderActiveTab()}
    </div>
  );
};

export default JurorProfile;
