import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { SidebarProps } from "./layout_types";
import SidebarMenu from "./SidebarMenu";
import SidebarMenuCustom from "./SidebarMenuCustom";
import SidebarMenuLink from "./SidebarMenuLink";
import { VersionNumber } from "./VersionNumber";
import { setActiveMenu } from "../../redux/slices/menuActionsSlice";
import { RootState } from "../../redux/types";

import "./styles.scss";

const SidebarContent = ({
  menuItems,
  logoImage,
  logoSmImage,
  hasDropdownMenu,
  dropdownChangeHandler,
  dropdownValue,
  dropdownOptions,
  showWorldClock,
  versionNumber,
  env,
  leftSideBarType
}: SidebarProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const activeMenu = useSelector((state: RootState) => state.menu.activeMenu);
  const showTitle = leftSideBarType === "menu-opened";

  useEffect(() => {
    menuItems.forEach((menu) => {
      if (menu.item_type === "Menu" || menu.item_type === "Custom") {
        menu.children?.forEach((item) => {
          if (item.route === location.pathname) {
            dispatch(setActiveMenu(menu?.route || ""));
          }
        });
      }
    });
  }, [location.pathname, menuItems, dispatch]);

  return (
    <React.Fragment>
      <div id='sidebar-menu' style={{ height: showWorldClock ? "calc(100vh - 175px)" : "100vh" }}>
        <div className='navbar-brand-box'>
          <Link to='/' className='logo logo-dark'>
            <span className='logo-sm'>
              <img src={logoSmImage} alt='anerva' height='44' />
            </span>
            <span className='logo-lg'>
              {showTitle ? (
                <img src={logoImage} alt='anerva' height='60' />
              ) : (
                <img className='logo-img-small' src={logoSmImage} alt='anerva' height='44' />
              )}
            </span>
          </Link>
        </div>
        {hasDropdownMenu && dropdownChangeHandler && (
          <select
            onChange={(e) => dropdownChangeHandler(e.target.value)}
            value={dropdownValue ?? undefined}
            className='form-select form-select-dropdown mb-3'
          >
            {dropdownOptions?.map((item, i) => <option key={"dropdown" + i}>{item}</option>)}
          </select>
        )}

        <ul className='metismenu list-unstyled mm-active' id='side-menu'>
          {menuItems.map((item, i) => {
            const itemTitle = showTitle ? item.title_en || "" : "";
            return item.item_type === "Link" ? (
              <SidebarMenuLink key={i} link={item} label={itemTitle} index={`${i}-0`} />
            ) : item.item_type === "Label" ? (
              showTitle && (
                <span key={i} className='module-label'>
                  {item.title_en}
                </span>
              )
            ) : item.item_type === "Custom" ? (
              <SidebarMenuCustom
                key={`${item.title_en}-${i.toString()}`}
                menu={item}
                index={i}
                collapse={item?.route !== activeMenu}
                onCollapse={(menu) => dispatch(setActiveMenu(menu?.route || ""))}
                leftSideBarType={leftSideBarType}
              />
            ) : (
              <SidebarMenu
                key={i}
                menu={item}
                index={i}
                collapse={item?.route !== activeMenu}
                onCollapse={(menu) => dispatch(setActiveMenu(menu?.route || ""))}
              />
            );
          })}
        </ul>

        {showTitle && versionNumber && <VersionNumber versionNumber={versionNumber} env={env} />}
      </div>
    </React.Fragment>
  );
};

export default SidebarContent;
