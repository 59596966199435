import React from "react";
import { NavigationCardsProps, TabPropTypes } from "../../utils/types";

import "./styles.navigationcards.scss";

/**
 * NavigationCards component renders a series of navigation cards that act as tabs.
 * Each card can be clicked to trigger a change in the active tab.
 *
 * @param {NavigationCardsProps} props - The props for the NavigationCards component.
 * @param {TabPropTypes | undefined} props.activeTab - The currently active tab.
 * @param {TabPropTypes[]} props.tabs - An array of tab items to be displayed.
 * @param {(tab: TabPropTypes) => void} [props.onTabChange] - A callback function triggered when a tab is clicked.
 *
 * @returns {JSX.Element} The rendered NavigationCards component.
 */
export default function NavigationCards({ activeTab, tabs, onTabChange }: NavigationCardsProps) {
  return (
    <div className='d-flex flex-row align-items-center gap-2 flex-wrap'>
      {tabs.map((item: TabPropTypes, index: number) => {
        return (
          <div
            onClick={() => {
              if (onTabChange) onTabChange(item);
            }}
            key={index.toString()}
            className={`nav-card ${activeTab?.id == item.id ? "bg-secondary-light" : ""} ${item.className}`}
          >
            <span className='title'>{item.title}</span>
            {item.icon}
          </div>
        );
      })}
    </div>
  );
}
