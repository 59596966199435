import { AxiosError } from "@digitalworkflow/psl_web";
import { Dispatch } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { setActiveMenu } from "../../../redux/slices/menuActionsSlice";
import { setUser } from "../../../redux/slices/userSlice";
import getInstance from "../../../utils/axiosInstance";
import { MESSAGES, SUPER_ADMIN } from "../../../utils/constants";
import { API_END_POINTS } from "../../../utils/constants/apiEndPoints";
import routes from "../../../utils/constants/routes";
import socketService from "../../../utils/services/socketService";

// Validation schema for Formik
export const validationSchema = {
  email: Yup.object({
    email: Yup.string().email(MESSAGES.INVALID_EMAIL_FORMAT).required(MESSAGES.EMAIL_IS_REQUIRED)
  }),
  password: Yup.object({
    password: Yup.string().required(MESSAGES.PASSWORD_REQUIRED)
  })
};

// Handle Next Step (Email Step)
export const handleNext = (values: { email: string }, setStep: (step: "email" | "password") => void) => {
  setStep("password");
};

// Handle Submit (Password Step)
export const handleSubmit = async (
  values: { email: string; password: string },
  navigate: NavigateFunction,
  dispatch: Dispatch
) => {
  console.log("Credentials values:", values);
  try {
    const axiosInstance = await getInstance();
    const response = await axiosInstance.post(API_END_POINTS.LOGIN, {
      email: values.email,
      password: values.password
    });
    console.log("response", response);
    if (response.status === 200) {
      const userResponse = response.data;
      if (userResponse && userResponse.user) {
        toast.success(MESSAGES.LOGIN_SUCCESSFULLY);
        localStorage.removeItem("logout-event");
        if (userResponse.user && userResponse.user?.role?.name) {
          localStorage.setItem("userRole", userResponse.user?.role?.name || "");
        }
        localStorage.setItem("user", JSON.stringify(userResponse?.user || ""));
        localStorage.setItem("authToken", userResponse?.token);
        localStorage.setItem("login-event", Date.now().toString());
        dispatch(setUser({ user: userResponse?.user, token: userResponse?.token }));
        await socketService.reconnect();
        if (userResponse.user?.role?.name === SUPER_ADMIN) {
          dispatch(setActiveMenu(routes.COMPANIES || ""));
          window.location.href = routes.COMPANIES;
        } else {
          dispatch(setActiveMenu(routes.DASHBOARD || ""));
          window.location.href = routes.DASHBOARD;
        }
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      const errorMessage = (axiosError.response.data as { message: string }).message;
      toast.error(errorMessage);
    } else {
      toast.error(MESSAGES.AN_ERROR_OCCURRED);
    }
  }
};
