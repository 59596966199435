import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setActiveMenu } from "../../redux/slices/menuActionsSlice";
import routes from "../../utils/constants/routes";

import "./styles.notFound.scss";

const NotFound = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className='not-found-container'>
      <h1 className='not-found-title'>404</h1>
      <p className='not-found-message'>Oops! The page you&apos;re looking for doesn&apos;t exist.</p>
      <Link
        to={routes.DASHBOARD}
        className='home-link'
        onClick={() => {
          navigate(routes.DASHBOARD);
          dispatch(setActiveMenu(routes.DASHBOARD));
        }}
      >
        Go back to the dashboard
      </Link>
    </div>
  );
};

export default NotFound;
