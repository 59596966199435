import { useFormik } from "formik";
import React, { useCallback } from "react";
import { BUTTON_TITLE, FORM_LABELS, FORM_NAMES, jurorEvaluationTabsData, TITLES } from "../../../../../utils/constants";
import { JurorEvaluationProps, RatingValue } from "../../../../../utils/types";
import ActionButton from "../../../../ActionButton";
import RatingField from "../../../../RatingField";
import WrapperComponent from "../../../../WrapperComponent";
import { emotionalIntelligenceInitialValues, emotionalIntelligenceValidationSchema } from "../helper";

import "../jurorEvaluation.scss";

const EmotionalIntelligence: React.FC<JurorEvaluationProps> = ({ handleTabChange }) => {
  const formik = useFormik({
    initialValues: emotionalIntelligenceInitialValues,
    validationSchema: emotionalIntelligenceValidationSchema,
    onSubmit: (values) => {
      console.log("Form data", values);
    }
  });

  const { values, setFieldValue, resetForm, handleSubmit } = formik;

  const handleRatingChange = useCallback(
    (field: string, value: RatingValue) => {
      setFieldValue(field, value);
    },
    [setFieldValue]
  );

  const handleCommentChange = useCallback(
    (field: string, comment: string) => {
      setFieldValue(field, comment);
    },
    [setFieldValue]
  );

  return (
    <div className='juror-evaluation-container'>
      <WrapperComponent title={TITLES.EMOTIONAL_INTELLIGENCE}>
        <form onSubmit={handleSubmit}>
          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.EMOTIONAL_AWARENESS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.EMOTIONAL_AWARENESS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.EMOTIONAL_AWARENESS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.EMOTIONAL_AWARENESS] as RatingValue}
              value={values[FORM_NAMES.EMOTIONAL_AWARENESS_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.EMOTIONAL_INFLUENCE}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.EMOTIONAL_INFLUENCE, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.EMOTIONAL_INFLUENCE_COMMENT, comment)}
              initialRating={values[FORM_NAMES.EMOTIONAL_INFLUENCE] as RatingValue}
              value={values[FORM_NAMES.EMOTIONAL_INFLUENCE_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.EMOTIONAL_REGULATION}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.EMOTIONAL_REGULATION, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.EMOTIONAL_REGULATION_COMMENT, comment)}
              initialRating={values[FORM_NAMES.EMOTIONAL_REGULATION] as RatingValue}
              value={values[FORM_NAMES.EMOTIONAL_REGULATION_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.EMPATHY}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.EMPATHY, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.EMPATHY_COMMENT, comment)}
              initialRating={values[FORM_NAMES.EMPATHY] as RatingValue}
              value={values[FORM_NAMES.EMPATHY_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.SOCIAL_SKILLS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.SOCIAL_SKILLS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.SOCIAL_SKILLS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.SOCIAL_SKILLS] as RatingValue}
              value={values[FORM_NAMES.SOCIAL_SKILLS_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.EMOTIONAL_EXPRESSION}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.EMOTIONAL_EXPRESSION, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.EMOTIONAL_EXPRESSION_COMMENT, comment)}
              initialRating={values[FORM_NAMES.EMOTIONAL_EXPRESSION] as RatingValue}
              value={values[FORM_NAMES.EMOTIONAL_EXPRESSION_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.CONFLICT_RESOLUTION}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.CONFLICT_RESOLUTION, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.CONFLICT_RESOLUTION_COMMENT, comment)}
              initialRating={values[FORM_NAMES.CONFLICT_RESOLUTION] as RatingValue}
              value={values[FORM_NAMES.CONFLICT_RESOLUTION_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.CONFLICT_AVOIDANCE}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.CONFLICT_AVOIDANCE, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.CONFLICT_AVOIDANCE_COMMENT, comment)}
              initialRating={values[FORM_NAMES.CONFLICT_AVOIDANCE] as RatingValue}
              value={values[FORM_NAMES.CONFLICT_AVOIDANCE_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.ADAPTABILITY}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.ADAPTABILITY, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.ADAPTABILITY_COMMENT, comment)}
              initialRating={values[FORM_NAMES.ADAPTABILITY] as RatingValue}
              value={values[FORM_NAMES.ADAPTABILITY_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.STRESS_MANAGEMENT}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.STRESS_MANAGEMENT, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.STRESS_MANAGEMENT_COMMENT, comment)}
              initialRating={values[FORM_NAMES.STRESS_MANAGEMENT] as RatingValue}
              value={values[FORM_NAMES.STRESS_MANAGEMENT_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.DECISION_MAKING}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.DECISION_MAKING, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.DECISION_MAKING_COMMENT, comment)}
              initialRating={values[FORM_NAMES.DECISION_MAKING] as RatingValue}
              value={values[FORM_NAMES.DECISION_MAKING_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.SELF_MOTIVATION}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.SELF_MOTIVATION, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.SELF_MOTIVATION_COMMENT, comment)}
              initialRating={values[FORM_NAMES.SELF_MOTIVATION] as RatingValue}
              value={values[FORM_NAMES.SELF_MOTIVATION_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
            <ActionButton
              title={BUTTON_TITLE.SAVE}
              className='save-button'
              onClick={() => {
                handleTabChange?.(jurorEvaluationTabsData[3]);
                resetForm();
              }}
            />
            <ActionButton
              title={BUTTON_TITLE.CANCEL}
              onClick={() => {
                handleTabChange?.(jurorEvaluationTabsData[1]);
                resetForm();
              }}
              className='cancel-button'
            />
          </div>
        </form>
      </WrapperComponent>
    </div>
  );
};

export default EmotionalIntelligence;
