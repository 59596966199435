import React from "react";

import "./styles.toggleTabButton.scss";

interface ToggleTabButtonProps {
  isSelected: boolean;
  label: string;
  onClick: () => void;
}

const ToggleTabButton: React.FC<ToggleTabButtonProps> = ({ isSelected, label, onClick }) => {
  return (
    <button className={`toggle-button ${isSelected ? "selected" : ""}`} onClick={onClick}>
      {label}
    </button>
  );
};

export default ToggleTabButton;
