import { AxiosResponse, EnvironmentType, PSL } from "@digitalworkflow/psl_web";

export { EnvironmentType as Environment };
export type { AxiosResponse };

export class PSLInstanceClass {
  private static pslInstance: PSL;

  private constructor() {}

  public static getPSLInstance(): PSL {
    if (!PSLInstanceClass.pslInstance) {
      let psl_endpoint = "";
      if (process.env.REACT_APP_PSL_ENDPOINT) {
        psl_endpoint = process.env.REACT_APP_PSL_ENDPOINT;
      } else {
        throw new Error("REACT_APP_PSL_ENDPOINT not set in environment variables");
      }
      PSLInstanceClass.pslInstance = PSL.getInstance(psl_endpoint);
    }
    return PSLInstanceClass.pslInstance;
  }
}
