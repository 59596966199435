// import AddCase from "./AddCase";

import { FormikValues } from "formik";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "../../../../redux/store";
import { SORT_DIRECTIONS, STRINGS } from "../../../../utils/constants";
import { caseFieldsArray, CaseInitialTabs } from "../../../../utils/constants/formFieldsJson";
import { SOCKET_EVENTS } from "../../../../utils/constants/socketEvents";
import {
  addFieldToInputFieldsData,
  createNewTab,
  dragFieldInTab,
  filterInputFieldsData,
  getFormikInitialValues,
  getFormikValidationSchema,
  moveFieldInTab,
  removeFieldFromTab,
  updateTabContent,
  updateTabsWithNewTab
} from "../../../../utils/helpers/customizableTabHelper";
import useSocket from "../../../../utils/hooks/sockets";
import {
  AddNewCasePropTypes,
  AnervaServerResponseProps,
  ConfigureTabProps,
  DraggableInputFieldProps,
  EventData,
  InputField
} from "../../../../utils/types";
import CustomizableTabs from "../../../CustomizableTabs";

const AddNewCase = ({ isConfigureMode }: AddNewCasePropTypes) => {
  const { socketEmit } = useSocket();
  const [tabs, setTabs] = useState<ConfigureTabProps[]>(CaseInitialTabs);
  const [inputFieldsData, setInputFieldsData] = useState<InputField[]>(caseFieldsArray);
  const [activeTab, setActiveTab] = useState<ConfigureTabProps | undefined>(tabs[0]);
  const user = useSelector((state: RootState) => state.users.user);

  const addNewTab = useCallback(
    (label: string) => {
      const newTab = createNewTab(tabs, label);
      setTabs((prevTabs) => updateTabsWithNewTab(prevTabs, newTab));
    },
    [tabs]
  );

  const handleFieldChange = useCallback((updatedTab: ConfigureTabProps) => {
    setTabs((prevTabs) => updateTabContent(prevTabs, updatedTab));
    setInputFieldsData((prevInputFieldsData) => filterInputFieldsData(prevInputFieldsData, updatedTab));
  }, []);

  const onMoveDown = useCallback(
    (field: InputField) => {
      setTabs((prevTabs) => moveFieldInTab(prevTabs, activeTab?.value ?? "", SORT_DIRECTIONS.DOWN, field.name));
    },
    [activeTab]
  );

  const onMoveUp = useCallback(
    (field: InputField) => {
      setTabs((prevTabs) => moveFieldInTab(prevTabs, activeTab?.value ?? "", SORT_DIRECTIONS.UP, field.name));
    },
    [activeTab]
  );

  const handleRemoveField = useCallback(
    (field: InputField) => {
      setInputFieldsData((prevInputFieldsData) => addFieldToInputFieldsData(prevInputFieldsData, field));
      setTabs((prevTabs) => removeFieldFromTab(prevTabs, activeTab?.value ?? "", field.name));
    },
    [activeTab]
  );

  const handleTabChange = useCallback((tab: ConfigureTabProps) => {
    setActiveTab(tab);
  }, []);

  const moveField = useCallback(
    (dragIndex: number, hoverIndex: number, field: InputField) => {
      setTabs((prevTabs) => dragFieldInTab(prevTabs, activeTab?.value ?? "", dragIndex, hoverIndex, field));
    },
    [activeTab]
  );

  const onFormSubmit = (tabs: ConfigureTabProps[]) => {
    const values = tabs
      .flatMap((item) => item.content)
      .reduce(
        (acc, node) => {
          acc[node.name] = node.value;
          return acc;
        },
        {} as Record<string, unknown>
      );
    handleSave(socketEmit, values);
  };

  const handleSave = useCallback(
    (
      socketEmit: <T extends EventData = EventData>(
        eventName: string,
        data: T,
        onSuccess?: (data: AnervaServerResponseProps) => void,
        onFailure?: (data: AnervaServerResponseProps) => void
      ) => void,
      values?: DraggableInputFieldProps[] | object
    ) => {
      if (socketEmit) {
        const newCase = {
          ...values,
          userId: user?.id,
          type: SOCKET_EVENTS.ADD_CASE,
          eventType: SOCKET_EVENTS.MANAGE_CASE,
          requestId: uuidv4()
        };

        if (socketEmit) {
          socketEmit(
            SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
            newCase,
            (data) => {
              const resData = data as AnervaServerResponseProps;
              if (resData.success) toast.success(resData.message);
              else if (!resData.success) toast.error(resData.message);
            },
            (data) => {
              const resData = data as AnervaServerResponseProps;
              if (resData.success) toast.success(resData.message);
              else if (!resData.success) toast.error(resData.message);
            }
          );
        }
      } else {
        toast.error(STRINGS.SOCKET_NOT_AVAILABLE);
        return;
      }
    },
    [user?.id]
  );

  const onFormValuesChange = useCallback((newValues: FormikValues) => {
    setTabs((prevTabs) =>
      prevTabs.map((tab) => {
        const updatedContent = tab.content.map((field) => {
          if (field.name in newValues) {
            return {
              ...field,
              value: newValues[field.name]
            };
          }
          return field;
        });

        return {
          ...tab,
          content: updatedContent
        };
      })
    );
  }, []);

  return (
    <div>
      <CustomizableTabs
        tabs={tabs}
        activeTab={activeTab}
        isConfigureMode={isConfigureMode}
        inputFieldsData={inputFieldsData}
        onTabChange={handleTabChange}
        addNewTab={addNewTab}
        handleFieldChange={handleFieldChange}
        onMoveDown={onMoveDown}
        onMoveUp={onMoveUp}
        handleRemoveField={handleRemoveField}
        initialValues={getFormikInitialValues(inputFieldsData)}
        validationSchema={getFormikValidationSchema(inputFieldsData)}
        onFormSubmit={onFormSubmit}
        moveField={moveField}
        enableNotepad={false}
        onFormValuesChange={onFormValuesChange}
        setInputFieldsData={setInputFieldsData}
      />
    </div>
  );
};

export default AddNewCase;
