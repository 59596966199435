import { FormikValues } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  buildParticipantDetails,
  buildValues,
  createParticipantDetailsPayload,
  emitUpdateParticipantDetails,
  getFilteredNotes,
  getFormikInitialValues,
  initializeInputFields,
  initializeTabs,
  mergeParticipantValues
} from "./helper";
import { RootState } from "../../../../redux/store";
import { FORM_LABELS, NOTE_TYPES, REACTION_FIELD_TYPES, SORT_DIRECTIONS, STRINGS } from "../../../../utils/constants";
import { SOCKET_EVENTS } from "../../../../utils/constants/socketEvents";
import {
  addFieldToInputFieldsData,
  createNewTab,
  dragFieldInTab,
  filterInputFieldsData,
  getFormikValidationSchema,
  moveFieldInTab,
  removeFieldFromTab,
  updateTabContent,
  updateTabsWithNewTab
} from "../../../../utils/helpers/customizableTabHelper";
import useSocket from "../../../../utils/hooks/sockets";
import {
  AnervaServerResponseProps,
  BasicInfoField,
  ConfigureTabProps,
  EditCandidateProps,
  EventData,
  InputField,
  Note,
  SelectedParticipantProps,
  SocketEmitFunction
} from "../../../../utils/types";
import CustomizableTabs from "../../../CustomizableTabs";
import ToggleButton from "../../../ToggleButton/toggleButton";

const CustomizableEditCandidate: React.FC<EditCandidateProps> = () => {
  const [tabs, setTabs] = useState<ConfigureTabProps[]>([]);
  const [inputFieldsData, setInputFieldsData] = useState<InputField[]>([]);
  const [excludedFields, setExcludedFields] = useState<{ field: InputField; section: string }[]>([]); // State for excluded fields with section info;
  const [isConfigureMode, setIsConfigureMode] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<ConfigureTabProps | undefined>(undefined);
  const { selectedParticipant } = useSelector((state: RootState) => state.participants);
  const { selectedCase } = useSelector((state: RootState) => state.cases);
  const user = useSelector((state: RootState) => state.users.user);
  const { socketEmit } = useSocket();

  const basicInfo = selectedParticipant?.participantDetails?.[0]?.basic || [];

  const getFieldByType = (fieldType: string) =>
    Array.isArray(basicInfo) ? basicInfo.filter((field: BasicInfoField) => field?.field === fieldType) : [];

  const profileImage = getFieldByType("profileImage")?.[0]?.value || "";

  useEffect(() => {
    if (selectedParticipant && selectedParticipant.participantDetails) {
      initializeTabs(selectedParticipant, setTabs, setExcludedFields, setActiveTab);
      initializeInputFields(selectedParticipant, tabs, setInputFieldsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParticipant, isConfigureMode]);

  const addNewTab = useCallback(
    (label: string) => {
      const newTab = createNewTab(tabs, label);
      setTabs((prevTabs) => updateTabsWithNewTab(prevTabs, newTab));
    },
    [tabs]
  );
  const handleFieldChange = useCallback((updatedTab: ConfigureTabProps) => {
    setTabs((prevTabs) => updateTabContent(prevTabs, updatedTab));
    setInputFieldsData((prevInputFieldsData) => filterInputFieldsData(prevInputFieldsData, updatedTab));
  }, []);

  const onMoveDown = useCallback(
    (field: InputField) => {
      setTabs((prevTabs) => moveFieldInTab(prevTabs, activeTab?.value ?? "", SORT_DIRECTIONS.DOWN, field.name));
    },
    [activeTab]
  );

  const onMoveUp = useCallback(
    (field: InputField) => {
      setTabs((prevTabs) => moveFieldInTab(prevTabs, activeTab?.value ?? "", SORT_DIRECTIONS.UP, field.name));
    },
    [activeTab]
  );

  const handleRemoveField = useCallback(
    (field: InputField) => {
      setInputFieldsData((prevInputFieldsData) => addFieldToInputFieldsData(prevInputFieldsData, field));
      setTabs((prevTabs) => removeFieldFromTab(prevTabs, activeTab?.value ?? "", field.name));
    },
    [activeTab]
  );

  const handleTabChange = useCallback((tab: ConfigureTabProps) => {
    setActiveTab(tab);
  }, []);

  const handleConfigureModeChange = useCallback((checked: boolean) => {
    setIsConfigureMode(checked);
  }, []);

  const moveField = useCallback(
    (dragIndex: number, hoverIndex: number, field: InputField) => {
      setTabs((prevTabs) => dragFieldInTab(prevTabs, activeTab?.value ?? "", dragIndex, hoverIndex, field));
    },
    [activeTab]
  );

  const handleSaveNote = useCallback(
    (
      socketEmit: <T extends EventData = EventData>(
        eventName: string,
        data: T,
        onSuccess?: (data: AnervaServerResponseProps) => void,
        onFailure?: (data: AnervaServerResponseProps) => void
      ) => void,
      values: {
        note: Note;
        type: string;
        eventType: string;
        caseId?: string;
        participantId?: string;
      }
    ) => {
      if (socketEmit) {
        const notePayload = {
          type: values.type,
          createdBy: user?.id || "",
          eventType: values.eventType,
          requestId: uuidv4(),
          note: values?.note.note,
          noteType: NOTE_TYPES.PARTICIPANT,
          caseId: selectedParticipant?.caseParticipants[0]?.caseId,
          participantId: selectedParticipant?.id,
          caseParticipantId: selectedParticipant?.caseParticipants[0]?.id
        };

        console.log("notePayload: ", notePayload);
        if (socketEmit) {
          socketEmit(
            SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
            notePayload,
            (data) => {
              const resData = data as AnervaServerResponseProps;
              if (resData.success) toast.success(resData.message);
              else if (!resData.success) toast.error(resData.message);
            },
            (data) => {
              const resData = data as AnervaServerResponseProps;
              if (resData.success) toast.success(resData.message);
              else if (!resData.success) toast.error(resData.message);
            }
          );
        }
      } else {
        toast.error(STRINGS.SOCKET_NOT_AVAILABLE);
        return;
      }
    },
    [selectedParticipant?.caseParticipants, selectedParticipant?.id, user?.id]
  );

  const handleReactions = useCallback(
    (obj: {
      field: { name: string; label: string };
      tabSection: { name: string; label: string };
      isLiked: boolean | null;
    }) => {
      if (socketEmit) {
        const payload = {
          type: SOCKET_EVENTS.ADD_FIELD_REACTION,
          userId: user?.id || "",
          eventType: SOCKET_EVENTS.MANAGE_FIELD_REACTIONS,
          requestId: uuidv4(),
          detailType: REACTION_FIELD_TYPES.PARTICIPANT_DETAILS,
          caseId: selectedParticipant?.caseParticipants[0]?.caseId,
          caseParticipantId: selectedParticipant?.caseParticipants[0]?.id,
          isLiked: obj.isLiked,
          fieldName: obj.field.name,
          fieldLabel: obj.field.label,
          tabSection: obj.tabSection.name,
          tabSectionLabel: obj.tabSection.label
        };

        console.log("Reactions Payload: ", payload);
        if (socketEmit) {
          socketEmit(
            SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
            payload,
            (data) => {
              const resData = data as AnervaServerResponseProps;
              if (resData.success) toast.success(resData.message);
              else if (!resData.success) toast.error(resData.message);
            },
            (data) => {
              const resData = data as AnervaServerResponseProps;
              if (resData.success) toast.success(resData.message);
              else if (!resData.success) toast.error(resData.message);
            }
          );
        }
      } else {
        toast.error(STRINGS.SOCKET_NOT_AVAILABLE);
        return;
      }
    },
    [selectedParticipant?.caseParticipants, socketEmit, user?.id]
  );

  const handleSaveBasicDetails = useCallback(
    (participant: SelectedParticipantProps) => {
      const payload = createParticipantDetailsPayload(
        participant as SelectedParticipantProps,
        user?.id || "",
        selectedCase?.id,
        selectedParticipant?.id
      );
      emitUpdateParticipantDetails(socketEmit as SocketEmitFunction, payload as SelectedParticipantProps);
    },
    [selectedCase?.id, selectedParticipant?.id, socketEmit, user?.id]
  );

  const onFormSubmit = useCallback(
    async (tabs: ConfigureTabProps[]) => {
      const values = buildValues(tabs);

      const updatedParticipant = await mergeParticipantValues(values, selectedParticipant as SelectedParticipantProps);
      const mergedDetails = buildParticipantDetails(tabs);
      excludedFields.forEach(({ field, section }) => {
        // Ensure section exists in mergedDetails, then add excluded field to its respective section
        if (!mergedDetails[section]) mergedDetails[section] = [];
        mergedDetails[section].push(field);
      });

      const participant = {
        ...updatedParticipant,
        participantDetails: mergedDetails
      };
      // Save participant details
      await handleSaveBasicDetails(participant);
    },
    [handleSaveBasicDetails, selectedParticipant, excludedFields]
  );

  const onFormValuesChange = useCallback((newValues: FormikValues) => {
    setTabs((prevTabs) =>
      prevTabs.map((tab) => {
        const updatedContent = tab.content.map((field) => {
          if (field.name in newValues) {
            return {
              ...field,
              value: newValues[field.name]
            };
          }
          return field;
        });

        return {
          ...tab,
          content: updatedContent
        };
      })
    );
  }, []);
  return (
    <div>
      <ToggleButton
        label={FORM_LABELS.CONFIGURE_MODE}
        isChecked={isConfigureMode}
        handleChange={handleConfigureModeChange}
      />
      <CustomizableTabs
        tabs={tabs}
        activeTab={activeTab}
        isConfigureMode={isConfigureMode}
        inputFieldsData={inputFieldsData}
        onTabChange={handleTabChange}
        addNewTab={addNewTab}
        handleFieldChange={handleFieldChange}
        onMoveDown={onMoveDown}
        onMoveUp={onMoveUp}
        handleRemoveField={handleRemoveField}
        initialValues={getFormikInitialValues([...tabs.flatMap((item) => item.content)])}
        validationSchema={getFormikValidationSchema([...tabs.map((item) => item.content)].flat())} // Define validation schema as required
        moveField={moveField}
        onFormSubmit={onFormSubmit}
        onFormValuesChange={onFormValuesChange}
        enableNotepad={true}
        notesHistory={getFilteredNotes(selectedParticipant!, selectedCase!)}
        handleSaveNote={(values) => handleSaveNote(socketEmit, values)}
        setInputFieldsData={setInputFieldsData}
        profileImage={profileImage}
        handleReactions={handleReactions}
      />
    </div>
  );
};

export default CustomizableEditCandidate;
