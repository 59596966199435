import { FormikValues, useFormikContext } from "formik";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getReactionStatus } from "./helper";
import { DummyPersonImg } from "../../../assets/images";
import { RootState } from "../../../redux/store";
import { URLS } from "../../../utils/constants";
import { ConfigureTabProps, CustomizableFormProps, InputField } from "../../../utils/types";
import DraggableInputField from "../../FormConfigurator/components/DraggableInputField";
import ImageAttachment from "../../ImageAttachment";
import SortIcons from "../../SortIcons";
import "./styles.CustomizableForm.scss";

export const CustomizableForm = ({
  isConfigureMode,
  tab,
  moveField,
  handleRemoveField,
  onMoveDown,
  onMoveUp,
  onFormValuesChange,
  profileImage,
  handleReactions
}: CustomizableFormProps) => {
  const { participantFieldReaction } = useSelector((state: RootState) => state.participants);
  const { selectedCase } = useSelector((state: RootState) => state.cases);
  const { values, handleChange, setFieldValue } = useFormikContext<unknown>();
  const location = useLocation();

  useEffect(() => {
    if (onFormValuesChange) {
      onFormValuesChange(values as FormikValues);
    }
    return () => {};
  }, [values, onFormValuesChange]);

  const isSkillsTab = tab.label === "Skills";
  const isNotCreateCasePage = location.pathname !== "/page/create_case";

  const onReactionClick = useCallback(
    (tab: ConfigureTabProps, field: InputField, reaction: boolean | null) => {
      const payload = {
        field: { name: field?.name || field?.field || "", label: field?.label },
        tabSection: { name: tab?.value, label: tab?.label },
        isLiked: reaction
      };
      if (reaction === null) {
        handleReactions?.(payload);
      }
      if (reaction) {
        handleReactions?.(payload);
      } else {
        handleReactions?.(payload);
      }
    },
    [handleReactions]
  );

  return (
    <div>
      <div className={`${!isConfigureMode ? "d-flex flex-row flex-wrap" : ""}`}>
        {isSkillsTab ? (
          <div className='col-12 d-flex flex-row align-items-center gap-3'>
            <textarea
              name='skills'
              placeholder='Enter all skills'
              className='w-100 form-control'
              value={(values as { skills?: string }).skills || ""}
              disabled
              rows={6}
            />
          </div>
        ) : (
          tab.content &&
          Array.isArray(tab.content) &&
          tab.content.length > 0 &&
          tab.content.map((field: InputField, index: number) => {
            const isFirstIndex = index === 0 || tab.content[index - 1]?.isFixed || false;
            const isLastIndex = index === tab.content.length - 1;

            const fieldValue = (values as FormikValues)[field.name] ?? field.value;

            return (
              <div
                key={`${field.id}-${index}-${tab.content.length}`}
                className={`${isConfigureMode ? "col-12" : "col-12 col-md-6 col-xl-6 col-xxl-6 py-1 pr-2"} d-flex flex-row align-items-center gap-3`}
              >
                {/* also add check if tab is basic then show image and route / page  is not create_case */}
                {index === 0 && tab.label === "Basic" && isNotCreateCasePage && (
                  <div className='lg-flex-wrap'>
                    {isConfigureMode ? (
                      <ImageAttachment onFileChange={() => {}} maxFiles={1} acceptVideos={false} />
                    ) : profileImage ? (
                      <img
                        src={typeof profileImage === "string" ? `${URLS.IMAGE_URL}${profileImage}` : DummyPersonImg}
                        alt='Profile'
                        className='form-profile-image'
                      />
                    ) : (
                      <ImageAttachment onFileChange={() => {}} maxFiles={1} acceptVideos={false} />
                    )}
                  </div>
                )}

                <DraggableInputField
                  field={{ ...field, value: fieldValue }}
                  values={values as FormikValues}
                  currentIndex={index}
                  moveField={moveField}
                  isInputField={true}
                  isConfigureMode={isConfigureMode}
                  onChange={handleChange}
                  setFieldValue={setFieldValue}
                  onSwitchChange={field.isSwitch ? () => {} : undefined}
                  isReacted={isNotCreateCasePage}
                  onDisLikeClick={() => onReactionClick(tab, field, false)}
                  onLikeClick={() => onReactionClick(tab, field, true)}
                  fieldReactionValue={getReactionStatus(participantFieldReaction, tab, field, selectedCase!)}
                />
                {isConfigureMode && !field.isFixed && (
                  <div className='d-flex flex-row align-items-center pt-2 gap-2'>
                    <span
                      className='cursor-pointer'
                      onClick={() => {
                        handleRemoveField(field);
                      }}
                    >
                      <i className='fa-regular fa-trash-can h-px-17 w-px-17 color-red '></i>
                    </span>
                    <SortIcons
                      onMoveDown={() => {
                        if (onMoveDown) onMoveDown(field);
                      }}
                      onMoveUp={() => {
                        if (onMoveUp && !tab.content[index - 1]?.isFixed) onMoveUp(field);
                      }}
                      isFirstIndex={isFirstIndex}
                      isLastIndex={isLastIndex}
                    />
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
