import { ColDef, ICellRendererParams } from "ag-grid-community";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAllCompanyUsersFromServer, handleAddNewCompanyUser, handleDeleteCompanyUser } from "./helpers";
import { ActionButton } from "../../../components";
import { AddUserModal } from "../../../components/AddUserModal";
import AgGrid from "../../../components/AgGrid";
import ActionsRenderer from "../../../components/AgGrid/ActionGridRenderer";
import NavigationWrapper from "../../../components/NavigationWrapper";
import WrapperComponent from "../../../components/WrapperComponent";
import { setSelectedCompanyUser } from "../../../redux/slices/companyUsersSlice";
import { RootState } from "../../../redux/types";
import { ACTION_COLUMN_STYLE, baseCompaniesColumns, BUTTON_TITLE } from "../../../utils/constants";
import routes from "../../../utils/constants/routes";
import useSocket from "../../../utils/hooks/sockets";
import { FormDataCompanyUser, HandleEditParams } from "../../../utils/types";

type CompanyDetailsProps = object;

const CompanyDetails: React.FC<CompanyDetailsProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { companyUsersList, companyUser } = useSelector((state: RootState) => state.companyUsers);
  const user = useSelector((state: RootState) => state.users.user);

  const fetchedRef = useRef(false);
  const { socketEmit } = useSocket();

  useEffect(() => {
    fetchAllCompanyUsersFromServer(socketEmit, companyUser?.id || "", user?.id || "", fetchedRef);
  }, [socketEmit, companyUser, user?.id]);

  const transformedCompanyUsersList = companyUsersList.map((user) => ({
    firstName: user.userId.firstName,
    lastName: user.userId.lastName,
    email: user.userId.email,
    role: user.userId.role,
    ...user
  }));

  const toggleModal = useCallback(() => {
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  }, []);

  const handleEdit = (params: HandleEditParams) => {
    dispatch(setSelectedCompanyUser(params));
    navigate(routes.COMPANY_USER_DETAILS);
  };

  const handleDeleteCase = (data: { companyId: string; userId: { id: string } }) => {
    const payload = {
      companyId: data.companyId,
      userId: data.userId.id
    };

    handleDeleteCompanyUser(socketEmit, payload);
  };

  const onClickAddNewUser = () => {
    toggleModal();
  };

  // Column definitions for the AgGrid
  const CompaniesUserListColumns: ColDef[] = [
    ...baseCompaniesColumns,
    {
      ...ACTION_COLUMN_STYLE,
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          data={params.data}
          onDelete={handleDeleteCase} // Pass company name for deletion
          onEdit={handleEdit} // Pass data for editing
        />
      )
    }
  ];

  const onFormSubmission = (data: unknown) => {
    const { firstName, lastName, email, role } = data as FormDataCompanyUser;
    handleAddNewCompanyUser(
      firstName,
      lastName,
      email,
      role,
      companyUser?.id || "",
      user?.id || "",
      socketEmit,
      toggleModal
    );
  };

  return (
    <div className='d-flex flex-column w-100'>
      <NavigationWrapper
        title={companyUser?.companyName || "Company Details"}
        headerChild={
          <ActionButton title={BUTTON_TITLE.ADD_NEW_USER} onClick={onClickAddNewUser} className='save-button bg-blue' />
        }
      >
        <WrapperComponent>
          <div className='d-flex' style={{ height: "65vh" }}>
            <AgGrid columnDefs={CompaniesUserListColumns} rowData={transformedCompanyUsersList} />
          </div>
        </WrapperComponent>

        {isModalOpen && (
          <AddUserModal
            fieldLabel='Add User'
            isOpen={isModalOpen}
            toggle={toggleModal}
            onSubmit={onFormSubmission} // Pass the entered label to the handler
          />
        )}
      </NavigationWrapper>
    </div>
  );
};

export default CompanyDetails;
