import { ColDef, ICellRendererParams } from "ag-grid-community";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { ActionButton, BreadCrumbs } from "../../components";
import AgGrid from "../../components/AgGrid";
import ActionsRenderer from "../../components/AgGrid/ActionGridRenderer";
import NavigationWrapper from "../../components/NavigationWrapper";
import WrapperComponent from "../../components/WrapperComponent";
import { setSelectedCase } from "../../redux/slices/casesSlices";
import { setActiveMenu } from "../../redux/slices/menuActionsSlice";
import { AppDispatch, RootState } from "../../redux/types";
import { ACTION_COLUMN_STYLE, baseCaseColumns, BUTTON_TITLE, TITLES } from "../../utils/constants";
import routes from "../../utils/constants/routes";
import { SOCKET_EVENTS } from "../../utils/constants/socketEvents";
import useSocket from "../../utils/hooks/sockets";
import { CaseListData, CellClickedEvent } from "../../utils/types/index";
import "./styles.caseList.scss";

const CaseList = () => {
  const caseList = useSelector((state: RootState) => state.cases.caseList);
  const user = useSelector((state: RootState) => state.users.user);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { socket, socketEmit } = useSocket();
  const fetchedRef = useRef(false);

  const fetchAllCasesFromServer = useCallback(() => {
    if (fetchedRef.current) return; // Exit if already fetched
    if (socketEmit) {
      socketEmit(
        SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
        {
          type: SOCKET_EVENTS.FETCH_CASES,
          eventType: SOCKET_EVENTS.MANAGE_CASE,
          userId: user?.id,
          requestId: uuidv4()
        },
        () => {
          fetchedRef.current = true;
        }
      );
    }
  }, [socketEmit, user?.id]);

  useEffect(() => {
    fetchAllCasesFromServer();
  }, [fetchAllCasesFromServer]);

  const handleDeleteCase = useCallback(
    (docToDelete: CaseListData) => {
      if (socket) {
        socketEmit(
          SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
          {
            type: SOCKET_EVENTS.DELETE_CASE,
            courtCaseNumber: docToDelete.courtCaseNumber,
            userId: user?.id,
            eventType: SOCKET_EVENTS.MANAGE_CASE,
            requestId: uuidv4()
          },
          ({ success, message }) => {
            if (success) toast.success(message);
            else if (!success) toast.success(message);
          }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [socket, user?.id]
  );

  const handleEdit = useCallback(
    (caseData: CaseListData) => {
      dispatch(setSelectedCase(caseData));
      navigate(routes.MANAGE_CASE, { state: { caseData, from: routes.CASES_LIST } });
      dispatch(setActiveMenu(routes.MANAGE_CASE || ""));
    },
    [dispatch, navigate]
  );

  const CaseListColumns: ColDef[] = [
    ...baseCaseColumns.map((col) => ({
      ...col,
      cellClass: "clickable-cell" // Add class for clickable cells
    })),
    {
      ...ACTION_COLUMN_STYLE,
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          data={params.data}
          onDelete={() => handleDeleteCase(params.data)}
          onEdit={() => handleEdit(params.data)}
        />
      )
    }
  ];

  const onCellClicked = useCallback(
    (event: CellClickedEvent) => {
      const { colDef, data } = event;
      if (colDef && baseCaseColumns.some((col) => col.field === colDef.field)) {
        handleEdit(data); // Call handleEdit if the clicked column is part of baseCaseColumns
      }
    },
    [handleEdit]
  );

  return (
    <div className='d-flex flex-column w-100'>
      <BreadCrumbs breadcrumbItems={[{ text: "Cases", url: routes.CASES_LIST }]} />
      <NavigationWrapper
        title={TITLES.CASES}
        goBack={false}
        headerChild={
          <ActionButton
            title={BUTTON_TITLE.ADD_NEW_CASE}
            onClick={() => {
              navigate(routes.CREATE_CASE);
              dispatch(setActiveMenu(routes.CREATE_CASE || ""));
            }}
            className='save-button bg-blue'
          />
        }
      >
        <WrapperComponent>
          <div className='d-flex' style={{ height: "65vh" }}>
            <AgGrid columnDefs={CaseListColumns} rowData={caseList} onCellClicked={onCellClicked} />
          </div>
        </WrapperComponent>
      </NavigationWrapper>
    </div>
  );
};

export default CaseList;
