import React from "react";
import { SelectComponentPropTypes } from "../../utils/types";

import "./styles.selectcomponent.scss"; // Import the Sass file

/**
 * A select component with optional label, error message, and layout options.
 * Supports two layout options: "column" and "row".
 *
 * @component
 * @example
 * const items = [
 *   { value: '1', label: 'Option 1' },
 *   { value: '2', label: 'Option 2' },
 *   { value: '3', label: 'Option 3' }
 * ];
 *
 * <SelectComponent
 *   label="Option"
 *   items={items}
 *   layout="column"
 *   errorMessage="This field is required"
 *   required
 *   onChange={handleChange}
 * />
 *
 * @param {SelectComponentPropTypes} props - The properties for the select component.
 * @param {string} props.label - The label to display above the select field.
 * @param {string} [props.errorMessage] - The error message to display below the select field. Defaults to a generic required message if not provided.
 * @param {Array<{ value: string | number, label: string }>} props.items - An array of options for the select field, where each option includes a `value` and `label`.
 * @param {"column" | "row"} [props.layout="column"] - The layout of the label and select field. Defaults to "column".
 * @param {React.SelectHTMLAttributes<HTMLSelectElement>} [props] - Additional properties to pass to the `select` element, such as `value`, `onChange`, etc.
 * @returns {JSX.Element} The rendered select component.
 */
const SelectComponent = ({
  label,
  errorMessage,
  items,
  layout = "column",
  hideLabel,
  placeholder,
  ...props
}: SelectComponentPropTypes) => {
  return (
    <div className='select-component'>
      {layout === "column" ? (
        <>
          {!hideLabel && (
            <label htmlFor='validationCustom04' className='select-label'>
              {label}
              {props.required && <span className='required-indicator'> *</span>}
            </label>
          )}
          <select
            {...props}
            className='form-select mt-1'
            id='validationCustom04'
            value={typeof props.value === "number" ? props.value.toString() : props.value}
          >
            <option className='text-sentence-capitalize' value=''>
              {"Select"}
            </option>
            {items.map((i, index) => (
              <option key={index} value={i.value}>
                {i.label}
              </option>
            ))}
          </select>
          <div className='invalid-feedback'>{errorMessage || `${label} is Required!`}</div>
        </>
      ) : (
        <div className='select-row'>
          {!hideLabel && (
            <div className='label-container'>
              <label htmlFor='validationCustom04' className='select-label'>
                {label}
                {props.required && <span className='required-indicator'> *</span>}
              </label>
            </div>
          )}
          <div className='select-container'>
            <select
              {...props}
              className='form-select'
              id='validationCustom04'
              value={typeof props.value === "number" ? props.value.toString() : props.value}
            >
              {placeholder ? (
                <option value=''>{hideLabel ? `${placeholder}` : placeholder}</option>
              ) : (
                <option value=''>{hideLabel ? `Please select ${label}` : "Please select"}</option>
              )}
              {items.map((i, index) => (
                <option key={index} value={i.value}>
                  {i.label}
                </option>
              ))}
            </select>
            <div className='invalid-feedback'>{errorMessage || `${label} is Required!`}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectComponent;
