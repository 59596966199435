import { Formik } from "formik";
import React, { useCallback, useState } from "react";
import CourtDates from "./CourtDates";
import { caseDetailsValidationSchema, courtDatesValidationSchema, initialDateValues, initialValues } from "./helper";
import NewCaseForm from "./NewCaseForm";
import { manageCaseTabButtons } from "../../../../utils/constants";
import { ConfigureTabProps, CreateNewCaseProps, NewCaseFormValues, Tab } from "../../../../utils/types";
import TabButtons from "../../../TabButtons";
import { WrapperComponent } from "../../../WrapperComponent";

import "./styles.scss";

const CreateNewCase: React.FC<CreateNewCaseProps> = ({ handleTabChange, caseData }) => {
  const [activeTab, setActiveTab] = useState(manageCaseTabButtons[0]);

  const handleCaseTab = useCallback((tab: ConfigureTabProps) => {
    setActiveTab(tab as Tab);
  }, []);

  const renderTabContent = () => {
    switch (activeTab) {
      case manageCaseTabButtons[0]:
        return (
          <WrapperComponent>
            <Formik<NewCaseFormValues>
              initialValues={initialValues}
              validationSchema={caseDetailsValidationSchema}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              <NewCaseForm handleTabChange={handleTabChange} caseData={caseData} />
            </Formik>
          </WrapperComponent>
        );
      case manageCaseTabButtons[1]:
        return (
          <WrapperComponent>
            <Formik<NewCaseFormValues>
              initialValues={initialDateValues}
              validationSchema={courtDatesValidationSchema}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              <CourtDates handleTabChange={handleTabChange} caseData={caseData} />
            </Formik>
          </WrapperComponent>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TabButtons tabs={manageCaseTabButtons} activeTab={activeTab} onTabChange={handleCaseTab} />
      {renderTabContent()}
    </>
  );
};

export default CreateNewCase;
