/* eslint-disable @typescript-eslint/no-explicit-any */
import { AgGridReact, SharedProps } from "ag-grid-react";
import React, { memo, RefObject } from "react";

import "./AgGrid.scss";

export interface GridProps extends SharedProps {
  gridRef?: RefObject<any>;
  theme?: string;
  pagination?: boolean;
}

const GridTable = (props: GridProps) => {
  const gridTheme = `${props.theme === "balham" ? "ag-theme-balham" : "ag-theme-alpine"} grid-table`;

  return (
    <div style={{ height: "100%" }}>
      <div className={gridTheme}>
        <AgGridReact ref={props?.gridRef} {...props} />
      </div>
    </div>
  );
};
export default memo(GridTable);
