import { StrikingReason } from "../types";

export const FORM_LABELS = {
  CASE_NAME: "Case Name",
  DOCUMENT_NAME: "Document Name",
  COURT_CASE_NUMBER: "Court Case Number",
  CLIENT_NAME: "Client Name",
  CASE_STATUS: "Case Status",
  COURT_JURISDICTION: "Court Jurisdiction",
  CASE_TYPE: "Case Type",
  CONFLICT_CHECK: "Conflict Check",
  TRIAL_START_DATE: "Trial Start Date",
  FILING_DATE: "Filing Date",
  HEARING_DATE: "Hearing Date",
  INJURIES: "Injuries",
  DATE_OF_INCIDENT: "Date of Incident/Accident",
  DEFENDANT_NAME: "Defendant's Name",
  OPPOSING_COUNSEL_NAME: "Opposing Counsel's Name",
  EXISTING_CASE_OR_CLIENT: "Existing Case And/Or Candidate?",
  ARREST_DATE: "Arrest Date",
  ADDITIONAL_INFORMATION: "Additional Information",
  DISCOVERY_DEADLINE: "Discovery Deadline",
  EVIDENCE_DESCRIPTION: "Evidence Description",
  DOC_TYPE: "Doc Type",
  CASE_NUMBER: "Case Number(s)",
  CLIENT_NAME_S: "Client Name(s)",
  DATE_ADDED: "Date Added",
  ADDED_BY: "Added By",
  ROLE: "Role",
  EVIDENCE_VIEWER: "Evidence Viewer",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  NICKNAME: "Nickname",
  AGE: "Age",
  SEX: "Sex",
  GENDER: "Gender",
  PRONOUNS: "Pronouns",
  PREFERRED_TITLE: "Preferred Title",
  OCCUPATION: "Occupation",
  EDUCATION_LEVEL: "Education Level",
  ADDRESS: "Address",
  ADDITIONAL_ADDRESS: "Additional Address (Optional)",
  CITY: "City",
  STATE: "State",
  COUNTRY: "Country",
  ZIP_CODE: "Zipcode",
  CHILDREN: "Any Children",
  CHILD_SUPPORT: "Does the candidate pay child support?",
  RELATIONSHIP_STATUS_OPTION: "Relationship Status",
  RELATIONSHIP_STATUS: "Relationship Status",
  ADDITIONAL_INFORMATION_OPTIONAL: "Additional Information (Optional)",
  TIME_ZONE: "Time Zone",
  ETHNICITY: "Ethnicity",
  ECONOMIC_STATUS: "Economic Status",
  WORKPLACE: "Workplace",
  ANY_BANKRUPTCY: "Any Bankruptcy's?",
  HOUSEHOLD_INFORMATION: "Household Information",
  ESTIMATED_HOUSEHOLD_INCOME: "Estimated Household Income",
  ESTIMATED_NET_WORTH: "Estimated Net Worth (Net Worth = Total Assets - Total Liabilities)",
  LENGTH_OF_RESIDENCE: "Length Of Residence",
  HOME_OWNERSHIP_STATUS: "Home Ownership Status",
  LEGAL_RECORDS: "Legal & Other Records",
  LICENSES_OR_CREDENTIALS: "Licenses or Credentials",
  ACADEMIC_RECORDS: "Academic Records",
  TESTIMONY_TRANSCRIPTS: "Testimony Transcripts",
  WITNESS_PROTECTION_PROGRAM: "Has this individual ever been in, or is currently in, a Witness Protection Program?",
  PRIOR_VERDICTS_OR_SETTLEMENTS: "Verdicts or Settlements in prior cases?",
  POLITICAL_AFFILIATIONS: "Political Affiliations",
  CRIMINAL_RECORDS: "Criminal Records",
  PRIOR_STATEMENTS_OR_REPORTS: "Prior Written Statements Or Reports?",
  NO_CONTACT_OR_RESTRAINING_ORDER: "Is there a current or past no-contact or restraining order for this individual?",
  DATE_OF_BIRTH: "Date of Birth",
  TWITTER: "X (Twitter)",
  FACEBOOK: "Facebook",
  REDDIT: "Reddit",
  TIKTOK: "TikTok",
  WEBSITE_BLOG_URLS: "Website/Blog URLs",
  LINKEDIN: "LinkedIn",
  INSTAGRAM: "Instagram",
  YOUTUBE: "Youtube",
  ADD_SOCIAL_MEDIA: "Add Social Media",
  CANDIDATE_PHOTO: "Candidate Photo",
  WITNESS_PHOTO: "Witness Photo",
  EXPERT_PHOTO: "Expert Photo",
  PARTICIPANT_PHOTO: "Participant Photo",
  JURY_PHOTO: "Jury Photo",

  INTERESTS_HOBBIES: "Interests/Hobbies",
  MUSIC_PREFERENCES: "Music Preferences",
  TRAVEL_PREFERENCES: "Travel Preferences",
  OUTDOOR_ACTIVITIES: "Outdoors Activities",
  RELIGIOUS_AFFILIATIONS: "Religious Affiliations",
  FAVORITE_BOOKS_MOVIES_TV_SHOWS: "Favorite Books/Movies/TV Shows",
  FOOD_PREFERENCES: "Food Preferences",
  PETS: "Pets",
  CHARITABLE_CONTRIBUTIONS: "Charitable Contributions",
  ORGANIZATIONAL_AFFILIATIONS: "Organizational Affiliations",
  PARTY_AFFILIATION: "Party Affiliation",
  VOTING_RECORDS: "Voting Records",
  DONOR_INFORMATION: "Donor Information (Environmental, Animal Welfare, Political)",
  OVERALL_JUROR_RATINGS: "Overall Juror Ratings",
  LIKELIHOOD_OF_FAVORABILITY: "Likelihood of Favorability",
  UNDERSTANDING_OF_LEGAL_CONCEPTS: "Understanding of Legal Concepts",
  ATTITUDE_TOWARD_CASE: "Attitude Toward Case",
  JUROR_BACKGROUND_KNOWLEDGE: "Juror Background Knowledge",
  COMMUNICATION_SKILLS: "Communication Skills",
  RECEPTIVITY_TO_ATTORNEYS_ARGUMENT: "Receptivity to Attorney’s Argument",
  POSTURE: "Posture",
  EYE_CONTACT: "Eye Contact",
  ACTIVE_LISTENING: "Active Listening",
  EMOTIONAL_DISPLAYS: "Emotional Displays",
  COLLABORATIVE_BEHAVIOR: "Collaborative Behavior",
  CONFIDENT_POSTURE: "Confident Posture",
  ADAPTATION: "Adaptation",
  GESTURES: "Gestures",
  FACIAL_EXPRESSIONS: "Facial Expressions",
  DISTRACTED_BEHAVIOR: "Distracted Behavior",
  BODY_TENSION: "Body Tension",
  DOMINANT_PASSIVE_BEHAVIOR: "Dominant or Passive Behavior",
  ASSERTIVE_BEHAVIOR: "Assertive Behavior",
  FLEXIBILITY: "Flexibility",
  EMOTIONAL_AWARENESS: "Emotional Awareness",
  EMOTIONAL_REGULATION: "Emotional Regulation",
  SOCIAL_SKILLS: "Social Skills",
  CONFLICT_RESOLUTION: "Conflict Resolution",
  ADAPTABILITY: "Adaptability",
  DECISION_MAKING: "Decision Making",
  EMOTIONAL_INFLUENCE: "Emotional Influence",
  EMPATHY: "Empathy",
  EMOTIONAL_EXPRESSION: "Emotional Expression",
  CONFLICT_AVOIDANCE: "Conflict Avoidance",
  STRESS_MANAGEMENT: "Stress Management",
  SELF_MOTIVATION: "Self-Motivation",
  CLARITY_OF_EXPRESSION: "Clarity Of Expression",
  RESPECTFUL_COMMUNICATION: "Respectful Communication",
  PERSUASIVENESS: "Persuasiveness",
  ADAPTABILITY_IN_COMMUNICATION: "Adaptability In Communication",
  ARTICULATION: "Articulation",
  NON_VERBAL_COMMUNICATION: "Non-Verbal Communication",
  CONFLICT_RESOLUTION_SKILLS: "Conflict Resolution Skills",
  QUESTIONING_SKILLS: "Questioning Skills",
  NOTE_TAKING_SKILLS: "Note-Taking Skills",
  TECHNICAL_SKILLS: "Technical Skills",
  LIFE_EXPERIENCES: "Life Experiences That Can Help The Case",
  LEADERSHIP_QUALITIES: "Leadership Qualities",
  COMMUNICATION: "Communication",
  SOFT_SKILLS: "Soft Skills",
  ACHIEVEMENTS: "Achievements And Accomplishments",
  RESILIENCE: "Resilience",
  ANALYTICAL_SKILLS: "Analytical Skills",
  TEAMWORK: "Teamwork And Collaboration",
  TECHNICAL_SKILL_GAPS: "Technical Skill Gaps",
  ADAPTABILITY_TO_CHANGE: "Adaptability To Change",
  PROBLEM_SOLVING_SKILLS: "Problem-Solving Skills",
  COMMUNICATION_CHALLENGES: "Communication Challenges",
  TIME_MANAGEMENT_ISSUES: "Time Management Issues",
  CLIENT_RELATIONSHIP_MANAGEMENT: "Client Relationship Management",
  UNDERSTANDING_LAW_PROCESSES: "Understanding The Law Processes And Procedures",
  AGE_BIAS: "Age Bias",
  ETHNICITY_RACE_BIAS: "Ethnicity or Race Bias",
  INDUSTRY_BIAS: "Industry Bias",
  CULTURAL_BIAS: "Cultural Bias",
  TECHNOLOGY_BIAS: "Technology Bias",
  CONFIRMATION_BIAS: "Confirmation Bias",
  GENDER_BIAS: "Gender Bias",
  SOCIOECONOMIC_BIAS: "Socioeconomic Bias",
  EXPERIENCE_BIAS: "Experience Bias",
  LANGUAGE_BIAS: "Language Bias",
  POLITICAL_IDEOLOGY_BIAS: "Political Ideology Bias",
  BEHAVIORAL_BIAS: "Behavioral Bias",
  PHONE_NUMBER: "Phone Number",
  ALT_PHONE_NUMBER: "Alt Phone Number",
  EMAIL: "Email",
  CONFIGURE_MODE: "Configure Mode",
  ANY_MEDICAL_CONDITIONS: "Any Medical Conditions",
  JUROR_ID: "Juror ID",
  DRIVING_RECORDS: "Driving Records",
  COURT_RECORDS: "Previous Legal/Court Records",
  PRIOR_JURY_EXPERIENCE: "Prior Jury Experience",
  JURY_QUESTION_RESPONSES: "Jury Question Responses",
  BANK_ACCOUNT_INFORMATION: "Bank Account Information",
  CREDIT_SCORE: "Credit Score",
  INVESTMENT_PREFERENCES: "Investment Preferences",
  INVESTMENT_PREFERENCES_CHECK: "Investment Preferences Available",
  AUTO_INFORMATION: "Auto Information",
  AUTO_INFORMATION_CHECK: "Auto Information Available",
  MORTGAGE_DETAILS: "Mortgage & Refinance Details",
  MORTGAGE_DETAILS_CHECK: "Mortgage & Refinance Details Available",
  BUDGETING_INFORMATION: "Budgeting Information",
  BANKRUPTCY_CHECK: "Any Bankruptcy’s?",
  BANKRUPTCY_TEXT: "Bankruptcy Details",
  PHONE_CALLS: "Phone Calls",
  FACE_TO_FACE_MEETINGS: "Face-to-Face Meetings",
  VIDEO_CONFERENCING: "Video Conferencing",
  MICROSOFT_TEAMS: "Microsoft Teams",
  GOOGLE_HANGOUTS: "Google Hangouts",
  WHATSAPP: "WhatsApp",
  TELEGRAM: "Telegram",
  SKYPE: "Skype",
  FACEBOOK_MESSENGER: "Facebook Messenger",
  APPLE_I_MESSAGE: "Apple iMessage",
  INSTAGRAM_DIRECT: "Instagram Direct",
  SIGNAL: "Signal",
  SNAPCHAT: "Snapchat",
  PREFERRED_LANGUAGE: "Preferred Language(s)",
  SEAT_NO: "Seat No"
};

export const FORM_PLACEHOLDERS = {
  DEFAULT: "Type here",
  DATE: "MM/DD/YYYY",
  CONFLICT_CHECK: "Notes",
  INJURIES: "Notes",
  COMMENT: "Comment(s)",
  ADDITIONAL_ADDRESS: "Optional",
  DASHED: "-",
  ADD_NEW_PHOTO: "Add New Photo",
  ADD_NEW_PHOTO_VIDEO: "Add New Photo or Video",
  SOCIAL_MEDIA_FIELD_NAME: "Social Media Field Name",
  COMMENT_HERE: "Type your comment here",
  REASON_HERE: "Type your reason here",
  SMART_SEARCH: "Smart Search",
  FieldPlaceholder: "Field Place holder",
  CompanyName: "Company Name"
};

export const FORM_NAMES = {
  CASE_NAME: "caseName",
  PHONE: "phoneNumber",
  DOCUMENT_NAME: "documentName",
  COURT_CASE_NUMBER: "courtCaseNumber",
  CLIENT_NAME: "clientName",
  CASE_STATUS: "caseStatus",
  COURT_JURISDICTION: "courtJurisdiction",
  CASE_TYPE: "caseType",
  CONFLICT_CHECK: "conflictCheck",
  TRIAL_START_DATE: "trialStartDate",
  FILING_DATE: "filingDate",
  HEARING_DATES: "hearingDates",
  INJURIES: "injuries",
  DATE_OF_INCIDENT: "dateOfIncident",
  DEFENDANT_NAME: "defendantName",
  OPPOSING_COUNSELS_NAME: "opposingCounselsName",
  EXISTING_CASE_OR_CLIENT: "existingCaseOrClient",
  ARREST_DATE: "arrestDate",
  ADDITIONAL_INFORMATION: "additionalInformation",
  DISCOVERY_DEADLINE: "discoveryDeadline",
  EVIDENCE_DESCRIPTION: "evidenceDescription",
  DOC_TYPE: "docType",
  CASE_NUMBER: "courtCaseNumber",
  CLIENT_NAME_S: "clientName",
  DATE_ADDED: "dateAdded",
  ADDED_BY: "addedBy",
  ROLE: "role",
  EVIDENCE_VIEWER: "evidenceViewer",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  NICKNAME: "nickname",
  AGE: "age",
  SEX: "sex",
  GENDER: "gender",
  PRONOUNS: "pronouns",
  PREFERRED_TITLE: "preferredTitle",
  OCCUPATION: "occupation",
  EDUCATION_LEVEL: "educationLevel",
  ADDRESS: "address",
  ADDITIONAL_ADDRESS: "addressAdditional",
  CITY: "city",
  STATE: "state",
  COUNTRY: "country",
  ZIP_CODE: "zipCode",
  CHILDREN: "children",
  CHILDREN_TEXT: "childrenText",
  CHILD_SUPPORT: "childSupport",
  CHILD_SUPPORT_TEXT: "childSupportText",
  RELATIONSHIP_STATUS_OPTION: "relationshipStatusOption",
  RELATIONSHIP_STATUS: "relationshipStatus",
  ADDITIONAL_INFORMATION_OPTIONAL: "additionalInformation",
  TIME_ZONE: "timeZone",
  ETHNICITY: "ethnicity",
  ECONOMIC_STATUS: "economicStatus",
  WORKPLACE: "workplace",
  ANY_BANKRUPTCY: "anyBankruptcy",
  ESTIMATED_HOUSEHOLD_INCOME: "estimatedHouseholdIncome",
  ESTIMATED_NET_WORTH: "estimatedNetWorth",
  LENGTH_OF_RESIDENCE: "lengthOfResidence",
  HOME_OWNERSHIP_STATUS: "homeOwnershipStatus",
  LEGAL_RECORDS: "legal_other_records",
  LICENSES_OR_CREDENTIALS: "licenses_or_credentials",
  ACADEMIC_RECORDS: "academic_records",
  TESTIMONY_TRANSCRIPTS: "testimony_transcripts",
  WITNESS_PROTECTION_PROGRAM: "witness_protection_program",
  WITNESS_PROTECTION_PROGRAM_CHECK: "witness_protection_program_check",
  PRIOR_VERDICTS_OR_SETTLEMENTS: "prior_verdicts_or_settlements",
  PRIOR_VERDICTS_OR_SETTLEMENTS_CHECK: "prior_verdicts_or_settlements_check",
  POLITICAL_AFFILIATIONS: "political_affiliations",
  CRIMINAL_RECORDS: "criminal_records",
  PRIOR_STATEMENTS_OR_REPORTS: "prior_statements_or_reports",
  PRIOR_STATEMENTS_OR_REPORTS_CHECK: "prior_statements_or_reports_check",
  NO_CONTACT_OR_RESTRAINING_ORDER: "no_contact_or_restraining_order",
  NO_CONTACT_OR_RESTRAINING_ORDER_CHECK: "no_contact_or_restraining_order_check",

  TWITTER: "twitter",
  FACEBOOK: "facebook",
  REDDIT: "reddit",
  TIKTOK: "tiktok",
  WEBSITE_BLOG_URLS: "website_blog_urls",
  LINKEDIN: "linkedin",
  INSTAGRAM: "instagram",
  YOUTUBE: "youtube",

  INTERESTS_HOBBIES: "interestsHobbies",
  MUSIC_PREFERENCES: "musicPreferences",
  TRAVEL_PREFERENCES: "travelPreferences",
  OUTDOOR_ACTIVITIES: "outdoorActivities",
  RELIGIOUS_AFFILIATIONS: "religiousAffiliations",
  FAVORITE_BOOKS_MOVIES_TV_SHOWS: "favoriteBooksMoviesTvShows",
  FOOD_PREFERENCES: "foodPreferences",
  PETS: "pets",
  CHARITABLE_CONTRIBUTIONS: "charitableContributions",
  ORGANIZATIONAL_AFFILIATIONS: "organizationalAffiliations",
  IS_DONOR_INFORMATION_AVAILABLE: "isDonorInformationAvailable",
  DONOR_INFORMATION: "donorInformation",
  PARTY_AFFILIATION: "partyAffiliation",
  VOTING_RECORDS: "votingRecords",

  OVERALL_JUROR_RATINGS: "overallJurorRatings",
  OVERALL_JUROR_RATINGS_COMMENT: "overallJurorRatingsComment",
  LIKELIHOOD_OF_FAVORABILITY: "likelihoodOfFavorability",
  LIKELIHOOD_OF_FAVORABILITY_COMMENT: "likelihoodOfFavorabilityComment",
  UNDERSTANDING_OF_LEGAL_CONCEPTS: "understandingOfLegalConcepts",
  UNDERSTANDING_OF_LEGAL_CONCEPTS_COMMENT: "understandingOfLegalConceptsComment",
  ATTITUDE_TOWARD_CASE: "attitudeTowardCase",
  ATTITUDE_TOWARD_CASE_COMMENT: "attitudeTowardCaseComment",
  JUROR_BACKGROUND_KNOWLEDGE: "jurorBackgroundKnowledge",
  JUROR_BACKGROUND_KNOWLEDGE_COMMENT: "jurorBackgroundKnowledgeComment",
  COMMUNICATION_SKILLS: "communicationSkills",
  COMMUNICATION_SKILLS_COMMENT: "communicationSkillsComment",
  RECEPTIVITY_TO_ATTORNEYS_ARGUMENT: "receptivityToAttorneysArgument",
  RECEPTIVITY_TO_ATTORNEYS_ARGUMENT_COMMENT: "receptivityToAttorneysArgumentComment",
  POSTURE: "posture",
  POSTURE_COMMENT: "postureComment",
  EYE_CONTACT: "eyeContact",
  EYE_CONTACT_COMMENT: "eyeContactComment",
  ACTIVE_LISTENING: "activeListening",
  ACTIVE_LISTENING_COMMENT: "activeListeningComment",
  EMOTIONAL_DISPLAYS: "emotionalDisplays",
  EMOTIONAL_DISPLAYS_COMMENT: "emotionalDisplaysComment",
  COLLABORATIVE_BEHAVIOR: "collaborativeBehavior",
  COLLABORATIVE_BEHAVIOR_COMMENT: "collaborativeBehaviorComment",
  CONFIDENT_POSTURE: "confidentPosture",
  CONFIDENT_POSTURE_COMMENT: "confidentPostureComment",
  ADAPTATION: "adaptation",
  ADAPTATION_COMMENT: "adaptationComment",
  GESTURES: "gestures",
  GESTURES_COMMENT: "gesturesComment",
  FACIAL_EXPRESSIONS: "facialExpressions",
  FACIAL_EXPRESSIONS_COMMENT: "facialExpressionsComment",
  DISTRACTED_BEHAVIOR: "distractedBehavior",
  DISTRACTED_BEHAVIOR_COMMENT: "distractedBehaviorComment",
  BODY_TENSION: "bodyTension",
  BODY_TENSION_COMMENT: "bodyTensionComment",
  DOMINANT_PASSIVE_BEHAVIOR: "dominantPassiveBehavior",
  DOMINANT_PASSIVE_BEHAVIOR_COMMENT: "dominantPassiveBehaviorComment",
  ASSERTIVE_BEHAVIOR: "assertiveBehavior",
  ASSERTIVE_BEHAVIOR_COMMENT: "assertiveBehaviorComment",
  FLEXIBILITY: "flexibility",
  FLEXIBILITY_COMMENT: "flexibilityComment",
  EMOTIONAL_AWARENESS: "emotionalAwareness",
  EMOTIONAL_AWARENESS_COMMENT: "emotionalAwarenessComment",
  EMOTIONAL_REGULATION: "emotionalRegulation",
  EMOTIONAL_REGULATION_COMMENT: "emotionalRegulationComment",
  SOCIAL_SKILLS: "socialSkills",
  SOCIAL_SKILLS_COMMENT: "socialSkillsComment",
  CONFLICT_RESOLUTION: "conflictResolution",
  CONFLICT_RESOLUTION_COMMENT: "conflictResolutionComment",
  ADAPTABILITY: "adaptability",
  ADAPTABILITY_COMMENT: "adaptabilityComment",
  DECISION_MAKING: "decisionMaking",
  DECISION_MAKING_COMMENT: "decisionMakingComment",
  EMOTIONAL_INFLUENCE: "emotionalInfluence",
  EMOTIONAL_INFLUENCE_COMMENT: "emotionalInfluenceComment",
  EMPATHY: "empathy",
  EMPATHY_COMMENT: "empathyComment",
  EMOTIONAL_EXPRESSION: "emotionalExpression",
  EMOTIONAL_EXPRESSION_COMMENT: "emotionalExpressionComment",
  CONFLICT_AVOIDANCE: "conflictAvoidance",
  CONFLICT_AVOIDANCE_COMMENT: "conflictAvoidanceComment",
  STRESS_MANAGEMENT: "stressManagement",
  STRESS_MANAGEMENT_COMMENT: "stressManagementComment",
  SELF_MOTIVATION: "selfMotivation",
  SELF_MOTIVATION_COMMENT: "selfMotivationComment",
  CLARITY_OF_EXPRESSION: "clarityOfExpression",
  CLARITY_OF_EXPRESSION_COMMENT: "clarityOfExpressionComment",
  RESPECTFUL_COMMUNICATION: "respectfulCommunication",
  RESPECTFUL_COMMUNICATION_COMMENT: "respectfulCommunicationComment",
  PERSUASIVENESS: "persuasiveness",
  PERSUASIVENESS_COMMENT: "persuasivenessComment",
  ADAPTABILITY_IN_COMMUNICATION: "adaptabilityInCommunication",
  ADAPTABILITY_IN_COMMUNICATION_COMMENT: "adaptabilityInCommunicationComment",
  ARTICULATION: "articulation",
  ARTICULATION_COMMENT: "articulationComment",
  NON_VERBAL_COMMUNICATION: "nonVerbalCommunication",
  NON_VERBAL_COMMUNICATION_COMMENT: "nonVerbalCommunicationComment",
  CONFLICT_RESOLUTION_SKILLS: "conflictResolutionSkills",
  CONFLICT_RESOLUTION_SKILLS_COMMENT: "conflictResolutionSkillsComment",
  QUESTIONING_SKILLS: "questioningSkills",
  QUESTIONING_SKILLS_COMMENT: "questioningSkillsComment",
  NOTE_TAKING_SKILLS: "noteTakingSkills",
  NOTE_TAKING_SKILLS_COMMENT: "noteTakingSkillsComment",
  TECHNICAL_SKILLS: "technicalSkills",
  TECHNICAL_SKILLS_COMMENT: "technicalSkillsComment",
  LIFE_EXPERIENCES: "lifeExperiences",
  LIFE_EXPERIENCES_COMMENT: "lifeExperiencesComment",
  LEADERSHIP_QUALITIES: "leadershipQualities",
  LEADERSHIP_QUALITIES_COMMENT: "leadershipQualitiesComment",
  COMMUNICATION: "communication",
  COMMUNICATION_COMMENT: "communicationComment",
  SOFT_SKILLS: "softSkills",
  SOFT_SKILLS_COMMENT: "softSkillsComment",
  ACHIEVEMENTS: "achievements",
  ACHIEVEMENTS_COMMENT: "achievementsComment",
  RESILIENCE: "resilience",
  RESILIENCE_COMMENT: "resilienceComment",
  ANALYTICAL_SKILLS: "analyticalSkills",
  ANALYTICAL_SKILLS_COMMENT: "analyticalSkillsComment",
  TEAMWORK: "teamwork",
  TEAMWORK_COMMENT: "teamworkComment",
  TECHNICAL_SKILL_GAPS: "technicalSkillGaps",
  TECHNICAL_SKILL_GAPS_COMMENT: "technicalSkillGapsComment",
  ADAPTABILITY_TO_CHANGE: "adaptabilityToChange",
  ADAPTABILITY_TO_CHANGE_COMMENT: "adaptabilityToChangeComment",
  PROBLEM_SOLVING_SKILLS: "problemSolvingSkills",
  PROBLEM_SOLVING_SKILLS_COMMENT: "problemSolvingSkillsComment",
  COMMUNICATION_CHALLENGES: "communicationChallenges",
  COMMUNICATION_CHALLENGES_COMMENT: "communicationChallengesComment",
  TIME_MANAGEMENT_ISSUES: "timeManagementIssues",
  TIME_MANAGEMENT_ISSUES_COMMENT: "timeManagementIssuesComment",
  CLIENT_RELATIONSHIP_MANAGEMENT: "clientRelationshipManagement",
  CLIENT_RELATIONSHIP_MANAGEMENT_COMMENT: "clientRelationshipManagementComment",
  UNDERSTANDING_LAW_PROCESSES: "understandingLawProcesses",
  UNDERSTANDING_LAW_PROCESSES_COMMENT: "understandingLawProcessesComment",
  AGE_BIAS: "ageBias",
  AGE_BIAS_COMMENT: "ageBiasComment",
  ETHNICITY_RACE_BIAS: "ethnicityRaceBias",
  ETHNICITY_RACE_BIAS_COMMENT: "ethnicityRaceBiasComment",
  INDUSTRY_BIAS: "industryBias",
  INDUSTRY_BIAS_COMMENT: "industryBiasComment",
  CULTURAL_BIAS: "culturalBias",
  CULTURAL_BIAS_COMMENT: "culturalBiasComment",
  TECHNOLOGY_BIAS: "technologyBias",
  TECHNOLOGY_BIAS_COMMENT: "technologyBiasComment",
  CONFIRMATION_BIAS: "confirmationBias",
  CONFIRMATION_BIAS_COMMENT: "confirmationBiasComment",
  GENDER_BIAS: "genderBias",
  GENDER_BIAS_COMMENT: "genderBiasComment",
  SOCIOECONOMIC_BIAS: "socioeconomicBias",
  SOCIOECONOMIC_BIAS_COMMENT: "socioeconomicBiasComment",
  EXPERIENCE_BIAS: "experienceBias",
  EXPERIENCE_BIAS_COMMENT: "experienceBiasComment",
  LANGUAGE_BIAS: "languageBias",
  LANGUAGE_BIAS_COMMENT: "languageBiasComment",
  POLITICAL_IDEOLOGY_BIAS: "politicalIdeologyBias",
  POLITICAL_IDEOLOGY_BIAS_COMMENT: "politicalIdeologyBiasComment",
  BEHAVIORAL_BIAS: "behavioralBias",
  BEHAVIORAL_BIAS_COMMENT: "behavioralBiasComment",
  PHONE_NUMBER: "phoneNumber",
  ALT_PHONE_NUMBER: "altPhoneNumber",
  EMAIL: "email",
  ANY_MEDICAL_CONDITIONS: "anyMedicalConditions",
  MEDICAL_CONDITION_TEXT: "medicalConditionText",
  JUROR_ID: "jurorID",
  DRIVING_RECORDS: "driving_records",
  COURT_RECORDS: "court_records",
  PRIOR_JURY_EXPERIENCE: "prior_jury_experience",
  JURY_QUESTION_RESPONSES: "jury_question_responses",
  DRIVING_RECORDS_CHECK: "court_records_check",
  CRIMINAL_RECORDS_CHECK: "criminal_records_check",
  COURT_RECORDS_CHECK: "court_records_check",
  PRIOR_JURY_EXPERIENCE_CHECK: "prior_jury_experience_check",
  BANK_ACCOUNT_INFORMATION: "bankAccountInformation",
  CREDIT_SCORE: "creditScore",
  INVESTMENT_PREFERENCES: "investmentPreferences",
  INVESTMENT_PREFERENCES_CHECK: "investmentPreferencesCheck",
  AUTO_INFORMATION: "autoInformation",
  AUTO_INFORMATION_CHECK: "autoInformationCheck",
  MORTGAGE_DETAILS: "mortgageDetails",
  MORTGAGE_DETAILS_CHECK: "mortgageDetailsCheck",
  BUDGETING_INFORMATION: "budgetingInformation",
  BANKRUPTCY_CHECK: "bankruptcyCheck",
  BANKRUPTCY_TEXT: "bankruptcyText",
  PHONE_CALLS: "phoneCalls",
  FACE_TO_FACE_MEETINGS: "faceToFaceMeetings",
  VIDEO_CONFERENCING: "videoConferencing",
  MICROSOFT_TEAMS: "microsoftTeams",
  GOOGLE_HANGOUTS: "googleHangouts",
  WHATSAPP: "whatsapp",
  TELEGRAM: "telegram",
  SKYPE: "skype",
  FACEBOOK_MESSENGER: "facebookMessenger",
  APPLE_I_MESSAGE: "appleiMessage",
  INSTAGRAM_DIRECT: "instagramDirect",
  SIGNAL: "signal",
  SNAPCHAT: "snapchat",
  PREFERRED_LANGUAGE: "preferredLanguage",
  SEAT_NO: "seatNumber",
  DATE_OF_BIRTH: "DateOfBirth"
};

export const BUTTON_TITLE = {
  SAVE: "Save",
  SAVE_CASE: "Save Case",
  SAVE_PAGE_CONFIGURATION: "Save Page Configuration",
  SAVE_DOCUMENT: "Save Document",
  ADD_DOCUMENT: "Add a Document",
  NEXT: "Next",
  CONTINUE: "Continue",
  BACK: "Back",
  CANCEL: "Cancel",
  STOP: "Stop",
  STOP_NOW: "Stop Now",
  ADD_MORE: "Add More",
  ADD_MORE_HEARING_DATE: "Add More Hearing Date",
  ADD_SOCIAL_MEDIA: "Add Social Media",
  Share: "Share",
  DOWNLOAD_AS_PDF: "Download as PDF",
  SHARE_LINK_TO_THIS_PAGE: "Share a link to this page",
  AI_PROFILE_GENERATE: "AI Profile Generation",
  ADD_NEW_CANDIDATE: "Add New Candidate",
  ADD_NEW_WITNESS: "Add New Witness",
  ADD_NEW_PARTICIPANT: "Add New Participant",
  ADD_NEW_EXPERT: "Add New Expert",
  UPLOAD_JURY_LIST: "Upload Jury List",
  ADD_JUROR: "Add Juror",
  ADD_NEW_EVIDENCE: "Add New Evidence",
  DOWNLOAD_FILES: "Download Set of Files",
  DOWNLOAD_DOCUMENTS: "Download Set of Documents",
  ADD_NEW_CASE: "Add New Case",
  ADD_NEW_COMPANY: "+ Add New Company",
  ADD_NEW_USER: "+ Add New User",
  ADD_NEW_DOC: "Add New Document",
  SHARE: "Share",
  ADD_NEW_EXPERTS: "Add New Experts",
  ADD_NEW_PARTICIPANTS: "Add New Participants",
  ADD_ONE_MORE_LANGUAGE: "Add One More Language",
  YES: "Yes",
  NO: "No",
  SURE: "Sure",
  APPLY: "Apply",
  ADD: "Add",
  ADD_AS_A_TEAM: "Add as a team",
  EditCompany: "Edit Company",
  COMPANIES: "Companies"
};

export const FIELD_TYPES = {
  TEXT_AREA: "textArea",
  NUMBER: "number",
  CURRENCY: "currency",
  TEXT: "text",
  PHONE: "tel",
  EMAIL: "email",
  PASSWORD: "password",
  SEARCH: "search",
  DATE: "date",
  TIME: "time"
};

export const CASE_TABS = {
  BASIC: "basic",
  DEMOGRAPHICS: "demographics",
  LEGAL: "legal",
  SOCIAL_MEDIA: "socialMedia",
  CASE_DETAILS: "caseDetails",
  PARTICIPANTS: "participants",
  TRIAL_TEAM: "trialTeam",
  CASE_EVIDENCE: "caseEvidence",
  DOCUMENTS: "documents",
  PERSONAL_PREFERENCES: "personalPreferences",
  POLITICAL: "politicalAndSocial",
  FINANCIAL: "financial",
  COMMUNICAITON_PREFERENCES: "communicationPreferences"
};

export const TITLES = {
  [CASE_TABS.BASIC]: "Candidate Information",
  [CASE_TABS.DEMOGRAPHICS]: "Demographics",
  [CASE_TABS.LEGAL]: "Legal & Other Records",
  [CASE_TABS.SOCIAL_MEDIA]: "Social Media & Online Presence",
  BASIC_INDICATORS: "Basic Indicators",
  BODY_LANGUAGE: "Body Language",
  EMOTIONAL_INTELLIGENCE: "Emotional Intelligence",
  COMMUNICATION_SKILLS: "Communication Skills",
  STRENGTHS: "Strengths",
  WEAKNESS: "Weakness",
  POTENTIAL_BIASES: "Potential Biases",
  CANDIDATES: "Candidates",
  WITNESSES: "Witnesses",
  PARTICIPANTS: "Participants",
  EXPERTS: "Experts",
  ADD_NEW_CANDIDATE: "Candidate: Add New Candidate",
  ADD_NEW_WITNESS: "Witness: Add New Witness",
  ADD_NEW_EXPERTS: "Expert: Add New Expert",
  ADD_NEW_PARTICIPANTS: "Participant: Add New Participant",
  ADD_NEW_JUROR: "Juror: Add New Juror",

  EDIT_NEW_CANDIDATE: "Edit Candidate: ",
  EDIT_NEW_WITNESS: "Edit Witness: ",
  EDIT_NEW_EXPERTS: "Edit Expert: ",
  EDIT_NEW_JUROR: "Edit Juror: ",
  EDIT_NEW_PARTICIPANTS: "Edit Participant: ",

  CASE_EVIDENCE: "Case Evidence",
  CREATE_NEW_CASE: "Create New Case",
  WELCOME_MSG: "Welcome",
  DOCUMENTS: "Documents",
  CREATE_CASE: "Create Case",
  CASE_DOCUMENTS: "Case Documents",
  CONFIRMATION: "Confirmation",
  CASES: "Cases",
  MANAGE_CASE: "Manage Case",
  EVIDENCE_DOCS: "Evidence Documents",
  ACCOUNTS: "Accounts",
  SWITCH_USER_ROLES: "Switch User Roles"
};

export const RATING_VALUES = [1, 2, 3, 4, 5] as const;
export const INITIAL_RATING = 5;
export const RATING_LABELS = {
  negative: "Absolutely Negative",
  positive: "Absolutely Positive"
} as const;

export const titleMap = {
  "add-new-candidate": TITLES.ADD_NEW_CANDIDATE,
  "add-new-witness": TITLES.ADD_NEW_WITNESS,
  "add-new-experts": TITLES.ADD_NEW_EXPERTS,
  "add-new-participants": TITLES.ADD_NEW_PARTICIPANTS
};
export const photoLabelMap = {
  "add-new-candidate": FORM_LABELS.CANDIDATE_PHOTO,
  "add-new-witness": FORM_LABELS.WITNESS_PHOTO,
  "add-new-experts": FORM_LABELS.EXPERT_PHOTO,
  "add-new-participants": FORM_LABELS.PARTICIPANT_PHOTO
};

export const subHeaderMap = {
  "add-new-candidate": "Candidate Information",
  "add-new-witness": "Witness Information",
  "add-new-experts": "Expert Information",
  "add-new-participants": "Participant Information"
};

export const editTitleMap = {
  "edit-new-candidate": TITLES.EDIT_NEW_CANDIDATE,
  "edit-new-witness": TITLES.EDIT_NEW_WITNESS,
  "edit-new-experts": TITLES.EDIT_NEW_EXPERTS,
  "edit-new-participants": TITLES.EDIT_NEW_PARTICIPANTS
};
export const editPhotoLabelMap = {
  "edit-new-candidate": FORM_LABELS.CANDIDATE_PHOTO,
  "edit-new-witness": FORM_LABELS.WITNESS_PHOTO,
  "edit-new-experts": FORM_LABELS.EXPERT_PHOTO,
  "edit-new-participants": FORM_LABELS.PARTICIPANT_PHOTO
};

export const editSubHeaderMap = {
  "edit-new-candidate": "Candidate Information",
  "edit-new-witness": "Witness Information",
  "edit-new-experts": "Expert Information",
  "edit-new-participants": "Participant Information"
};

// constants.ts
export const STRINGS = {
  JUROR_HAS_BEEN_DISMISSED: "Juror has been dismissed",
  JURY_BOX: "Jury Box",
  DRAG_AND_DROP_PARA: "Drag and drop them into the Jury Box below to for your Trial Team",
  JURY_SELECTION: "Jury Selection",
  TRIAL_TEAM_STATISTICS: "Trial Team Statistics",
  ADD_ALTERNATE_JUROR: "Add Alternate Juror",
  ARE_YOUR_SURE_RECORD: "Are you sure you want to delete this record?",
  ADDRESS: "123 Main Street, Anytown, Missouri 65807",
  CHILDREN: "2 children",
  MARRIED: "Married",
  USERNAME: "@UserName",
  PREFIX: "Mrs",
  PRONOUNS: "She/Her",
  REGULAR: "regular",
  STRIKE: "Strike",
  REASONFORSTRIKING: "Reason for striking?",
  RATING: "Rating",
  ARE_YOUR_SURE_KICK_OUT: "Are you sure you want to kick out this juror?",
  TOOL_TIP: "tooltip",
  FILL_REQUIRED_FIELDS: "Please fill in all required fields",
  SOCKET_NOT_AVAILABLE: "Socket connection is not available",
  Education: "Education",
  EducationLevel: "educationLevel",
  Candidates: "candidates",
  Witnesses: "witnesses",
  Experts: "experts",
  LOGIN_TITLE: "Login",
  LOGIN_SUBTITLE: "Login with your email address.",
  EMAIL_LABEL: "Email",
  EMAIL_PLACEHOLDER: "Enter your email",
  CANCEL_BUTTON_TITLE: "Cancel",
  NEXT_BUTTON_TITLE: "Next",
  PASSWORD_LABEL: "Password",
  PASSWORD_PLACEHOLDER: "Enter your password",
  SUBMIT_BUTTON_TITLE: "Submit",
  NO_FIELDS_FOUND: "No fields found"
};

export const ANERVA = "Anerva";
export const ADMIN = "Admin";
export const SUPER_ADMIN = "SuperAdmin";
export const ANERVA_TEAM = "Anerva Team";

export const THEME = {
  DARK: "dark",
  LIGHT: "light"
};

export const POSITIONS = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right",
  TOP_RIGHT: "top-right",
  TOP_LEFT: "top-left",
  BOTTOM_RIGHT: "bottom-right",
  BOTTOM_LEFT: "bottom-left",
  CENTER: "center"
};

export const caseCardLabels = {
  CASE_NAME: "Court Case #",
  COURT_JURISDICTION: "Court Jurisdiction",
  CLIENT_NAME: "Client Name",
  TRIAL_DATE: "Trial Date",
  N_A: "N/A"
};

export const STRIKING_REASONS: StrikingReason[] = [
  {
    value: false,
    text: "Challenge - Cause",
    type: "Cause"
  },
  {
    value: false,
    text: "Challenge - Peremptory",
    type: "Peremptory"
  },
  {
    value: false,
    text: "Bias/Prejudice - Party",
    type: "Party"
  },
  {
    value: false,
    text: "Bias/Prejudice - Subject matter",
    type: "subjectMatter"
  },
  {
    value: false,
    text: "Excused",
    type: "Excused"
  },
  {
    value: false,
    text: "Other",
    type: "other"
  }
];

export const MEMBER_TYPES = ["witness", "expert", "candidate", "juror"] as const;
export const SORT_DIRECTIONS = {
  UP: "up",
  DOWN: "down"
};

export const MESSAGES = {
  COMPANY_NAME_EMPTY: "Company name cannot be empty!",
  NEW_COMPANY_SUCCESS: "New company added successfully!",
  NEW_COMPANY_FAILURE: "Failed to add the company!",
  FETCH_COMPANIES_FAILURE: "Failed to fetch companies!",
  DELETE_COMPANY_SUCCESS: "Company deleted successfully!",
  DELETE_COMPANY_FAILURE: "Failed to delete the company!",
  UPDATE_COMPANY_SUCCESS: "Company updated successfully!",
  UPDATE_COMPANY_FAILURE: "Failed to update the company!",

  ERROR_FIELDS_REQUIRED: "All fields are required!",
  SUCCESS_COMPANY_USER_ADD: "New company user added successfully!",
  ERROR_COMPANY_USER_ADD: "Failed to add the company user!",
  SUCCESS_COMPANY_USER_DELETE: "Company user deleted successfully!",
  ERROR_COMPANY_USER_DELETE: "Failed to delete the company user!",
  LOGIN_SUCCESSFULLY: "Login successfully",
  AN_ERROR_OCCURRED: "An error occurred. Please try again later.",
  EMAIL_IS_REQUIRED: "Email is required",
  INVALID_EMAIL_FORMAT: "Invalid email format",
  PASSWORD_REQUIRED: "Password is required"
};
