import React from "react";
import { LabeledInputProps } from "../../utils/types";

import "./styles.LabeledInput.scss";

interface EnhancedLabeledInputProps extends LabeledInputProps {
  type?: "email" | "password";
}

const LabeledInput: React.FC<EnhancedLabeledInputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  type = "email",
  error
}) => {
  return (
    <div className='labeled-input'>
      <label className='labeled-input__label'>{label}</label>
      <input
        type={type}
        className={`labeled-input__input ${error ? "labeled-input__input--error" : ""}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxLength={type === "password" ? 100 : 500} // Set appropriate max length
        autoComplete={type === "password" ? "current-password" : "email"} // Enable browser auto-fill
        aria-required='true' // Indicate the field is required
        aria-invalid={!!error} // Indicate validation error status
        aria-describedby={error ? `${label}-error` : undefined} // Associate with error message if present
      />
      {error && (
        <div id={`${label}-error`} className='labeled-input__error'>
          {error}
        </div>
      )}
    </div>
  );
};

export default LabeledInput;
