import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createParticipantPayload, emitSaveParticipantDetails } from "./helper";
import { RootState } from "../../../redux/types";
import {
  BUTTON_TITLE,
  educationLevelOptions,
  FIELD_TYPES,
  FORM_LABELS,
  FORM_NAMES,
  FORM_PLACEHOLDERS,
  genderOptions,
  preferredTitleOptions,
  pronounsOptions,
  relationshipStatusOptions,
  sexOptions,
  STRINGS,
  timeZoneOptions
} from "../../../utils/constants";
import { formatPhoneNumber } from "../../../utils/helpers";
import useSocket from "../../../utils/hooks/sockets";
import { BasicInfoProps, SocketEmitFunction } from "../../../utils/types";
import { ActionButton } from "../../ActionButton";
import ImageAttachment from "../../ImageAttachment";
import SelectComponent from "../../SelectComponent";
import TextInput from "../../TextInput";

const BasicInfo: React.FC<BasicInfoProps> = (props) => {
  const { values, errors, setFieldValue, handleChange, resetForm, isValid } = props;
  const { selectedCase } = useSelector((state: RootState) => state.cases);
  const { selectedParticipant } = useSelector((state: RootState) => state.participants);
  const user = useSelector((state: RootState) => state.users.user);

  const navigate = useNavigate();
  const { socketEmit } = useSocket();

  const handleSaveBasicDetails = useCallback(() => {
    if (!isValid) {
      toast.error(STRINGS.FILL_REQUIRED_FIELDS);
      return;
    }

    const payload = createParticipantPayload(values, user?.id || "", selectedCase?.id, selectedParticipant?.id);

    emitSaveParticipantDetails(socketEmit as SocketEmitFunction, payload);
  }, [isValid, selectedCase?.id, selectedParticipant?.id, socketEmit, user?.id, values]);

  return (
    <>
      <div className='d-flex flex-row w-100 sm-flex-wrap justify-content-between'>
        <div className='client-photo-section sm-flex-wrap '>
          <ImageAttachment
            label={props.photoLabel || FORM_LABELS.CANDIDATE_PHOTO}
            onFileChange={(file) => setFieldValue("clientPhoto", file)}
            maxFiles={1}
            acceptVideos={false}
          />
        </div>

        <div className='client-info-section d-flex flex-column gap-1'>
          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <TextInput
              label={FORM_LABELS.FIRST_NAME}
              name={FORM_NAMES.FIRST_NAME}
              placeholder={FORM_PLACEHOLDERS.DEFAULT}
              value={values.firstName}
              onChange={handleChange}
              errorMessage={errors.firstName}
            />
            <SelectComponent
              label={FORM_LABELS.PREFERRED_TITLE}
              name={FORM_NAMES.PREFERRED_TITLE}
              items={preferredTitleOptions}
              value={values.preferredTitle}
              onChange={(e) => setFieldValue(FORM_NAMES.PREFERRED_TITLE, e.target.value)}
              errorMessage={errors.preferredTitle}
            />
          </div>
          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <TextInput
              label={FORM_LABELS.LAST_NAME}
              name={FORM_NAMES.LAST_NAME}
              placeholder={FORM_PLACEHOLDERS.DEFAULT}
              value={values.lastName}
              onChange={handleChange}
              errorMessage={errors.lastName}
            />
            <SelectComponent
              label={FORM_LABELS.PRONOUNS}
              name={FORM_NAMES.PRONOUNS}
              items={pronounsOptions}
              value={values.pronouns}
              onChange={(e) => setFieldValue(FORM_NAMES.PRONOUNS, e.target.value)}
              errorMessage={errors.pronouns}
            />
          </div>
          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <TextInput
              label={FORM_LABELS.NICKNAME}
              name={FORM_NAMES.NICKNAME}
              placeholder={FORM_PLACEHOLDERS.DEFAULT}
              value={values.nickname}
              onChange={handleChange}
            />
            <TextInput
              label={FORM_LABELS.AGE}
              name={FORM_NAMES.AGE}
              placeholder={FORM_PLACEHOLDERS.DEFAULT}
              type={FIELD_TYPES.NUMBER}
              value={values.age}
              onChange={handleChange}
              errorMessage={errors.age}
              min={10}
              max={120}
            />
          </div>
        </div>
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.EMAIL}
          name={FORM_NAMES.EMAIL}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          value={values.email}
          onChange={handleChange}
          errorMessage={errors.email}
        />
        <TextInput
          label={FORM_LABELS.PHONE_NUMBER}
          name={FORM_NAMES.PHONE_NUMBER}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          value={values.phoneNumber}
          onChange={(e) => {
            const formattedPhoneNumber = formatPhoneNumber(e.target.value);
            handleChange({ target: { name: FORM_NAMES.PHONE_NUMBER, value: formattedPhoneNumber } });
          }}
          errorMessage={errors.phoneNumber}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <SelectComponent
          label={FORM_LABELS.SEX}
          name={FORM_NAMES.SEX}
          items={sexOptions}
          value={values.sex}
          onChange={(e) => setFieldValue(FORM_NAMES.SEX, e.target.value)}
          errorMessage={errors.sex}
        />
        <SelectComponent
          label={FORM_LABELS.GENDER}
          name={FORM_NAMES.GENDER}
          items={genderOptions}
          value={values.gender}
          onChange={(e) => setFieldValue(FORM_NAMES.GENDER, e.target.value)}
          errorMessage={errors.gender}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
        <TextInput
          label={FORM_LABELS.OCCUPATION}
          name={FORM_NAMES.OCCUPATION}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          value={values.occupation}
          onChange={handleChange}
          errorMessage={errors.occupation}
        />
        <SelectComponent
          label={FORM_LABELS.EDUCATION_LEVEL}
          name={FORM_NAMES.EDUCATION_LEVEL}
          items={educationLevelOptions}
          value={values.educationLevel}
          onChange={(e) => setFieldValue(FORM_NAMES.EDUCATION_LEVEL, e.target.value)}
          errorMessage={errors.educationLevel}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.ADDRESS}
          name={FORM_NAMES.ADDRESS}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          value={values.address}
          onChange={handleChange}
          errorMessage={errors.address}
        />
        <TextInput
          label={FORM_LABELS.ADDITIONAL_ADDRESS}
          name={FORM_NAMES.ADDITIONAL_ADDRESS}
          placeholder={FORM_PLACEHOLDERS.ADDITIONAL_ADDRESS}
          value={values.addressAdditional}
          onChange={handleChange}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.CITY}
          name={FORM_NAMES.CITY}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          value={values.city}
          onChange={handleChange}
          errorMessage={errors.city}
        />
        <TextInput
          label={FORM_LABELS.STATE}
          name={FORM_NAMES.STATE}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          value={values.state}
          onChange={handleChange}
          errorMessage={errors.state}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
        <TextInput
          label={FORM_LABELS.COUNTRY}
          name={FORM_NAMES.COUNTRY}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          value={values.country}
          onChange={handleChange}
          errorMessage={errors.country}
        />
        <TextInput
          label={FORM_LABELS.ZIP_CODE}
          name={FORM_NAMES.ZIP_CODE}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          value={values.zipCode}
          onChange={handleChange}
          errorMessage={errors.zipCode}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.CHILDREN}
          name={FORM_NAMES.CHILDREN_TEXT}
          isSwitch
          type={FIELD_TYPES.NUMBER}
          value={values.childrenText}
          checked={values?.children}
          onChange={handleChange}
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.CHILDREN, e.target.checked)}
        />
        <TextInput
          label={FORM_LABELS.CHILD_SUPPORT}
          name={FORM_NAMES.CHILD_SUPPORT_TEXT}
          isSwitch
          value={values?.childSupportText}
          checked={values.childSupport}
          onChange={handleChange}
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.CHILD_SUPPORT, e.target.checked)}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap w-100'>
        <div className='d-flex flex-column w-100'>
          <SelectComponent
            label={FORM_LABELS.RELATIONSHIP_STATUS_OPTION}
            name={FORM_NAMES.RELATIONSHIP_STATUS_OPTION}
            items={relationshipStatusOptions}
            value={values.relationshipStatusOption}
            onChange={(e) => setFieldValue(FORM_NAMES.RELATIONSHIP_STATUS_OPTION, e.target.value)}
            errorMessage={errors.relationshipStatusOption}
          />
          <TextInput
            name={FORM_NAMES.RELATIONSHIP_STATUS}
            placeholder={FORM_PLACEHOLDERS.DEFAULT}
            value={values.relationshipStatus}
            onChange={handleChange}
            errorMessage={errors.relationshipStatus}
          />
        </div>
        <TextInput
          label={FORM_LABELS.ADDITIONAL_INFORMATION_OPTIONAL}
          name={FORM_NAMES.ADDITIONAL_INFORMATION_OPTIONAL}
          type={FIELD_TYPES.TEXT_AREA}
          value={values.additionalInformation}
          onChange={handleChange}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap w-50'>
        <SelectComponent
          label={FORM_LABELS.TIME_ZONE}
          name={FORM_NAMES.TIME_ZONE}
          items={timeZoneOptions}
          value={values.timeZone}
          onChange={(e) => setFieldValue(FORM_NAMES.TIME_ZONE, e.target.value)}
          errorMessage={errors.timeZone}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
        <ActionButton title={BUTTON_TITLE.SAVE} className='save-button' onClick={handleSaveBasicDetails} />
        <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            navigate(-1);
            resetForm?.();
          }}
          className='cancel-button'
        />
      </div>
    </>
  );
};

export default BasicInfo;
