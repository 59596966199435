import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { MESSAGES } from "../../../utils/constants";
import { SOCKET_EVENTS } from "../../../utils/constants/socketEvents";
import { AnervaServerResponseProps, EventData } from "../../../utils/types";

// Function to fetch all company users from the server
export const fetchAllCompanyUsersFromServer = (
  socketEmit: <T extends EventData = EventData>(
    eventName: string,
    data: T,
    onSuccess?: (data: AnervaServerResponseProps) => void
  ) => void,
  companyId: string, // Pass the companyId as a parameter
  userId: string, // Pass the userId as a parameter
  fetchedRef?: React.MutableRefObject<boolean>
) => {
  if (fetchedRef?.current) return; // Exit if already fetched
  if (socketEmit) {
    socketEmit(
      SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
      {
        companyId: companyId, // Include companyId in the payload
        type: SOCKET_EVENTS.FETCH_COMPANY_USERS, // Set the event type
        eventType: SOCKET_EVENTS.MANAGE_COMPANY_USERS, // Set the event type
        requestId: uuidv4(), // Generate a unique request ID
        userId: userId // Include the user ID
      },
      () => {
        if (fetchedRef) {
          fetchedRef.current = true; // Set fetchedRef to true after successful fetch
        }
      }
    );
  }
};

export const handleAddNewCompanyUser = (
  firstName: string,
  lastName: string,
  email: string,
  role: string,
  companyId: string,
  userId: string,
  socketEmit: <T extends EventData = EventData>(
    eventName: string,
    data: T,
    onSuccess?: (data: AnervaServerResponseProps) => void,
    onFailure?: (data: AnervaServerResponseProps) => void
  ) => void,
  toggleModal: () => void
) => {
  // Validate fields
  if (!firstName.trim() || !lastName.trim() || !email.trim() || !companyId.trim()) {
    toast.error(MESSAGES.ERROR_FIELDS_REQUIRED);
    return;
  }

  // Construct the newCompanyUser object
  const newCompanyUser = {
    companyId,
    firstName,
    lastName,
    email,
    role: role || "Admin", // add user role
    createdBy: userId,
    type: SOCKET_EVENTS.ADD_COMPANY_USER,
    eventType: SOCKET_EVENTS.MANAGE_COMPANY_USERS,
    requestId: uuidv4() // Unique requestId
  };

  // Emit the socket event
  if (socketEmit) {
    socketEmit(
      SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
      newCompanyUser,
      (data) => {
        const resData = data as AnervaServerResponseProps;
        if (resData.success) {
          toast.success(resData.message || MESSAGES.SUCCESS_COMPANY_USER_ADD);
        } else {
          toast.error(resData.message || MESSAGES.ERROR_COMPANY_USER_ADD);
        }
      },
      (data) => {
        const resData = data as AnervaServerResponseProps;
        toast.error(resData.message || MESSAGES.ERROR_COMPANY_USER_ADD);
      }
    );
  }

  // Close the modal after submission
  toggleModal();
};

export const handleDeleteCompanyUser = (
  socketEmit: <T extends EventData = EventData>(
    eventName: string,
    data: T,
    onSuccess?: (data: AnervaServerResponseProps) => void,
    onFailure?: (data: AnervaServerResponseProps) => void
  ) => void,
  data: { companyId: string; userId: string }
) => {
  if (socketEmit) {
    socketEmit(
      SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
      {
        ...data,
        type: SOCKET_EVENTS.DELETE_COMPANY_USER,
        eventType: SOCKET_EVENTS.MANAGE_COMPANY_USERS,
        requestId: uuidv4()
      },
      (data) => {
        const resData = data as AnervaServerResponseProps;
        if (resData.success) {
          toast.success(resData.message || MESSAGES.SUCCESS_COMPANY_USER_DELETE);
        } else {
          toast.error(resData.message || MESSAGES.ERROR_COMPANY_USER_DELETE);
        }
      },
      (data) => {
        const resData = data as AnervaServerResponseProps;
        toast.error(resData.message || MESSAGES.ERROR_COMPANY_USER_DELETE);
      }
    );
  }
};
