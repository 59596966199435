import moment from "moment";
import React from "react";
import { NotesCardProps } from "../../utils/types";

import "./notescard.scss";

const NotesCard = ({ note }: NotesCardProps) => {
  return (
    <div className='history-detail'>
      <div className='d-flex flex-row align-items-center justify-content-between'>
        <div className='history-createdBy'>{note?.creator?.firstName + " " + note?.creator?.lastName}</div>
        <div className='history-createAt'>
          {note.createdAt && moment(note.createdAt.toString()).format("MMM DD, YYYY hh:mm a")}
        </div>
      </div>
      <div className='history-description'>{note.note}</div>
    </div>
  );
};
export default NotesCard;
