import React from "react";
import { TextInputPropTypes } from "../../utils/types";
import ThumbsToggle from "../ThumbsToggle";

import "./styles.textinput.scss";

/**
 * A text input component with optional label, layout, and error message.
 * Supports two layout options: "column" and "row".
 *
 * @component
 * @example
 * <TextInput
 *   label="Username"
 *   layout="column"
 *   errorMessage="Username is required"
 *   required
 *   placeholder="Enter your username"
 *   isSwitch
 *   checked={true}
 *   onSwitchChange={(e) => handleSwitchChange(e)}
 *   switchLabel={{ on: "Yes", off: "No" }}
 * />
 *
 * @param {TextInputPropTypes} props - The properties for the text input component.
 * @param {string} props.label - The label to display above the input field.
 * @param {"column" | "row"} [props.layout="column"] - The layout of the text input and label. Defaults to "column".
 * @param {string} [props.errorMessage] - The error message to display below the input field. Defaults to a generic required message if not provided.
 * @param {boolean} [props.isSwitch] - Whether to include a switch input alongside the text input.
 * @param {boolean} [props.checked] - The checked state of the switch (if `isSwitch` is true).
 * @param {function} [props.onSwitchChange] - Callback function to handle switch state change.
 * @param {Object} [props.switchLabel] - Labels to display for the switch states (`on` and `off`).
 * @param {React.InputHTMLAttributes<HTMLInputElement>} [props] - Additional properties to pass to the input element, such as `placeholder`, `value`, `onChange`, etc.
 * @returns {JSX.Element} The rendered text input component.
 */

export default function TextInput({
  label,
  layout = "column",
  textAreaClassName = "",
  errorMessage,
  labelClassName = "",
  parentClassName = "",
  inputClassName = "",
  rows = 5,
  type = "text",
  isSwitch = false,
  checked = true,
  placeholder = "Type here",
  onSwitchChange,
  icon,
  switchLabel = { on: "Yes", off: "No" },
  currencyText,
  iconClassName = "",
  onClickIcon = () => {},
  value = "",
  onChange,
  onLikeClick = () => {},
  onDisLikeClick = () => {},
  isReacted,
  fieldReactionValue = null,
  ...props
}: TextInputPropTypes): JSX.Element {
  return (
    <div className={`form-group ${parentClassName}`}>
      {layout === "column" ? (
        <>
          <div className='input-field'>
            {label && (
              <label className={`form-label ${labelClassName}`}>
                {label}
                {!!props.required && <span className='required-marker'> *</span>}
              </label>
            )}
            {isReacted && (
              <ThumbsToggle
                onDisLikeClick={onDisLikeClick}
                onLikeClick={onLikeClick}
                fieldReactionValue={fieldReactionValue}
              />
            )}
            {isSwitch && (
              <div className='form-check form-switch'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckChecked'
                  checked={checked}
                  onChange={onSwitchChange}
                />
                <label className='form-label switch' htmlFor='flexSwitchCheckChecked'>
                  {checked ? switchLabel.on : switchLabel.off}
                </label>
              </div>
            )}
          </div>
          <div className='input-group'>
            {type === "textArea" ? (
              <textarea
                id='description'
                name='description'
                className={`form-control ${textAreaClassName}`}
                disabled={!checked}
                rows={rows}
                maxLength={2000}
                placeholder={placeholder}
                value={value || ""}
                onChange={onChange as React.ChangeEventHandler<HTMLTextAreaElement>}
                {...props}
              />
            ) : type === "currency" ? (
              <>
                <span className='currency-symbol'>$</span>
                <input
                  className={`form-control currency-input ${inputClassName}`}
                  disabled={!checked}
                  maxLength={500}
                  placeholder={placeholder}
                  type='text'
                  value={value || ""}
                  onChange={onChange as React.ChangeEventHandler<HTMLInputElement>}
                  {...props}
                />
                {currencyText && <span className='currency-text'>{currencyText}</span>}
              </>
            ) : icon ? (
              <div className='form-control-with-icon'>
                <input
                  className={`w-100 mr-1 ${inputClassName}`}
                  maxLength={500}
                  placeholder={placeholder}
                  type={type}
                  value={value || ""}
                  onChange={onChange as React.ChangeEventHandler<HTMLInputElement>}
                  {...props}
                />
                <span onClick={onClickIcon} className={`cursor-pointer pr-1 ${iconClassName}`}>
                  {icon}
                </span>
              </div>
            ) : (
              <input
                className={`form-control ${inputClassName}`}
                disabled={!checked}
                maxLength={500}
                placeholder={placeholder}
                type={type}
                value={value || ""}
                onChange={onChange as React.ChangeEventHandler<HTMLInputElement>}
                {...props}
              />
            )}
            {errorMessage && <div className='input-error-message'>{errorMessage}</div>}
          </div>
        </>
      ) : (
        <div className='row align-items-center'>
          <div className='col-md-4'>
            {label && (
              <label className={`form-label ${labelClassName}`}>
                {label}
                {!!props.required && <span className='required-marker'> *</span>}
              </label>
            )}
          </div>
          <div className='col-md-8'>
            <div className='input-group'>
              <input
                className={`form-control ${inputClassName}`}
                type={type}
                value={value || ""}
                onChange={onChange as React.ChangeEventHandler<HTMLInputElement>}
                {...props}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
